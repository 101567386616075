import { FC } from 'react';
import { useGetIssueStatusMappingQuery } from '@/shared/state/api/swagger';
import { chakra, Stack, Text } from '@chakra-ui-kraud/react';
import styles from './issue-details.module.scss';
import dayjs from 'dayjs';
import { IssueBadge } from '@/entities/issue';
import { Link } from 'react-router-dom';
import { abbreviateUser, LINK_TO_TECH_CARD_INFO, LabeledBlock, DEFAULT_YEAR_FORMAT } from '@/shared';
import { IssueDetailsProps } from '../model/issue-details.types';

const STUB = '—';

export const IssueDetails: FC<IssueDetailsProps> = ({
	open_at,
	status,
	product_tech_map,
	responsible_user,
	problem_product_operation,
	executor_user,
}) => {
	const { data: statusLocaleMap = {} } = useGetIssueStatusMappingQuery();

	return (
		<Stack gap={3}>
			<LabeledBlock
				label={<Text className={styles['block__label']}>Дата создания</Text>}
				content={
					<Text className={styles['block__field']}>
						{open_at && dayjs(open_at).format(DEFAULT_YEAR_FORMAT)}
					</Text>
				}
			/>
			<LabeledBlock
				label={<Text className={styles['block__label']}>Статус</Text>}
				content={
					<IssueBadge value={status}>
						{/*TODO remove any after fixes from backend */}
						<chakra.p>{status && (statusLocaleMap as any)[status]}</chakra.p>
					</IssueBadge>
				}
			/>
			<LabeledBlock
				label={<Text className={styles['block__label']}>Маршрутная карта</Text>}
				content={
					product_tech_map?.number && (
						<Link to={LINK_TO_TECH_CARD_INFO(product_tech_map?.number)} className={styles['link']}>
							<chakra.p>
								{product_tech_map?.symbol
									? `${product_tech_map?.symbol}-${product_tech_map?.number}`
									: product_tech_map?.number}
							</chakra.p>
						</Link>
					)
				}
			/>
			<LabeledBlock
				label={<Text className={styles['block__label']}>Ответственный</Text>}
				content={
					<Text className={styles['block__field']}>
						{responsible_user
							? abbreviateUser(
									responsible_user.first_name ?? '',
									responsible_user.last_name ?? '',
									responsible_user.middle_name ?? '',
							  )
							: STUB}
					</Text>
				}
			/>
			<LabeledBlock
				label={<Text className={styles['block__label']}>Наименование детали</Text>}
				content={<Text className={styles['block__field']}>{product_tech_map?.product?.name}</Text>}
			/>
			<LabeledBlock
				label={<Text className={styles['block__label']}>Операция</Text>}
				content={<Text className={styles['block__field']}>{problem_product_operation?.name ?? STUB}</Text>}
			/>
			<LabeledBlock
				label={<Text className={styles['block__label']}>Исполнитель</Text>}
				content={
					<Text className={styles['block__field']}>
						{executor_user
							? abbreviateUser(
									executor_user.first_name ?? '',
									executor_user.last_name ?? '',
									executor_user.middle_name ?? '',
							  )
							: STUB}
					</Text>
				}
			/>
		</Stack>
	);
};
