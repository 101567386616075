import { EngineerValidateQuestionDocument, Stub } from '@/shared';
import { FC } from 'react';
import { AlertProps } from './alert.types';

export const NoCommentsAlert: FC<AlertProps> = ({ noBackground }) => {
	return (
		<Stub
			noBackground={noBackground}
			icon={<EngineerValidateQuestionDocument />}
			header="Здесь пока нет комментариев"
			label="Вы можете быть первым, кто их оставит"
		/>
	);
};
