import * as yup from 'yup';
import dayjs from 'dayjs';
import { DEFAULT_YEAR_FORMAT } from '@/shared';

export const userFormSchema = (passIsRequired: boolean, isChangePasswordModalOpen: boolean) =>
	yup.object({
		last_name: yup.string().required('Заполните это поле, оно обязательное'),
		first_name: yup.string().required('Заполните это поле, оно обязательное'),
		middle_name: yup.string().required('Заполните это поле, оно обязательное'),
		role: yup.string().required('Заполните это поле, оно обязательное'),
		position: yup.string().nullable().notRequired(),
		date_of_work_start: yup
			.string()
			.default(dayjs(new Date()).format(DEFAULT_YEAR_FORMAT))
			.required('Заполните это поле, оно обязательное'),
		email: yup
			.string()
			.required('Заполните это поле, оно обязательное')
			.email('Пожалуйста, напишите почту в формате email@agat.ru'),
		password: yup.string().when('role', (_, schema) => {
			if (passIsRequired || isChangePasswordModalOpen) {
				return schema
					.min(6, 'Минимальная длина пароля 6 символов')
					.required('Заполните это поле, оно обязательное')
					.matches(
						/^.*(?=.{5,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
						'Пароль не подходит',
					);
			} else {
				return schema.notRequired();
			}
		}),
		confirm_password: yup.string().when('role', (_, schema) => {
			if (passIsRequired || isChangePasswordModalOpen) {
				return schema
					.required('Заполните это поле, оно обязательное')
					.oneOf([yup.ref('password')], 'Пароли не совпадают');
			} else {
				return schema.notRequired();
			}
		}),
	});
