import { TechCardsFiltersNullableArg } from '@/entities/tables/tech-card';
import { forwardRef, useImperativeHandle } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { preformTechMapDefaults } from '../../model/tech-map-defaults';
import { FilterFormHandle, FilterTechMapProps } from '../filter-modal.types';
import styles from '../filter.module.scss';
import {
	NumGostSelect,
	NumGostSortamentSelect,
	NumSelect,
	NumSortamentSelect,
	NumSymbolSelect,
} from '../fields/num-select';
import { CheckboxGroupStatus } from '../fields/status-checkbox-group';
import { CalendarRangePicker, TechCardStatuses, useResetForm } from '@/shared';
import { WastedRadioGroup } from '../fields/wasted-radio-group';

export const FilterPreformTechMap = forwardRef<FilterFormHandle, FilterTechMapProps>(
	({ defaultValue, onSubmit }, ref) => {
		const formProps = useForm<TechCardsFiltersNullableArg>({
			defaultValues: preformTechMapDefaults(defaultValue),
		});

		const { handleSubmit, setValue, getValues } = formProps;
		const { handleResetForm } = useResetForm(getValues, setValue);

		useImperativeHandle(ref, () => ({
			handleResetForm: () => {
				handleResetForm();
				setValue('createdAtFrom', null);
				setValue('createdAtTo', null);
				handleSubmit(onSubmit)();
			},
			requestSubmit() {
				handleSubmit(onSubmit)();
			},
		}));

		const selectCreatedDates = (firstDate: string, secondDate: string) => {
			setValue('createdAtFrom', firstDate);
			setValue('createdAtTo', secondDate);
		};

		return (
			<form className={styles['form']} onSubmit={handleSubmit(onSubmit)}>
				<FormProvider {...formProps}>
					<NumSelect />
					<NumSymbolSelect />
					<NumGostSelect />
					<NumSortamentSelect />
					<NumGostSortamentSelect />
					<CheckboxGroupStatus
						statuses={[
							TechCardStatuses.progress,
							TechCardStatuses.accepted_at_storage,
							TechCardStatuses.partially_issued,
							TechCardStatuses.canceled,
							TechCardStatuses.released,
						]}
					/>
					<WastedRadioGroup />
					<CalendarRangePicker
						textLabel="Дата создания"
						setDates={selectCreatedDates}
						defaultValue={{
							from: defaultValue?.createdAtFrom,
							to: defaultValue?.createdAtTo,
						}}
					/>
				</FormProvider>
			</form>
		);
	},
);
