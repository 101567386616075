import {
	DeleteModal,
	showErrorToast,
	showSuccessToast,
	TECH_CARDS_PATH,
	useShowTechCardButtons,
	useTechCard,
} from '@/shared';
import { isFetchBaseQueryError } from '@/shared/state/api';
import {
	BackendHttpValidationError,
	useChangeProductTechMapFileMutation,
	useDeleteTechmapMutation,
	useGetTechMapExcelMutation,
	useLazyGetFileQuery,
} from '@/shared/state/api/swagger';
import { TechCardCommentsModal } from '@/widgets';
import { DELETE_PRODUCT_TECH_MAP_ERROR } from '@/widgets/tech-card/model/constants';
import {
	Button,
	IconButton,
	Menu,
	MenuButton,
	MenuDivider,
	MenuItem,
	MenuList,
	Portal,
	Tooltip,
	useToast,
} from '@chakra-ui-kraud/react';
import { MadIcon } from 'madsoft-icons';
import { FC, useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useNavigate } from 'react-router-dom';
import { ActionTechCardMenuProps } from './action-tech-card-menu.types';

export const ActionsTechCardMenu: FC<ActionTechCardMenuProps> = ({
	setLinkVariant,
	setIsLinkSchemaModalOpen,
	setIsResetMode,
}) => {
	const { cardInfo, userRole, refetchTechCard } = useTechCard();
	const [getExcel] = useGetTechMapExcelMutation();
	const [getAdditionalFile] = useLazyGetFileQuery();
	const [deleteTechmap] = useDeleteTechmapMutation();
	const [changeTechMapFile] = useChangeProductTechMapFileMutation();
	const toast = useToast();
	const [deleteModalOpen, setDeleteModalOpen] = useState(false);
	const [commentsModalOpen, setCommentsModalOpen] = useState(false);
	const navigate = useNavigate();

	const { open: handleUploadFile } = useDropzone({
		maxFiles: 1,
		accept: {
			'application/pdf': ['.pdf'],
			'application/msword': ['.doc'],
			'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
		},
		onDrop(acceptedFiles) {
			if (!cardInfo?.id) return;
			changeTechMapFile({ id: cardInfo?.id, bodyChangeProductTechMapFile: { file: acceptedFiles[0] } })
				.unwrap()
				.then(() => {
					refetchTechCard().then(() => {
						showSuccessToast(toast, { description: 'Файл успешно загружен' });
					});
				})
				.catch(() => {
					showErrorToast(toast, { description: 'При загрузке файла произошла ошибка' });
				});
		},
	});

	const {
		// отображение кнопки "Аннулировать"
		hideCancelButton,
		// отображение кнопки "История связей"
		hideShowTechCardGraphLink,
		// отображение кнопки "Создать связь"
		hideTechCardCreateLinkButton,
	} = useShowTechCardButtons({ cardInfo, userRole });

	const handleGetExcelFile = useCallback((id: number | undefined) => {
		if (!id) return;
		getExcel({ productTechMapNumber: id })
			.unwrap()
			.then((res) => {
				const href = window.URL.createObjectURL(new Blob([res]));
				const link = document.createElement('a');
				link.href = href;
				link.download = `${id}.xlsx`;
				link.click();
				link.remove();
			})
			.catch(() => {
				showErrorToast(toast, { description: 'При скачивании Excel произошла ошибка' });
			});
	}, []);

	const handleGetAdditionalFile = useCallback(
		(id: string | undefined) => {
			if (!id || !cardInfo?.file) return;
			getAdditionalFile({ id: String(id) })
				.unwrap()
				.then((res: any) => {
					const href = window.URL.createObjectURL(new Blob([res]));
					const link = document.createElement('a');
					link.href = href;
					link.download = String(cardInfo?.file?.filename);
					link.click();
					link.remove();
				})
				.catch(() => {
					showErrorToast(toast, { description: 'При скачивании файла произошла ошибка' });
				});
		},
		[cardInfo],
	);

	const handleDeleteTechCard = useCallback(
		(productTechMapNumber: number) => {
			deleteTechmap({ productTechMapNumber })
				.unwrap()
				.then(() => {
					showSuccessToast(toast, { description: `МК № ${productTechMapNumber} удалена` });
					navigate(TECH_CARDS_PATH);
				})
				.catch((error) => {
					if (isFetchBaseQueryError(error)) {
						if (error.status === DELETE_PRODUCT_TECH_MAP_ERROR) {
							showErrorToast(toast, {
								title: 'Не удалось удалить МК',
								description: `Пока маршрутная карта связана со сборочной картой №${
									(error?.data as BackendHttpValidationError)?.detail?.assembly_tech_map_number
								}, вы не сможете её удалить.`,
							});
							return;
						}
					}
					showErrorToast(toast, { description: 'При удалении МК произошла ошибка' });
				});
			setDeleteModalOpen(false);
		},
		[deleteTechmap, navigate, toast],
	);

	return (
		<>
			<Menu>
				<MenuButton
					as={IconButton}
					size="md"
					variant="ghost"
					colorScheme="tertiary"
					icon={
						<MadIcon module="basic" mode="default" size="default" type="outline" name="ellipsis-vertical" />
					}
				/>
				<MenuList p="10px">
					{!hideTechCardCreateLinkButton && (
						<>
							<MenuItem
								as={Button}
								leftIcon={
									<MadIcon
										module="basic"
										mode="default"
										size="default"
										type="outline"
										name="arrows-right-left"
									/>
								}
								size="sm"
								variant="ghost"
								colorScheme="tertiary"
								onClick={() => setLinkVariant({ isOpen: true, variant: 'edit' })}
								justifyContent="flex-start"
							>
								Связать с существующей МК
							</MenuItem>
							<MenuItem
								as={Button}
								leftIcon={
									<MadIcon module="basic" mode="default" size="default" type="outline" name="plus" />
								}
								size="sm"
								variant="ghost"
								colorScheme="tertiary"
								onClick={() => setLinkVariant({ isOpen: true, variant: 'create' })}
								justifyContent="flex-start"
							>
								Создать МК и связь с ней
							</MenuItem>
						</>
					)}
					{!hideShowTechCardGraphLink && (
						<MenuItem
							as={Button}
							leftIcon={
								<MadIcon module="basic" mode="default" size="default" type="outline" name="link" />
							}
							size="sm"
							variant="ghost"
							colorScheme="tertiary"
							onClick={() => setIsLinkSchemaModalOpen(true)}
							justifyContent="flex-start"
						>
							История связей
						</MenuItem>
					)}
					<MenuDivider />
					<MenuItem
						as={Button}
						leftIcon={
							<MadIcon
								module="basic"
								mode="default"
								size="default"
								type="outline"
								name="arrow-down-tray"
							/>
						}
						size="sm"
						variant="ghost"
						colorScheme="tertiary"
						onClick={() => handleGetExcelFile(cardInfo?.number)}
						justifyContent="flex-start"
					>
						Скачать в Excel
					</MenuItem>
					<MenuDivider />
					<Tooltip isDisabled={!!cardInfo?.file} hasArrow label="У этой МК нет файла">
						<div>
							<MenuItem
								closeOnSelect={!!cardInfo?.file}
								isDisabled={!cardInfo?.file}
								style={{
									opacity: cardInfo?.file ? 1 : 0.5,
									pointerEvents: cardInfo?.file ? 'all' : 'none',
								}}
								as={Button}
								leftIcon={
									<MadIcon
										module="basic"
										mode="default"
										size="default"
										type="outline"
										name="arrow-down-tray"
									/>
								}
								size="sm"
								variant="ghost"
								colorScheme="tertiary"
								onClick={(e) => handleGetAdditionalFile(cardInfo?.file?.id)}
								justifyContent="flex-start"
							>
								Скачать приложения к МК
							</MenuItem>
						</div>
					</Tooltip>
					<MenuItem
						as={Button}
						leftIcon={
							<MadIcon module="basic" mode="default" size="default" type="outline" name="arrow-up-tray" />
						}
						size="sm"
						variant="ghost"
						colorScheme="tertiary"
						onClick={handleUploadFile}
						justifyContent="flex-start"
					>
						Загрузить приложения к МК
					</MenuItem>
					<MenuDivider />
					<MenuItem
						as={Button}
						leftIcon={<MadIcon module="basic" mode="default" size="default" type="outline" name="chat" />}
						size="sm"
						variant="ghost"
						colorScheme="tertiary"
						onClick={() => setCommentsModalOpen(true)}
						justifyContent="flex-start"
					>
						Комментарии
					</MenuItem>
					{!hideCancelButton && (
						<>
							<MenuDivider />

							<MenuItem
								as={Button}
								leftIcon={
									<MadIcon
										module="basic"
										mode="default"
										size="default"
										type="outline"
										name="x-circle"
									/>
								}
								size="sm"
								variant="ghost"
								colorScheme="tertiary"
								onClick={() => setIsResetMode(true)}
								justifyContent="flex-start"
							>
								Аннулировать
							</MenuItem>
						</>
					)}
					{userRole === 'admin' && (
						<MenuItem
							as={Button}
							leftIcon={
								<MadIcon module="basic" mode="default" size="default" type="outline" name="trashcan" />
							}
							size="sm"
							variant="ghost"
							colorScheme="danger"
							onClick={() => setDeleteModalOpen(true)}
							justifyContent="flex-start"
							color="red"
						>
							Удалить карту
						</MenuItem>
					)}
				</MenuList>
			</Menu>
			<Portal>
				{commentsModalOpen && (
					<TechCardCommentsModal
						isOpen={commentsModalOpen}
						onClose={() => setCommentsModalOpen(false)}
						productTechMapId={Number(cardInfo?.id)}
					/>
				)}
				<DeleteModal
					title="Удаление МК"
					text="Вы уверены, что хотите удалить МК?"
					isOpen={deleteModalOpen}
					onClose={() => setDeleteModalOpen(false)}
					onDelete={() => handleDeleteTechCard(Number(cardInfo?.number))}
				/>
			</Portal>
		</>
	);
};
