import { useFormContext } from 'react-hook-form';
import { SelectProps } from 'antd';

export const useHandleSelectOption = (key: string, options?: SelectProps['options']) => {
	const { setValue } = useFormContext();
	const handleSelectOption: SelectProps['onChange'] = (e) => {
		setValue(key, (options?.find((el) => el.value == e)?.label as string) ?? e);
	};

	return {
		handleSelectOption,
	};
};
