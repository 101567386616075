import {
	DefaultProductOperationUpdateInOperation,
	ProductOperationDomain,
	ProductOperationUpdate,
	useGetTechMapQuery,
	useUpdateOperationsOperatorMutation,
} from '@/shared/state/api/swagger';

export const useUpdateOperations = (productTechMapId: number) => {
	const { refetch } = useGetTechMapQuery({ productTechMapId: Number(productTechMapId) });

	const [updateOperation] = useUpdateOperationsOperatorMutation();

	const updateOperations = async (skippedOperations: ProductOperationDomain[]) => {
		return refetch().then(({ data }) => {
			const defaultOperations = data?.default_product_operations?.map((op) => {
				const skippedOperation = skippedOperations.find((skippedOp) => skippedOp.id === op.id);
				return skippedOperation ? skippedOperation : op;
			}) as DefaultProductOperationUpdateInOperation[];

			const operationsPayload = data?.product_operations?.map((op) => {
				const skippedOperation = skippedOperations.find((skippedOp) => skippedOp.id === op.id);
				return skippedOperation ? skippedOperation : op;
			}) as ProductOperationUpdate[];

			return new Promise((resolve, reject) => {
				updateOperation({
					bodyUpdateOperationsOperator: {
						default_operations_payload: defaultOperations,
						operations_payload: operationsPayload,
					},
				})
					.unwrap()
					.then(() => {
						resolve(true);
					})
					.catch((error) => {
						reject(error);
					});
			});
		});
	};

	return { updateOperations };
};
