import { SideModal } from '@/shared';
import { Button, ButtonGroup } from '@chakra-ui-kraud/react';
import { createElement, FC, RefObject, useRef } from 'react';
import * as UI from './filter-forms';
import { FilterFormHandle, FilterModalProps, FilterTechMapProps } from './filter-modal.types';

export const FilterTechMapModal: FC<FilterModalProps> = ({ isOpen, onClose, type, onSubmit, defaultValue }) => {
	const formRef = useRef<FilterFormHandle>(null);

	return (
		<SideModal
			title="Фильтры"
			isOpen={isOpen}
			onClose={onClose}
			footer={
				<ButtonGroup>
					<Button
						size="md"
						variant="ghost"
						colorScheme="tertiary"
						type="button"
						onClick={() => {
							formRef?.current?.handleResetForm();
						}}
					>
						Сбросить
					</Button>
					<Button
						size="md"
						variant="solid"
						colorScheme="primary"
						type="submit"
						onClick={() => {
							formRef?.current?.requestSubmit();
						}}
					>
						Применить
					</Button>
				</ButtonGroup>
			}
		>
			{createElement(UI[type], { defaultValue, onSubmit, ref: formRef } as FilterTechMapProps & {
				ref: RefObject<FilterFormHandle>;
			})}
		</SideModal>
	);
};
