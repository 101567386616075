import * as yup from 'yup';

export const FormT = yup.object({
	product: yup.object({
		id: yup.number().integer().required().default(0),
		name: yup.string().required().default(''),
		extra_name: yup.string().nullable().default(''),
		symbol: yup.string().required().default(''),
		material: yup.object({
			gost: yup.string().nullable(),
			symbol: yup.string().nullable(),
			sortament: yup.string().nullable(),
			gost_na_sortament: yup.string().nullable(),
		}),
	}),
	product_plan: yup.object({
		NR_kg: yup.mixed().nullable(),
		NR_meters: yup.mixed().nullable(),
		NR_square_meters: yup.mixed().nullable(),
		NR_grams: yup.mixed().nullable(),
		count: yup.number().required(),
		list_number: yup.number().nullable().default(1),
		issuance_count: yup.mixed().nullable(),
		fio: yup.string().nullable(),
	}),
	num: yup.object({
		id: yup.number().default(0),
		num: yup.number().nullable().required(),
		gost: yup.string().default(''),
		gost_na_sortament: yup.string().default(''),
		number_of_melt: yup.string().default(''),
		date_of_manufacture: yup.string().default('').nullable(),
		// o_v: yup.string().default(''),
		o_v_or_date_of_manufacture: yup.string().required(),
		sortament: yup.string().default(''),
		symbol: yup.string().default(''),
	}),
});
