import { EngineerValidateSuccess, Stub } from '@/shared';
import { Button, chakra } from '@chakra-ui-kraud/react';
import { OperationAlertProps } from './alert.types';

interface CancelOperationsAlertProps extends OperationAlertProps {
	handleSkipOperations: () => void;
	handleCancelOperations: () => void;
}

export const SkippedOperationsAlert = ({
	handleSkipOperations,
	handleCancelOperations,
}: CancelOperationsAlertProps) => {
	return (
		<Stub
			icon={<EngineerValidateSuccess />}
			header="Операция заполнена"
			label={
				<p>
					Мы сохранили данные о заполненной операции, но вы также пропустили несколько операций. Отмените их
					или оставьте не заполненными.
				</p>
			}
			footer={
				<chakra.div width="100%" display="flex" flexDirection="column" gap="12px">
					<Button variant="solid" width="100%" onClick={handleCancelOperations}>
						Перейти к отмене операций
					</Button>
					<Button variant="outline" width="100%" onClick={handleSkipOperations}>
						Оставить незаполненными
					</Button>
				</chakra.div>
			}
		/>
	);
};
