import type { FC } from 'react';
import type { IAssemblyFormData } from '../model/interfaces/assembly-form.types';

import { useState, useCallback, useRef, useEffect } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { Flex, IconButton } from '@chakra-ui-kraud/react';
import { DefaultOptionType } from 'antd/es/select';
import { debounce } from 'lodash';
import { MadIcon } from 'madsoft-icons';

import { useLazyGetProductsQuery } from '@/shared/state/api/swagger';
import { CustomInput } from '@/shared/components/custom-input';
import { CustomSelect } from '@/shared/components/custom-select';
import { NotFoundContentDropdown } from '@/shared/components/not-found-content-dropdown';

import styles from './assembly-form.module.scss';
import { ReactSortable } from 'react-sortablejs';
import { FormFieldOperation } from '@/shared/components/form-field-operation';
import { FormBlock } from '@/shared/components/form-block';

export const AssemblyForm: FC = () => {
	const { control, setValue, getValues } = useFormContext<IAssemblyFormData>();

	const [productsOptions, setProductsOptions] = useState<
		Record<
			string,
			{
				options: (DefaultOptionType & { product_name: string; product_symbol: string })[];
				search: (query: string) => void;
			}
		>
	>({});
	const productAborts = useRef<Record<string, () => void>>({});
	const productList = useWatch({ control, name: 'products' });
	const [getProducts, productsData] = useLazyGetProductsQuery();

	const operations = useWatch({ control, name: 'operation_types' });
	const sortableOperations = operations.slice(0, operations.length - 1);
	const lastOperation = operations.slice(operations.length - 1)[0];

	const productsSearch = useCallback((idx: number) => {
		return debounce((query: string) => {
			const { abort, unwrap } = getProducts({ stringToSearch: query, pageSize: 1_000_000 });
			productAborts.current[idx] = abort;

			unwrap()
				.then(({ payload }) => {
					// Создаем объект с уникальными значениями для того, чтобы избежать проблем с ключами при ренде списка
					const uniqOptions = payload.reduce((acc, product) => {
						const text = `${product.symbol} (${product.name})`;
						acc[text] = {
							label: text,
							value: text,
							product_name: product.name,
							product_symbol: product.symbol,
						};
						return acc;
					}, {} as Record<string, { label: string; value: string; product_name: string; product_symbol: string }>);

					setProductsOptions((prev) => ({
						...prev,
						[idx]: {
							...prev[idx],
							options: Object.values(uniqOptions),
						},
					}));
				})
				.catch((err) => {
					console.error(err);
				});
		}, 200);
	}, []);

	useEffect(() => {
		setProductsOptions((prev) => {
			const tmp = { ...prev };

			if (productList?.length) {
				productList?.forEach((product, idx) => {
					if (!tmp[idx]) {
						tmp[idx] = {
							search: productsSearch(idx),
							options: [],
						};

						tmp[idx].search('');

						if (product.product_symbol && product.product_name) {
							tmp[idx].options.push({
								...product,
								label: `${product.product_symbol} (${product.product_name})`,
								value: `${product.product_symbol} (${product.product_name})`,
							});
						}
					}
				});
			} else {
				if (!tmp[0]) {
					tmp[0] = {
						search: productsSearch(0),
						options: [],
					};
				}
				tmp[0].search('');
			}

			return tmp;
		});
	}, [productList]);

	return (
		<form>
			<Flex direction="column" gap="32px">
				<FormBlock title="Сборочная единица">
					<Controller
						control={control}
						name="symbol"
						render={({ field, fieldState }) => (
							<CustomInput size="md" {...field} label="Обозначение" isInvalid={!!fieldState.error} />
						)}
					/>
					<Controller
						control={control}
						name="name"
						render={({ field, fieldState }) => (
							<CustomInput size="md" {...field} label="Наименование" isInvalid={!!fieldState.error} />
						)}
					/>
				</FormBlock>
				<FormBlock
					title="Детали"
					action="Добавить деталь"
					onAction={() => {
						setValue('products', [
							...(productList ?? []),
							{ product_name: '', product_symbol: '', value: '' },
						]);
					}}
				>
					{productList?.map((product, idx) => (
						<Controller
							key={product.product_name + product.product_symbol + idx}
							control={control}
							name={`products.${idx}`}
							render={({ field, fieldState }) => (
								<Flex flexDir="row" w="100%" gap="4px" alignItems="center">
									<CustomSelect
										size="middle"
										value={field.value.value.length ? field.value.value : undefined}
										defaultValue={field.value.value.length ? String(field.value.value) : undefined}
										isInvalid={!!fieldState.error}
										showSearch
										labelInValue
										notFoundContent={
											<NotFoundContentDropdown
												isFetchingNaming={productsData.isFetching}
												alertName="Введите назваение"
											/>
										}
										label="Обозначение детали"
										options={productsOptions[idx]?.options ?? []}
										onChange={(value) => {
											const product = productsOptions[idx]?.options.find(
												(product) => product.value === value.value,
											);
											if (product) {
												setValue(`products.${idx}`, {
													value:
														(product.value as string | null | undefined) ??
														`${product.product_symbol} (${product.product_name})`,
													product_name: product.product_name,
													product_symbol: product.product_symbol,
												});
											} else {
												setValue(`products.${idx}`, {
													value: '',
													product_name: '',
													product_symbol: '',
												});
											}
										}}
										onSearch={(query) => {
											productAborts.current[idx]?.();
											productsOptions[idx]?.search(query);
										}}
										onClear={() => {
											setValue(`products.${idx}`, {
												value: '',
												product_name: '',
												product_symbol: '',
											});
										}}
									/>
									<IconButton
										aria-label="delete"
										size="sm"
										variant="ghost"
										colorScheme="danger"
										icon={<MadIcon module="basic" name="trashcan" />}
										isDisabled={!productList?.length || (productList?.length === 1 && idx === 0)}
										onClick={() =>
											setValue(
												'products',
												productList.filter((_, idxFilter) => idx !== idxFilter),
											)
										}
									/>
								</Flex>
							)}
						/>
					))}
				</FormBlock>
				<FormBlock
					title="Операции"
					action="Добавить операцию"
					onAction={() => {
						const lastOperation = operations.pop();
						if (lastOperation) {
							if (operations.length) {
								setValue('operation_types', [
									...(operations ?? []),
									{ name: '', id: new Date().getTime() * -1, step_id: lastOperation.step_id },
									{ ...lastOperation, step_id: lastOperation.step_id + 5 },
								]);
							} else {
								setValue('operation_types', [
									{ name: '', id: new Date().getTime() * -1, step_id: 10 },
									{ ...lastOperation, step_id: 15 },
								]);
							}
						}
					}}
				>
					<Flex direction="column" gap="10px">
						<FormFieldOperation step={5} id={0}>
							<Flex direction="column" gap="6px">
								{productList?.map((product, idx) => (
									<CustomInput
										key={product.value + idx}
										size="sm"
										label=""
										value={
											product.value.length
												? `Комплектовочная (${product.product_name})`
												: 'Комплектовочная'
										}
										isDisabled
									/>
								))}
							</Flex>
						</FormFieldOperation>
						<div className={styles['assembly-form-operation-separator']} />
						<ReactSortable
							handle=".handle"
							animation={150}
							list={sortableOperations}
							setList={(newState) => {
								setValue(
									'operation_types',
									[...newState.filter((el) => !!el), lastOperation].map((operation, idx) => ({
										...operation,
										step_id: (idx + 2) * 5,
									})),
								);
							}}
							className={styles['operations']}
						>
							{sortableOperations.map((operation, idx) => (
								<Controller
									key={operation.id}
									control={control}
									name={`operation_types.${idx}`}
									render={({ field, fieldState }) => {
										return (
											<FormFieldOperation
												step={operation.step_id}
												id={operation.id}
												onDelete={() => {
													const newOperations = operations
														.filter((operation) => operation.id !== field.value.id)
														.map((operation, idx) => ({
															...operation,
															step_id: (idx + 2) * 5,
														}));
													setValue('operation_types', newOperations);
												}}
											>
												<CustomInput
													size="sm"
													label="Наименование операции"
													value={field.value.name}
													isInvalid={!!fieldState.error}
													onChange={(e) => {
														setValue(`operation_types.${idx}`, {
															...getValues(`operation_types.${idx}`),
															name: e.target.value,
														});
													}}
												/>
											</FormFieldOperation>
										);
									}}
								/>
							))}
						</ReactSortable>
						<div className={styles['assembly-form-operation-separator']} />
						<FormFieldOperation step={lastOperation.step_id} id={lastOperation.id}>
							<Controller
								control={control}
								name={`operation_types.${operations.length - 1}`}
								render={({ field, fieldState }) => (
									<CustomInput
										size="sm"
										label="Наименование операции"
										value={field.value.name}
										isInvalid={!!fieldState.error}
										onChange={(e) =>
											setValue(`operation_types.${operations.length - 1}.name`, e.target.value)
										}
									/>
								)}
							/>
						</FormFieldOperation>
					</Flex>
				</FormBlock>
			</Flex>
		</form>
	);
};
