import { ProductPlanDomain } from '@/shared/state/api/swagger';

const stringToNumber = (value: string | number | undefined): number => {
	if (value) {
		const prepareValue = String(value)
			.replace(/[^0-9,.хx]/g, '')
			.replace(/,/g, '.')
			.toLowerCase()
			.replace(/х/g, 'x');
		return prepareValue.split('x').reduce((a, c) => a * Number(c), 1) || 0;
	}
	return 0;
};

export const calculateSummary = ({
	product_releases,
	return_count,
}: {
	product_releases: ProductPlanDomain['product_releases'];
	return_count: number | string | undefined;
}) => {
	const releases_sum =
		(product_releases as ProductPlanDomain['product_releases'])?.reduce(
			(acc, curr) => acc + Number(stringToNumber(curr.count)),
			0,
		) ?? 0;
	const return_sum = Number(stringToNumber(return_count));

	const prepareResult = (releases_sum - return_sum).toFixed(1);
	return String(prepareResult).replace(/\./g, ',');
};
