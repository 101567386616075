import { Table, Tr, Th, Td, Flex, Input, Text } from '@chakra-ui-kraud/react';
import { FC, useEffect, useMemo } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { PlanBlockTypes } from './plan.types';
import defaultStyles from '../../tech-card.module.scss';
import { useAppSelector } from '@/shared/state';
import { calculateSummary, UserRoles } from '@/shared/core';
import { Select } from 'antd';
import styles from './plan.module.scss';
import clsx from 'clsx';
import { StandardConsumptionTypeEnum } from '@/shared/state/api/swagger';
import { convertNumberToNumberStringWithComma } from '@/shared/core/utils/convert-string-to-number-string';
import { RETURN_STANDARD_CONSUMPTION_TYPES, STANDARD_CONSUMPTION_TYPES } from '@/shared/constants';
import { returnFieldNamesMatcher } from './utils/matchers';
import { FormT } from '@/pages/tech-card-page/formConfig';

export const PlanBlock: FC<PlanBlockTypes> = ({
	isEditable,
	isCreationMode,
	cardInfo,
	focusedBlock,
	setFocusedBlock,
	employee,
	isPrinting,
}) => {
	const { control, trigger, setValue } = useFormContext<FormT>();
	const userRole = useAppSelector((state) => state.auth.userProfile?.role);

	const [product_releases, return_count, return_count_square_meters, return_count_kg, return_count_grams] = useWatch({
		control,
		name: [
			'product_plan.product_releases',
			'product_plan.return_count',
			'product_plan.return_count_square_meters',
			'product_plan.return_count_kg',
			'product_plan.return_count_grams',
		],
	});
	const [planCount, planNRKg, planNRMeters, planNRGrams, planNRSquareMeters] = useWatch({
		control,
		name: [
			'product_plan.count',
			'product_plan.NR_kg',
			'product_plan.NR_meters',
			'product_plan.NR_grams',
			'product_plan.NR_square_meters',
		],
	});
	const standardConsumptionType = useWatch({
		control,
		name: 'num.standard_consumption_type',
	});

	const oldTechMap = standardConsumptionType === 'old';

	const isRoleCorrect = useMemo(
		() => [UserRoles.admin, UserRoles.senior_operator].includes(userRole as UserRoles),
		[userRole],
	);

	useEffect(() => {
		if (isCreationMode) trigger();
	}, []);

	useEffect(() => {
		const returns = {
			return_count_grams: return_count_grams,
			return_count_kg: return_count_kg,
			return_count_meters: return_count,
			return_count_square_meters: return_count_square_meters,
		};

		setValue(
			'product_plan.summary',
			calculateSummary({
				product_releases,
				return_count: oldTechMap ? return_count : returns[`return_count_${standardConsumptionType!}`],
			}),
		);
	}, [
		product_releases,
		return_count,
		return_count_square_meters,
		return_count_kg,
		return_count_grams,
		standardConsumptionType,
		oldTechMap,
	]);

	useEffect(() => {
		let count = Number(String(planCount).replace(',', '.'));
		count = Number.isNaN(count) ? 0 : count;

		const kg = Number(String(planNRKg).replace(',', '.'));
		const meters = Number(String(planNRMeters).replace(',', '.'));
		const grams = Number(String(planNRGrams).replace(',', '.'));
		const squareMeters = Number(String(planNRSquareMeters).replace(',', '.'));

		let result = 0;

		if (oldTechMap) {
			// расчет "к выдаче" мк с типом standard_consumption_type = old
			// приоритет расчета в метрах
			if (!!planNRMeters && !Number.isNaN(meters)) {
				result = count * meters;
			} else if (!!planNRKg && !Number.isNaN(kg)) {
				result = count * kg;
			}
		} else {
			// расчет "к выдаче" мк с другими типами
			if (standardConsumptionType === 'kg') result = count * kg;
			else if (standardConsumptionType === 'grams') result = count * grams;
			else if (standardConsumptionType === 'meters') result = count * meters;
			else if (standardConsumptionType === 'square_meters') result = count * squareMeters;
		}

		if (result) {
			setValue('product_plan.issuance_count', result);
		} else {
			setValue('product_plan.issuance_count', 0);
		}
	}, [planCount, planNRKg, planNRMeters, planNRGrams, planNRSquareMeters]);

	const emptyRows = (length: number) => {
		const rows = new Array(length).fill({});
		return rows.map((_, idx) => (
			<Tr
				key={idx}
				className={clsx(
					isCreationMode ? styles['create_plan_middle_block_td'] : styles['plan_middle_block_td'],
				)}
			>
				<Td></Td>
				<Td></Td>
				<Td></Td>
				<Td></Td>
				{oldTechMap && <Td></Td>}
			</Tr>
		));
	};

	return (
		<>
			<Flex
				direction="column"
				marginTop={'-1px'}
				className={clsx(
					styles['plan-block'],
					standardConsumptionType === 'kg' ||
						(standardConsumptionType === 'square_meters' && styles['plan-block_resize']),
				)}
			>
				<Flex h="100%">
					<Table
						h="100%"
						flex={1}
						className={clsx(
							focusedBlock?.block === 'plan' &&
								focusedBlock.focusedBy === 'tab' &&
								!focusedBlock.atCurrentBlock &&
								defaultStyles.focused,
							isEditable && styles['edit-table'],
						)}
						onFocus={() => setFocusedBlock && setFocusedBlock('plan')}
					>
						<Tr className={styles['plan_top_block_th']}>
							<Th w="120px">План шт.</Th>
							{oldTechMap ? (
								<>
									<Th>НР на кг</Th>
									<Th>НР на м</Th>
								</>
							) : (
								<Th>
									НР на{' '}
									{STANDARD_CONSUMPTION_TYPES[standardConsumptionType as StandardConsumptionTypeEnum]}
								</Th>
							)}
							{oldTechMap ? (
								<Th>К выдаче, м</Th>
							) : (
								<Th>
									К выдаче,{' '}
									{STANDARD_CONSUMPTION_TYPES[standardConsumptionType as StandardConsumptionTypeEnum]}
								</Th>
							)}
							<Th w="120px">Подпись</Th>
							<Th w="200px">ФИО ПДС</Th>
						</Tr>
						<Tr className={styles['plan_top_block_td']}>
							<Td>
								{isCreationMode || (isEditable && isRoleCorrect && !isPrinting) ? (
									<Controller
										name="product_plan.count"
										control={control}
										render={({ field: { onChange, value }, fieldState: { error } }) => (
											<Input
												backgroundColor="transparent"
												tabIndex={5}
												isInvalid={!!error}
												onChange={onChange}
												value={value || ''}
												name="product_plan.count"
											/>
										)}
									/>
								) : (
									<Text className={styles['print_bold_font']}>
										{cardInfo?.product_plan?.count || ''}
									</Text>
								)}
							</Td>
							{oldTechMap ? (
								<>
									<Td>
										{isCreationMode || (isEditable && isRoleCorrect && !isPrinting) ? (
											<Controller
												name="product_plan.NR_kg"
												control={control}
												render={({ field: { onChange, value }, fieldState: { error } }) => (
													<Input
														tabIndex={5}
														isInvalid={!!error}
														onChange={onChange}
														defaultValue={''}
														value={convertNumberToNumberStringWithComma(value)}
														name="product_plan.NR_kg"
													/>
												)}
											/>
										) : (
											<Text className={styles['print_bold_font']}>
												{cardInfo?.product_plan?.NR_kg
													? convertNumberToNumberStringWithComma(
															cardInfo?.product_plan?.NR_kg,
													  )
													: '-'}
											</Text>
										)}
									</Td>
									<Td>
										{isCreationMode || (isEditable && isRoleCorrect && !isPrinting) ? (
											<Controller
												name="product_plan.NR_meters"
												control={control}
												render={({ field: { onChange, value }, fieldState: { error } }) => (
													<Input
														tabIndex={5}
														isInvalid={!!error}
														onChange={onChange}
														defaultValue={''}
														value={convertNumberToNumberStringWithComma(value)}
														name="product_plan.NR_meters"
													/>
												)}
											/>
										) : (
											<Text className={styles['print_bold_font']}>
												{cardInfo?.product_plan?.NR_meters
													? convertNumberToNumberStringWithComma(
															cardInfo?.product_plan?.NR_meters,
													  )
													: '-'}
											</Text>
										)}
									</Td>
								</>
							) : (
								<Td>
									{isCreationMode || (isEditable && isRoleCorrect && !isPrinting) ? (
										<Controller
											key={`product_plan.NR_${standardConsumptionType}`}
											name={
												`product_plan.NR_${standardConsumptionType}` as `product_plan.NR_${Exclude<
													StandardConsumptionTypeEnum,
													'old'
												>}`
											}
											control={control}
											render={({ field: { onChange, value }, fieldState: { error } }) => {
												return (
													<Input
														tabIndex={5}
														isInvalid={!!error}
														onChange={onChange}
														defaultValue={''}
														value={convertNumberToNumberStringWithComma(value)}
														name={'product_plan.NR_' + standardConsumptionType}
													/>
												);
											}}
										/>
									) : (
										<Text className={styles['print_bold_font']}>
											{cardInfo?.product_plan[
												`NR_${standardConsumptionType}` as `NR_${Exclude<
													StandardConsumptionTypeEnum,
													'old'
												>}`
											]
												? convertNumberToNumberStringWithComma(
														cardInfo?.product_plan[
															`NR_${standardConsumptionType}` as `NR_${Exclude<
																StandardConsumptionTypeEnum,
																'old'
															>}`
														],
												  )
												: '-'}
										</Text>
									)}
								</Td>
							)}
							<Td>
								{isCreationMode || (isEditable && userRole === UserRoles.admin && !isPrinting) ? (
									<Controller
										name="product_plan.issuance_count"
										control={control}
										render={({ field: { value, onChange, name }, fieldState: { error } }) => (
											<Input
												backgroundColor="transparent"
												name={name}
												onChange={onChange}
												value={convertNumberToNumberStringWithComma(value)}
												tabIndex={5}
												isInvalid={!!error}
											/>
										)}
									/>
								) : (
									<Text className={styles['print_bold_font']}>
										{convertNumberToNumberStringWithComma(cardInfo?.product_plan?.issuance_count)}
									</Text>
								)}
							</Td>
							<Td></Td>
							<Td>
								{(isCreationMode || (isEditable && isRoleCorrect)) && !isPrinting ? (
									<Controller
										name="product_plan.fio"
										control={control}
										render={({ field: { value, onChange }, fieldState: { error } }) => (
											<Select
												status={error?.message?.length ? 'error' : ''}
												tabIndex={5}
												showSearch
												placeholder="Выберите ФИО"
												optionFilterProp="children"
												onChange={(e) => {
													onChange(e);
													setValue(
														'product_plan.fio',
														employee.find((el) => el.value === e)?.label,
														{
															shouldValidate: true,
														},
													);
												}}
												value={employee.find((el) => el.label === value)?.value}
												filterOption={(input, option) =>
													(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
												}
												options={employee}
												style={{
													width: '100%',
													whiteSpace: 'nowrap',
													boxShadow: error?.message ? '0 0 0 1px #fc5e6e' : '',
												}}
												className={styles['custom-select']}
											/>
										)}
									/>
								) : (
									<Text className={styles['print_bold_font']}>{cardInfo?.product_plan.fio}</Text>
								)}
							</Td>
						</Tr>
					</Table>
				</Flex>
				<Flex>
					<Table
						flex={1}
						onFocus={() => setFocusedBlock && setFocusedBlock('plan_in_out')}
						className={clsx(
							focusedBlock?.block === 'plan_in_out' &&
								focusedBlock.focusedBy === 'tab' &&
								!focusedBlock.atCurrentBlock &&
								defaultStyles.focused,
							isEditable && styles['edit-table'],
						)}
					>
						<Tr className={styles['plan_top_block_th']}>
							{oldTechMap ? (
								<>
									<Th minW="180px">Выдано, м/(мхм) х шт.</Th>
									<Th minW="100px">Выдано, кг</Th>
								</>
							) : (
								<Th>
									Выдано,{' '}
									{
										RETURN_STANDARD_CONSUMPTION_TYPES[
											standardConsumptionType as StandardConsumptionTypeEnum
										]
									}
								</Th>
							)}
							<Th minW="120px" maxW="180px">
								Партия (№МК)
							</Th>
							<Th w="120px">Подпись</Th>
							<Th w="200px">ФИО выдавшего материал</Th>
						</Tr>

						{!isEditable && cardInfo && (
							<>
								{cardInfo?.product_plan?.product_releases?.map((item) => (
									<Tr
										key={item.id}
										className={clsx(
											isCreationMode
												? styles['create_plan_middle_block_td']
												: styles['plan_middle_block_td'],
										)}
									>
										{oldTechMap ? (
											<>
												<Td>
													<Text className={styles['print_bold_font']}>{item?.count}</Text>
												</Td>
												<Td>
													<Text className={styles['print_bold_font']}>{item.count_kg}</Text>
												</Td>
											</>
										) : (
											<Td>
												<Text className={styles['print_bold_font']}>{item.count}</Text>
											</Td>
										)}
										<Td>{item?.preform_tech_map_giver_number || item?.number}</Td>
										<Td></Td>
										<Td>
											<Text className={styles['print_bold_font']}>{item?.fio}</Text>
										</Td>
									</Tr>
								))}
							</>
						)}
						{!cardInfo && <>{emptyRows(3)}</>}
						{isEditable && cardInfo && (
							<>
								{cardInfo?.product_plan?.product_releases?.map((field, index) => (
									<Tr
										key={field.id}
										className={
											isCreationMode
												? styles['create_plan_middle_block_td']
												: styles['plan_middle_block_td']
										}
									>
										<Td>
											<Controller
												name={`product_plan.product_releases.${index}.count`}
												control={control}
												render={({ field: { onChange, value }, fieldState: { error } }) => (
													<Input
														backgroundColor="transparent"
														tabIndex={5}
														isInvalid={!!error}
														onChange={onChange}
														value={convertNumberToNumberStringWithComma(
															String(value).replace(/[^0-9,.хx]/g, ''),
														)}
														name={`product_plan.product_releases.${index}.count`}
													/>
												)}
											/>
										</Td>
										{oldTechMap && (
											<Td>
												<Controller
													name={`product_plan.product_releases.${index}.count_kg`}
													control={control}
													render={({ field: { onChange, value }, fieldState: { error } }) => (
														<Input
															tabIndex={5}
															isInvalid={!!error}
															onChange={onChange}
															value={convertNumberToNumberStringWithComma(
																String(value).replace(/[^0-9,.хx]/g, ''),
															)}
															name={`product_plan.product_releases.${index}.count_kg`}
														/>
													)}
												/>
											</Td>
										)}
										<Td>
											<Controller
												name={`product_plan.product_releases.${index}.${
													field.preform_tech_map_giver_number
														? 'preform_tech_map_giver_number'
														: 'number'
												}`}
												control={control}
												render={({ field: { onChange, value }, fieldState: { error } }) => (
													<Input
														backgroundColor="transparent"
														tabIndex={5}
														isInvalid={!!error}
														onChange={onChange}
														value={convertNumberToNumberStringWithComma(
															String(value).replace(/[^0-9,.хx]/g, ''),
														)}
														name={`product_plan.product_releases.${index}.${
															field.preform_tech_map_giver_number
																? 'preform_tech_map_giver_number'
																: 'number'
														}`}
													/>
												)}
											/>
										</Td>
										<Td></Td>
										<Td>
											<Controller
												name={`product_plan.product_releases.${index}.fio`}
												control={control}
												render={({ field: { value, onChange }, fieldState: { error } }) => (
													<Select
														status={error?.message ? 'error' : ''}
														allowClear
														tabIndex={5}
														filterOption={(input, option) =>
															(option?.label ?? '')
																.toLowerCase()
																.includes(input.toLowerCase())
														}
														showSearch
														placeholder="Выберите ФИО"
														optionFilterProp="children"
														onChange={(e) => {
															onChange(e);
															setValue(
																`product_plan.product_releases.${index}.fio`,
																e
																	? employee.find((el) => el.value === Number(e))
																			?.label
																	: '',
															);
														}}
														value={value}
														options={employee}
														style={{ width: '100%', whiteSpace: 'nowrap' }}
														className={styles['custom-select']}
													/>
												)}
											/>
										</Td>
									</Tr>
								))}
							</>
						)}
						<Tr className={styles['plan_top_block_th']}>
							{oldTechMap ? (
								<>
									<Th>Возврат, м/(мхм) х шт.</Th>
									<Th>Возврат, кг</Th>
								</>
							) : (
								<Th>
									Возврат,{' '}
									{
										RETURN_STANDARD_CONSUMPTION_TYPES[
											standardConsumptionType as StandardConsumptionTypeEnum
										]
									}
								</Th>
							)}
							<Th>Партия (№МК)</Th>
							<Th width={'80px'}>Подпись</Th>
							<Th whiteSpace={'nowrap'} width={'200px'}>
								ФИО получившего материал
							</Th>
						</Tr>
						<Tr
							className={
								isCreationMode ? styles['create_plan_middle_block_td'] : styles['plan_middle_block_td']
							}
						>
							{oldTechMap ? (
								<>
									<Td>
										{isEditable && !!cardInfo ? (
											<Controller
												name="product_plan.return_count"
												control={control}
												render={({ field: { onChange, value }, fieldState: { error } }) => (
													<Input
														tabIndex={5}
														isInvalid={!!error}
														onChange={onChange}
														value={convertNumberToNumberStringWithComma(
															String(value).replace(/[^0-9,.хx]/g, ''),
														)}
														name="product_plan.return_count"
														backgroundColor="transparent"
													/>
												)}
											/>
										) : (
											<Text className={styles['print_bold_font']}>
												{cardInfo?.product_plan?.return_count}
											</Text>
										)}
									</Td>
									<Td>
										{isEditable && !!cardInfo ? (
											<Controller
												name="product_plan.return_count_kg"
												control={control}
												render={({ field: { onChange, value }, fieldState: { error } }) => (
													<Input
														tabIndex={5}
														isInvalid={!!error}
														onChange={onChange}
														value={convertNumberToNumberStringWithComma(
															String(value).replace(/[^0-9,.хx]/g, ''),
														)}
														name="product_plan.return_count_kg"
													/>
												)}
											/>
										) : (
											<Text className={styles['print_bold_font']}>
												{cardInfo?.product_plan?.return_count_kg}
											</Text>
										)}
									</Td>
								</>
							) : (
								<Td>
									{isEditable && !!cardInfo ? (
										<Controller
											key={`product_plan.${
												returnFieldNamesMatcher[`return_count_${standardConsumptionType!}`]
											}`}
											name={`product_plan.${
												returnFieldNamesMatcher[`return_count_${standardConsumptionType!}`]
											}`}
											control={control}
											render={({ field: { onChange, value }, fieldState: { error } }) => (
												<Input
													tabIndex={5}
													isInvalid={!!error}
													onChange={onChange}
													value={convertNumberToNumberStringWithComma(
														String(value).replace(/[^0-9,.хx]/g, ''),
													)}
													name="product_plan.return_count"
													backgroundColor="transparent"
												/>
											)}
										/>
									) : (
										<Text className={styles['print_bold_font']}>
											{
												cardInfo?.product_plan?.[
													returnFieldNamesMatcher[`return_count_${standardConsumptionType!}`]
												]
											}
										</Text>
									)}
								</Td>
							)}
							<Td>
								{isEditable && !!cardInfo ? (
									<Controller
										name="product_plan.return_number"
										control={control}
										render={({ field: { onChange, value }, fieldState: { error } }) => (
											<Input
												tabIndex={5}
												isInvalid={!!error}
												onChange={onChange}
												value={convertNumberToNumberStringWithComma(
													String(value).replace(/[^0-9,.хx]/g, ''),
												)}
												name="product_plan.return_number"
												backgroundColor="transparent"
											/>
										)}
									/>
								) : (
									<>{cardInfo?.product_plan?.return_number}</>
								)}
							</Td>
							<Td></Td>
							<Td>
								{isEditable && !!cardInfo ? (
									<Controller
										name="product_plan.return_fio"
										control={control}
										render={({ field: { value, onChange } }) => (
											<Select
												allowClear
												tabIndex={5}
												filterOption={(input, option) =>
													(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
												}
												showSearch
												placeholder="Выберите ФИО"
												optionFilterProp="children"
												onChange={(e) => {
													onChange(e);
													setValue(
														'product_plan.return_fio',
														e ? employee.find((el) => el.value === Number(e))?.label : '',
													);
												}}
												value={value}
												options={employee}
												style={{ width: '100%', whiteSpace: 'nowrap' }}
												className={styles['custom-select']}
											/>
										)}
									/>
								) : (
									<Text className={styles['print_bold_font']}>
										{cardInfo?.product_plan?.return_fio}
									</Text>
								)}
							</Td>
						</Tr>
					</Table>
				</Flex>
			</Flex>
		</>
	);
};
