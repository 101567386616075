import { mainBlockId, pirntAreaBlockId } from '@/shared';
import { useCallback, useEffect } from 'react';

export const useMapResize = () => {
	const handleResize = useCallback(() => {
		const inner = document.getElementById(pirntAreaBlockId);
		const mainBLock = document.getElementById(mainBlockId);
		if (!inner) return;
		const innerBase = inner.scrollWidth;
		const width = mainBLock?.clientWidth;
		if (!width) return;

		if (width < innerBase) {
			inner.style.transform = `scale(${width / (innerBase + 24)})`;
			inner.style.transformOrigin = '0 0';
		} else {
			inner.style.transform = 'scale(1)';
			inner.style.transformOrigin = '0 0';
		}
	}, []);

	// hotfix
	// нормально не получается скейлить карту, у которой есть связи
	// TODO
	handleResize();

	useEffect(() => {
		handleResize();
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, [handleResize]);
};
