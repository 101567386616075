import { useEffect } from 'react';
import { Tab, TabList, TabPanel, TabPanels, Tabs, useToast, chakra } from '@chakra-ui-kraud/react';
import {
	IssueReportResponse,
	IssueReportTypeEnum,
	useGetIssueStuckOperationDetailsQuery,
	useGetIssueTypeMappingQuery,
	useGetIssueWastedLimitDetailsQuery,
} from '@/shared/state/api/swagger';
import { IssueFormModalProps } from '../model/issue-form.types';
import { IssueDetails } from '@/entities/issue';
import { SideModal, showErrorToast, DEFAULT_YEAR_FORMAT } from '@/shared';
import styles from './issue.module.scss';
import dayjs from 'dayjs';
import { IssueFormComments } from './issue-form-comments';
import { useSearchParams } from 'react-router-dom';

export const IssueFormModal = ({ onClose, isOpen }: IssueFormModalProps) => {
	const [searchParams] = useSearchParams();
	const type = searchParams.get('issue_type') as IssueReportTypeEnum;
	const issueId = Number.parseInt(searchParams.get('issue_id') ?? '');
	const isStuck = type === 'stuck_operation';

	const stuckIssue = useGetIssueStuckOperationDetailsQuery({ issueId }, { skip: !isStuck });
	const wastedIssue = useGetIssueWastedLimitDetailsQuery({ issueId }, { skip: isStuck });
	const { data = {}, isError } = isStuck ? stuckIssue : wastedIssue;
	const issue = ('issue_report' in data ? data.issue_report : data) as IssueReportResponse;
	const { id, comments } = issue;

	const { data: stuckDetails } = stuckIssue;
	const { stuck_product_operation } = stuckDetails ?? {};

	const { data: typeLocaleMap = {} } = useGetIssueTypeMappingQuery();

	const toast = useToast();
	useEffect(() => {
		if (isError) {
			showErrorToast(toast, {
				description: 'Не удалось загрузить детали операции проблемы',
			});
		}
	}, [isError]);

	return (
		<SideModal title={(typeLocaleMap as any)[type]} onClose={onClose} isOpen={isOpen}>
			<IssueDetails {...issue} />
			{isStuck ? (
				<Tabs className={styles['tabs']} isFitted>
					<TabList>
						<Tab className={styles['tabs__tab']}>Текущее состояние</Tab>
						<Tab className={styles['tabs__tab']}>Комментарии</Tab>
					</TabList>
					<TabPanels>
						<TabPanel className={styles['tabs__panel']}>
							<chakra.p pt={4}>
								Находится на операции {stuck_product_operation?.name} с{' '}
								{dayjs(stuck_product_operation?.created_at).format(DEFAULT_YEAR_FORMAT)}
							</chakra.p>
						</TabPanel>
						<TabPanel className={`${styles['tabs__panel']} ${styles['comments']}`}>
							<IssueFormComments issueId={id} comments={comments} />
						</TabPanel>
					</TabPanels>
				</Tabs>
			) : (
				<chakra.div className={`${styles['comments']} ${styles['tabs']}`}>
					<chakra.p className={styles['comments__title']}>Комментарии</chakra.p>
					<IssueFormComments issueId={id} comments={comments} />
				</chakra.div>
			)}
		</SideModal>
	);
};
