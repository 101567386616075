import { Flex } from '@chakra-ui-kraud/react';
import clsx from 'clsx';
import { FC } from 'react';
import { CustomOperationsAssemblyBlock } from '../custom-operations/custom-operations-assembly';
import { DefaultOperationsAssemblyBlock } from '../default-operations/default-operations-assembly';
import { OperationsAssemblyBlockTypes } from '../model/operations.types';
import styles from './operations.module.scss';

export const OperationsAssemblyBlock: FC<OperationsAssemblyBlockTypes> = ({ isEditable }) => {
	return (
		<Flex className={clsx(styles['operations'], styles['operations__assembly'])} direction="column">
			<CustomOperationsAssemblyBlock isEditable={isEditable} />
			<DefaultOperationsAssemblyBlock isEditable={isEditable} />
		</Flex>
	);
};
