import * as yup from 'yup';

export const FormT = yup.object({
	product: yup.object({
		symbol: yup.string().required().default(''),
	}),
	product_plan: yup.object({
		count: yup.number().required(),
		fio: yup.string().nullable(),
	}),
	num: yup.object({
		id: yup.number().default(0),
		o_v_or_date_of_manufacture: yup.string().required(),
	}),
});
