import type { FC } from 'react';
import type { FormFieldOperationProps } from './form-field-operation.types';

import { MadIcon } from 'madsoft-icons';
import { IconButton } from '@chakra-ui-kraud/react';

import { GripHorizontalIcon } from '@/shared/assets';

import styles from './form-field-operation.module.scss';
import clsx from 'clsx';

export const FormFieldOperation: FC<FormFieldOperationProps> = ({ step, children, id, onDelete }) => {
	return (
		<label key={id} className={clsx(styles['operation'], !onDelete && styles['operation_not-draggable'])}>
			<div className={styles['operation-content']}>
				<span className={clsx('handle', styles['operation-step-id'])}>
					<span className={styles['operation-step-id-number']}>{String(step).padStart(2, '0')}</span>
					<span className={styles['operation-step-id-icon']}>
						<GripHorizontalIcon />
					</span>
				</span>
				<div className={styles['operation-content-data']}>{children}</div>
				{onDelete && (
					<IconButton
						aria-label="delete"
						icon={<MadIcon type="outline" mode="default" size="default" module="basic" name="trashcan" />}
						size="sm"
						variant="ghost"
						colorScheme="danger"
						backgroundColor="white"
						onClick={onDelete}
					/>
				)}
			</div>
		</label>
	);
};
