import * as yup from 'yup';

export const assemblyCreateValidation = yup.object({
	symbol: yup.string().min(1).required('Ведите обзначение'),
	name: yup.string().min(1).required('Ведите наименование'),
	products: yup.array().of(
		yup.object({
			product_symbol: yup.string().min(1).required('Ведите обозначение'),
			product_name: yup.string().min(1).required('Ведите наименование'),
		}),
	),
	operation_types: yup.array().of(
		yup.object({
			name: yup.string().min(1).required('Ведите наименование операции'),
			step_id: yup.number().min(0).required('Ведите наименование шаг'),
		}),
	),
});
