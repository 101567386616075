import { selectTechMapSymbolOptions } from '@/entities/tech-map';
import { useGetTechMapSymbolCatalogQuery } from '@/shared/state/api/swagger';
import { FC } from 'react';
import { filterOption } from '../../lib/filter-option';
import { FilterSelect } from './filter-select';

export const GroupSelect: FC<{ name?: string }> = ({ name = 'symbol' }) => {
	const { options, isFetching } = useGetTechMapSymbolCatalogQuery(undefined, {
		selectFromResult: (result) => ({ ...result, options: selectTechMapSymbolOptions(result?.data) }),
	});

	return (
		<FilterSelect
			label="Группа"
			name={name}
			options={options}
			isLoading={isFetching}
			alertName="Введите группу"
			filterOption={filterOption}
		/>
	);
};
