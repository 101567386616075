import { selectEmployesForOptions } from '@/entities/employee';
import { CustomInput, CustomSelect, DEFAULT_YEAR_FORMAT, OVorDateSelectOption } from '@/shared';
import {
	FileResponse,
	PreformTechMapResponse,
	useGetEmployeeInitialsQuery,
	useGetMaterialsQuery,
	useGetProductsQuery,
} from '@/shared/state/api/swagger';
import { Stack } from '@chakra-ui-kraud/react';
import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import { forwardRef, useCallback, useImperativeHandle, useState } from 'react';
import { Controller, useForm, useFormContext } from 'react-hook-form';
import { FormT } from '../model/schema';
import {
	FormHandle,
	type FormT as FormType,
	TechCardPreformFormProps,
} from '../model/tech-card-preform-form-modal.types';
import _debounce from 'lodash/debounce';
import { convertNumberToNumberStringWithComma } from '@/shared/core/utils/convert-string-to-number-string';

export const TechCardPreformForm = forwardRef<FormHandle, TechCardPreformFormProps>(({ onSubmit }, ref) => {
	const { getValues: getValuesEditPreformForm } = useFormContext<PreformTechMapResponse>();

	const num = getValuesEditPreformForm('preform_num');

	const [productsQuery, setProductsQuery] = useState('');
	const debouncedProductsSearch = useCallback(
		_debounce((search: string) => setProductsQuery(search), 300),
		[],
	);

	const formProps = useForm<FormType>({
		resolver: yupResolver(FormT),
		defaultValues: {
			num: {
				...num,
				replace_decision: undefined,
			},
			product_plan: {
				count: undefined,
				fio: undefined,
			},
		},
	});

	const OVorDateSelectOptions = [
		getValuesEditPreformForm('preform_num.o_v') && {
			value: 'o_v',
			label: getValuesEditPreformForm('preform_num.o_v'),
		},
		getValuesEditPreformForm('preform_num.date_of_manufacture') && {
			value: 'date_of_manufacture',
			label: dayjs(getValuesEditPreformForm('preform_num.date_of_manufacture')).format(DEFAULT_YEAR_FORMAT),
		},
		{ value: 'empty', label: '-' },
	].filter(Boolean) as OVorDateSelectOption[];

	useImperativeHandle(ref, () => {
		return {
			onSubmit() {
				handleSubmit(onSubmit)();
			},
			getValues,
		};
	});

	const { handleSubmit, setValue, control, getValues } = formProps;

	const { data: products, options: productOptions } = useGetProductsQuery(
		{
			standardConsumption: 'meters',
			stringToSearch: productsQuery,
		},
		{
			selectFromResult: (result) => ({
				...result,
				options:
					result?.data?.payload?.map((product) => ({
						value: product.id,
						label: product.symbol,
					})) || [],
			}),
		},
	);

	const { options: employeeOptions, isFetching: isEmployeesFetching } = useGetEmployeeInitialsQuery(undefined, {
		selectFromResult: (result) => ({
			...result,
			options: selectEmployesForOptions(result?.data),
		}),
	});

	const { data: materials } = useGetMaterialsQuery();

	const handleProductSelect = (value: string | number) => {
		setValue('product_operations', []);
		const selectedProduct = products?.payload?.find((product) => product.id === Number(value));
		const selectedMaterial = materials?.find((material) => material.id === selectedProduct?.material_id);

		if (selectedProduct?.material_id) {
			setValue(
				'product',
				{
					...selectedProduct,
					// @ts-ignore TODO
					product_catalog_id: selectedProduct.id,
					material_id: selectedProduct.material_id,
					file: selectedProduct?.file as FileResponse,
					id: selectedProduct?.id || 0,
					symbol: selectedProduct?.symbol || '',
					extra_symbol: selectedProduct?.extra_symbol || '',
					extra_name: selectedProduct?.extra_name,
					name: selectedProduct?.name || '-',
					approval_card: selectedProduct?.approval_card || '',
					extra_approval_card: selectedProduct?.extra_approval_card || '',
				},
				{ shouldValidate: true },
			);
		}

		setValue(
			'product.material',
			{
				id: selectedMaterial?.id || 0,
				symbol: selectedMaterial?.symbol || '-',
				gost: selectedMaterial?.gost || '-',
				sortament: selectedMaterial?.sortament || '-',
				gost_na_sortament: selectedMaterial?.gost_na_sortament || '-',
			},
			{ shouldValidate: true },
		);

		const sortedOperationsTypes = [...(selectedProduct?.operation_types ?? [])]?.sort(
			(a, b) => (a.step_id ?? 0) - (b.step_id ?? 0),
		);
		setValue(
			'product_plan.NR_meters',
			convertNumberToNumberStringWithComma(selectedProduct?.standard_consumption_meters),
			{ shouldValidate: true },
		);
		sortedOperationsTypes?.forEach((operation, index) => {
			setValue(`product_operations.${index}.step_id`, operation.step_id ?? 0);
			setValue(`product_operations.${index}.name`, operation.name);
		});
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<Stack gap={3}>
				<Controller
					name="product.symbol"
					control={control}
					render={({ field, fieldState: { error } }) => (
						<CustomSelect
							{...field}
							label={'Выберите обозначение для новой МК'}
							allowClear={false}
							initialValue={undefined}
							onSearch={debouncedProductsSearch}
							onSelect={handleProductSelect}
							options={productOptions}
							isInvalid={!!error}
						/>
					)}
				/>
				<Controller
					name="num.id"
					control={control}
					render={({ field }) => (
						<CustomSelect
							{...field}
							label={'Выберите НУМ для новой МК'}
							disabled
							allowClear={false}
							options={[]}
							defaultValue={num?.num?.toString()}
							value={num?.num?.toString()}
						/>
					)}
				/>
				<Controller
					name="num.o_v_or_date_of_manufacture"
					render={({ field: { onChange, value, ...field }, fieldState: { error } }) => {
						return (
							<CustomSelect
								{...field}
								value={value}
								onChange={onChange}
								initialValue={undefined}
								options={OVorDateSelectOptions}
								label={'σв / дата изготовления'}
								style={{ width: '100%' }}
								allowClear={false}
								isInvalid={!!error}
								onSelect={(value) => {
									setValue('num.o_v_or_date_of_manufacture', value);
								}}
							/>
						);
					}}
				/>

				<Controller
					name="num.replace_decision"
					control={control}
					render={({ field, fieldState: { error } }) => (
						<CustomInput
							{...field}
							label="Решение о замене материала"
							isInvalid={!!error}
							onChange={(e) => {
								setValue('num.replace_decision', e.target.value);
							}}
						/>
					)}
				/>
				<Controller
					name="product_plan.count"
					control={control}
					render={({ field, fieldState: { error } }) => {
						return (
							<CustomInput
								{...field}
								label="Введите план по количеству деталей"
								isInvalid={!!error}
								onChange={(e) => {
									setValue('product_plan.count', Number(e.target.value.replace(/\D/g, '')));
								}}
							/>
						);
					}}
				/>
				<Controller
					name="product_plan.fio"
					control={control}
					render={({ field, fieldState: { error } }) => (
						<CustomSelect
							{...field}
							label={'Выберите ФИО ПДС'}
							allowClear
							onClear={() => {
								setValue('product_plan.fio', '');
							}}
							onChange={(e) => {
								setValue('product_plan.fio', employeeOptions.find((el) => el.value === e)?.label, {
									shouldValidate: true,
								});
							}}
							value={employeeOptions.find((el) => el.label === field.value)?.value}
							initialValue={undefined}
							options={employeeOptions}
							isInvalid={!!error}
							isLoading={isEmployeesFetching}
						/>
					)}
				/>
			</Stack>
		</form>
	);
});
