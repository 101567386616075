import { FC, useEffect } from 'react';
import type { FileDnDProps } from './file-dnd.types';

import { useState, useCallback, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import { Button, chakra, Flex, Text } from '@chakra-ui-kraud/react';

import styles from './file-dnd.module.scss';
import classnames from 'classnames';
import { MadIcon } from 'madsoft-icons';

export const FileDnD: FC<FileDnDProps> = ({
	containerClassName,
	onDownload,
	title,
	description = 'Нажмите, чтобы загрузить файл или перетащите его сюда',
	selected = false,
	background: backgroundProp,
	iconName,
	disabled = false,
	accepted = false,
	children,
	onDropRejected,
	isActive,
	isError = false,
	rejectDuration = 4_000,
	...props
}) => {
	const [isHovered, setHovered] = useState<boolean>(false);
	const [isReject, setReject] = useState<boolean>(isError);

	const dropZoneState = useDropzone({
		...props,
		disabled,
		noClick: true,
		onDropRejected: (...arg) => {
			setReject(true);
			onDropRejected && onDropRejected(...arg);
		},
	});
	const { getInputProps, getRootProps, isDragAccept, isDragReject, isDragActive, isFileDialogActive, open } =
		dropZoneState;

	useEffect(() => {
		if (isReject) {
			setTimeout(() => setReject(false), rejectDuration);
		}
	}, [isReject]);

	useEffect(() => {
		if (isError) {
			setReject(true);
		}
	}, [isError]);

	const borderColor = useMemo(() => {
		let color = 'gray.400';
		if (
			isDragActive ||
			isHovered ||
			isFileDialogActive ||
			isDragAccept ||
			isActive ||
			//fix because the files ( jpg\jpeg ) are of a common type
			isDragReject
		) {
			color = 'primary.600';
		}

		if (isReject || (!isDragAccept && isDragActive)) color = 'danger.600';

		return color;
	}, [isHovered, isDragAccept, isDragReject, isDragActive, isFileDialogActive, isReject, isActive]);

	const textColor = useMemo(() => {
		let color = 'gray.400';

		if (!isDragAccept && isDragActive) color = 'gray.100';

		if (isDragActive || isHovered || isFileDialogActive) {
			color = 'primary.600';
		}

		if (isDragAccept || isDragReject || isReject) {
			color = 'gray.800';
		}

		return color;
	}, [isHovered, isDragAccept, isDragReject, isDragActive, isFileDialogActive, isReject]);

	const background = useMemo(() => {
		let color = 'transparent';

		if (isReject || (!isDragAccept && isDragActive)) color = 'danger.50';

		if (isDragAccept) color = 'primary.50';
		if (isReject) color = 'danger.50';

		return color;
	}, [isDragAccept, isReject, isDragActive]);

	const onMouseEnter = useCallback(() => setHovered(true), []);
	const onMouseLeave = useCallback(() => setHovered(false), []);

	if (accepted) {
		return (
			<chakra.div className={classnames(styles['dnd-container'], styles['dnd-container_accepted'])}>
				<Flex w="100%" justifyContent="space-between" alignItems="center">
					<Flex gap={2}>
						<MadIcon module="basic" mode="default" size="default" type="outline" name="document-text" />

						<Text tag="p" fontSize="xs" textAlign="center" lineHeight="4" fontWeight="normal">
							Файл загружен
						</Text>
					</Flex>
					<Flex gap={2}>
						<Button onClick={open} variant="outline" colorScheme="tertiary">
							Заменить
						</Button>
						{onDownload && (
							<Button onClick={onDownload} variant="outline" colorScheme="tertiary">
								Скачать
							</Button>
						)}
					</Flex>
				</Flex>
			</chakra.div>
		);
	} else
		return (
			<chakra.div
				{...getRootProps({
					className: classnames(
						styles['dnd-container'],
						(isDragActive || isHovered || isFileDialogActive || isActive) &&
							styles['dnd-container_focused'],
						selected && styles['dnd-container_selected'],
						disabled && styles['dnd-container_disabled'],
						containerClassName,
					),
					color: selected ? 'gray.100' : textColor,
					onMouseLeave,
					onMouseEnter,
				})}
				onClick={() => !children && open()}
				borderColor={selected ? 'transparent' : borderColor}
				bg={background}
				_hover={{
					background: selected ? 'surface.blackout' : 'blackAlpha.50',
				}}
			>
				<input {...getInputProps()} disabled={disabled} />

				{children ? (
					children({ ...dropZoneState, isHovered, isReject })
				) : !selected ? (
					isDragActive ? (
						<Text tag="p" fontSize="xs" lineHeight="4" fontWeight="normal">
							Отпустите файл, чтобы загрузить
						</Text>
					) : (
						<>
							<Text tag="p" fontSize="xs" lineHeight="4" fontWeight="semibold">
								{title}
							</Text>
							<Text tag="p" fontSize="xs" lineHeight="4" fontWeight="normal">
								{description}
							</Text>
						</>
					)
				) : (
					<>
						<div
							className={classnames(
								styles['dnd-container-background'],
								isHovered && styles['dnd-container-background-hovered'],
							)}
						>
							{backgroundProp}
						</div>
						{isHovered && (
							<>
								{iconName && <chakra.i className={iconName} fontSize="24px" />}
								<Text tag="p" fontSize="xs" lineHeight="4" fontWeight="normal">
									Нажмите, чтобы загрузить файл
								</Text>
							</>
						)}
					</>
				)}
			</chakra.div>
		);
};
