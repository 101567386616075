import { calculateSummary, StandardConsumptionTypeEnum, UserRoles } from '@/shared/core';
import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import {
	CustomNumDomain,
	ProductOperationDomain,
	ProductCatalogResponse,
	ProductTechMapResponse,
	UserRoleEnum,
	CustomProductResponse,
} from '@/shared/state/api/swagger';

const validateCount = (val: any) => !val || !isNaN(Number(val)) || /^-{1,}$/.test(String(val));

const creationValidationScheme = yup.object({
	created_at: yup.string().nullable(),
	product: yup.object({
		id: yup.number().integer().required().default(0),
		name: yup.string().required().default(''),
		extra_name: yup.string().nullable().default(''),
		symbol: yup.string().required().default(''),
		material: yup.object({
			gost: yup.string().required().default(''),
			symbol: yup.string().required().default(''),
			sortament: yup.string().required().default(''),
			gost_na_sortament: yup.string().required().default(''),
		}),
	}),
	product_plan: yup.object({
		NR_kg: yup
			.mixed()
			.nullable()
			.test('NR_kg-create-validation', 'NR_kg-create-validation', (val) => {
				const currentValue = Number(String(val).replace(',', '.'));
				return !val || (!isNaN(currentValue) && currentValue > 0) || /^-{1,}$/.test(String(val));
			}),
		NR_meters: yup
			.mixed()
			.nullable()
			.test('NR_meters-create-validation', 'NR_meters-create-validation', (val) => {
				const currentValue = Number(String(val).replace(',', '.'));
				return !val || (!isNaN(currentValue) && currentValue > 0) || /^-{1,}$/.test(String(val));
			}),
		NR_square_meters: yup
			.mixed()
			.nullable()
			.test('NR_square_meters-create-validation', 'NR_square_meters-create-validation', (val) => {
				const currentValue = Number(String(val).replace(',', '.'));
				return !val || (!isNaN(currentValue) && currentValue > 0) || /^-{1,}$/.test(String(val));
			}),
		NR_grams: yup
			.mixed()
			.nullable()
			.test('NR_grams-create-validation', 'NR_grams-create-validation', (val) => {
				const currentValue = Number(String(val).replace(',', '.'));
				return !val || (!isNaN(currentValue) && currentValue > 0) || /^-{1,}$/.test(String(val));
			}),
		count: yup.number().required(),
		list_number: yup.number().nullable().default(1),
		issuance_count: yup
			.mixed()
			.nullable()
			.test(
				'validity',
				'',
				(val) => !val || !isNaN(Number(String(val).replace(',', '.'))) || /^-{1,}$/.test(String(val)),
			),
		fio: yup.string().required(),
	}),
	num: yup.object({
		id: yup.number().default(0),
		num: yup.number().nullable().required(),
		gost: yup.string().default(''),
		gost_na_sortament: yup.string().default(''),
		number_of_melt: yup.string().default(''),
		date_of_manufacture: yup.string().default('').nullable(),
		// o_v: yup.string().default(''),
		o_v_or_date_of_manufacture: yup.string().required(),
		sortament: yup.string().default(''),
		symbol: yup.string().default(''),
	}),
});

const editValidationScheme = yup.object({
	created_at: yup.string().nullable(),
	number: yup
		.string()
		.min(3)
		.test('is-valid-number', 'number not valid', (val) => {
			const year = Number((val ?? '')?.trim().slice(0, 2));

			if (Number.isNaN(year)) return false;

			return new Date().getFullYear() % 100 >= year;
		}),
	symbol: yup
		.string()
		.test('len', 'Must be less than 2 characters', (val) => (val ? val?.length <= 2 : true))
		.notRequired(),
	count_gram: yup.number().nullable(),
	count_number: yup.number().nullable(),
	storage_at: yup
		.string()
		.nullable()
		.transform((value) => {
			if (value && dayjs(value).isValid()) {
				return dayjs(value).toISOString();
			}
			return value;
		}),
	product: yup.object({
		id: yup.number().integer().required().default(0),
		name: yup.string().required().default(''),
		extra_name: yup.string().nullable().default(''),
		symbol: yup.string().required().default(''),
		approval_card: yup.string().nullable().default(''),
		extra_approval_card: yup.string().nullable().default(''),
		material: yup.object({
			gost: yup.string().required().default(''),
			symbol: yup.string().required().default(''),
			sortament: yup.string().required().default(''),
			gost_na_sortament: yup.string().required().default(''),
		}),
	}),
	product_plan: yup.object({
		NR_kg: yup
			.mixed()
			.nullable()
			.test('NR_kg-create-validation', 'NR_kg-create-validation', (val) => {
				const currentValue = Number(String(val).replace(',', '.'));
				return !val || (!isNaN(currentValue) && currentValue > 0) || /^-{1,}$/.test(String(val));
			}),
		NR_meters: yup
			.mixed()
			.nullable()
			.test(
				'NR_meters-edit-validation',
				'NR_meters-edit-validation',
				(val) => !val || !isNaN(Number(String(val).replace(',', '.'))) || /^-{1,}$/.test(String(val)),
			),
		NR_square_meters: yup
			.mixed()
			.nullable()
			.test('NR_square_meters-create-validation', 'NR_square_meters-create-validation', (val) => {
				const currentValue = Number(String(val).replace(',', '.'));
				return !val || (!isNaN(currentValue) && currentValue > 0) || /^-{1,}$/.test(String(val));
			}),
		NR_grams: yup
			.mixed()
			.nullable()
			.test('NR_grams-create-validation', 'NR_grams-create-validation', (val) => {
				const currentValue = Number(String(val).replace(',', '.'));
				return !val || (!isNaN(currentValue) && currentValue > 0) || /^-{1,}$/.test(String(val));
			}),
		count: yup.number().nullable(),
		fio: yup.string().nullable().default(''),
		issuance_count: yup
			.mixed()
			.nullable()
			.test(
				'validity',
				'',
				(val) => !val || !isNaN(Number(String(val).replace(',', '.'))) || /^-{1,}$/.test(String(val)),
			),
		return_count: yup.string().nullable().default(''),
		return_count_kg: yup.string().nullable().default(''),
		return_count_grams: yup.string().nullable().default(''),
		return_count_square_meters: yup.string().nullable().default(''),
		return_fio: yup.string().nullable().default(''),
		list_number: yup.string().default('1'),
		tech_decision: yup.string().nullable().default(''),
		summary: yup.string().nullable(),
		product_releases: yup.array().of(
			yup.object({
				count: yup.string().nullable(),
				count_kg: yup.string().nullable(),
				number: yup.string().nullable(),
				fio: yup.string().nullable(),
			}),
		),
	}),
	num: yup.object({
		id: yup.number().default(0),
		num: yup.string().default(''),
		gost: yup.string().default(''),
		gost_na_sortament: yup.string().default(''),
		number_of_melt: yup.string().default(''),
		date_of_manufacture: yup.string().default('').nullable(),
		// o_v: yup.string().default(''),
		sortament: yup.string().default(''),
		symbol: yup.string().default(''),
		o_v_or_date_of_manufacture: yup.string().required(),
	}),

	product_operations: yup.array().of(
		yup.object().shape({
			step_id: yup.number().nullable(),
			user_id: yup.number().nullable(),
			date: yup.string().nullable(),
			product_tech_map_id: yup.number().nullable(),
			wasted: yup.mixed().nullable().test('validity', '', validateCount),
			fio: yup.string().nullable(),
			name: yup.string().nullable(),
			count_in_gram: yup.mixed().nullable().test('validity', '', validateCount),
			count_in_number: yup.mixed().nullable().test('validity', '', validateCount),
			count_out_gram: yup.mixed().nullable().test('validity', '', validateCount),
			count_out_number: yup
				.mixed()
				.nullable()
				.test('', 'count in number less then count out number', (val, ctx) => {
					if (!val) return true;
					if (
						(ctx.parent.count_in_number === '-' || ctx.parent.count_in_number === null) &&
						(val === '-' || !isNaN(Number(val)))
					)
						return true;
					if (val === '-') return true;
					if (!isNaN(Number(val)) && val !== 0 && Number(val) <= Number(ctx.parent.count_in_number))
						return true;
				}),
			id: yup.number().nullable(),
		}),
	),
	default_product_operations: yup.array().of(
		yup.object().shape({
			step_id: yup.number().nullable(),
			user_id: yup.number().nullable(),
			date: yup.string().nullable(),
			product_tech_map_id: yup.number().nullable(),
			wasted: yup.mixed().nullable().test('validity', '', validateCount),
			fio: yup.string().nullable(),
			name: yup.string().nullable(),
			count_in_gram: yup.mixed().nullable().test('validity', '', validateCount),
			count_in_number: yup.mixed().nullable().test('validity', '', validateCount),
			count_out_gram: yup.mixed().nullable().test('validity', '', validateCount),
			count_out_number: yup
				.mixed()
				.nullable()
				.test('', 'count in number less then count out number', (val, ctx) => {
					if (!val) return true;
					if (val === '-') return true;
					if (!isNaN(Number(val)) && val !== 0 && Number(val) <= Number(ctx.parent.count_in_number))
						return true;
				}),
			id: yup.number().nullable(),
		}),
	),
	custom_operations: yup.array().of(
		yup.object().shape({
			step_id: yup.number().nullable(),
			user_id: yup.number().nullable(),
			date: yup.string().nullable(),
			product_tech_map_id: yup.number().nullable(),
			wasted: yup.mixed().nullable().test('validity', '', validateCount),
			fio: yup.string().nullable(),
			name: yup.string().nullable(),
			count_in_gram: yup.mixed().nullable().test('validity', '', validateCount),
			count_in_number: yup.mixed().nullable().test('validity', '', validateCount),
			count_out_gram: yup.mixed().nullable().test('validity', '', validateCount),
			count_out_number: yup
				.mixed()
				.nullable()
				.test('', 'count in number less then count out number', (val, ctx) => {
					if (!val) return true;
					if (
						(ctx.parent.count_in_number === '-' || ctx.parent.count_in_number === null) &&
						(val === '-' || !isNaN(Number(val)))
					)
						return true;
					if (val === '-') return true;
					if (!isNaN(Number(val)) && val !== 0 && Number(val) <= Number(ctx.parent.count_in_number))
						return true;
				}),
			id: yup.number().nullable(),
		}),
	),
	product_sgd_out: yup.array().of(
		yup.object({
			date: yup.string().nullable(),
			fio: yup.string().nullable().default(''),
			gram: yup.number().nullable().default(1),
			count: yup.number().nullable().default(1),
			certificate: yup.string().nullable().default(''),
		}),
	),
});

export interface FormT extends Omit<ProductTechMapResponse, 'user_id' | 'id' | 'product' | 'num'> {
	product: Partial<ProductCatalogResponse> & Pick<CustomProductResponse, 'product_catalog_id'>;
	custom_operations?: ProductOperationDomain[];
	num: Omit<CustomNumDomain, 'standard_consumption_type'> & {
		standard_consumption_type?: StandardConsumptionTypeEnum;
	};
}

export const useFormConfig = (isCreationMode: boolean, userRole?: UserRoleEnum, cardInfo?: ProductTechMapResponse) => {
	const preparedEditValidationScheme = useMemo(
		() =>
			userRole === UserRoles.admin ? editValidationScheme.concat(creationValidationScheme) : editValidationScheme,
		[userRole],
	);

	const emptyObjectArray = (length: number) => {
		return new Array(length).fill({});
	};

	const sgdOutField = (length: number) => {
		switch (length) {
			case 0:
				return emptyObjectArray(3);
			default:
				// return [...(cardInfo?.product_sgd_out || []), ...emptyObjectArray(20 - (cardInfo?.product_sgd_out.length ?? 0))];
				return [...(cardInfo?.product_sgd_out || []), cardInfo?.product_sgd_out?.length ?? 0];
		}
	};

	const initialValues = useMemo<FormT>(
		() => ({
			number: cardInfo?.number,
			symbol: cardInfo?.symbol,
			status: cardInfo?.status,
			created_at: cardInfo?.created_at,
			updated_at: cardInfo?.updated_at,
			product: {
				name: cardInfo?.product?.name,
				extra_name: cardInfo?.product?.extra_name,
				symbol: cardInfo?.product?.symbol,
				extra_symbol: cardInfo?.product?.extra_symbol,
				approval_card: cardInfo?.product?.approval_card,
				extra_approval_card: cardInfo?.product?.extra_approval_card,
				id: cardInfo?.product?.id,
				material_id: cardInfo?.product?.material_id,
				material: {
					symbol: cardInfo?.product?.material?.symbol,
					gost: cardInfo?.product?.material?.gost,
					sortament: cardInfo?.product?.material?.sortament,
					gost_na_sortament: cardInfo?.product?.material?.gost_na_sortament,
				},
				standard_consumption_meters: cardInfo?.product?.product_catalog?.standard_consumption_meters,
				standard_consumption_square_meters:
					cardInfo?.product?.product_catalog?.standard_consumption_square_meters,
				standard_consumption_grams: cardInfo?.product?.product_catalog?.standard_consumption_grams,
				standard_consumption_kg: cardInfo?.product?.product_catalog?.standard_consumption_kg,
			},
			product_operations: cardInfo?.product_operations,
			default_product_operations: cardInfo?.default_product_operations || [],
			num: {
				num: cardInfo?.num?.num,
				symbol: cardInfo?.num?.symbol,
				gost: cardInfo?.num?.gost,
				sortament: cardInfo?.num?.sortament,
				gost_na_sortament: cardInfo?.num?.gost_na_sortament,
				number_of_melt: cardInfo?.num?.number_of_melt,
				date_of_manufacture: cardInfo?.num?.date_of_manufacture,
				o_v: cardInfo?.num?.o_v,
				replace_decision: cardInfo?.num?.replace_decision,
				o_v_or_date_of_manufacture: cardInfo?.num?.o_v_or_date_of_manufacture,
				standard_consumption_type: cardInfo?.num?.standard_consumption_type,
			},

			product_plan: {
				id: cardInfo?.product_plan.id,
				count: cardInfo?.product_plan.count ?? 0,
				NR_kg: cardInfo?.product_plan.NR_kg ?? '',
				NR_meters: cardInfo?.product_plan.NR_meters ?? '',
				NR_grams: cardInfo?.product_plan.NR_grams ?? '',
				NR_square_meters: cardInfo?.product_plan.NR_square_meters ?? '',
				issuance_count: cardInfo?.product_plan.issuance_count,
				fio: cardInfo?.product_plan.fio,
				return_count: cardInfo?.product_plan?.return_count,
				return_count_kg: cardInfo?.product_plan?.return_count_kg,
				return_count_grams: cardInfo?.product_plan.return_count_grams,
				return_count_square_meters: cardInfo?.product_plan.return_count_square_meters,
				return_fio: cardInfo?.product_plan?.return_fio,
				return_number: cardInfo?.product_plan?.return_number,
				product_releases: cardInfo?.product_plan?.product_releases?.length
					? cardInfo?.product_plan?.product_releases
					: new Array(3).fill({ plan_id: cardInfo?.product_plan.id }),
				list_number: cardInfo?.product_plan.list_number ?? 1,
				tech_decision: cardInfo?.product_plan.tech_decision,
				summary:
					cardInfo?.product_plan.summary ?? cardInfo
						? calculateSummary({
								product_releases: cardInfo.product_plan.product_releases,
								return_count: cardInfo.product_plan.return_count,
						  })
						: '',
			},

			product_sgd_out: sgdOutField(cardInfo?.product_sgd_out?.length || 0),
		}),
		[cardInfo],
	);

	const formConfig = useForm<FormT>({
		// Коммент оставлен на случай тестирвоания валидации
		// resolver: (...args) => {
		// 	const res = yupResolver(isCreationMode ? creationValidationScheme : preparedEditValidationScheme)(
		// 		...args,
		// 	).then((res) => {
		// 		console.debug(res);
		// 		return res;
		// 	});

		// 	return res;
		// },
		resolver: yupResolver(isCreationMode ? creationValidationScheme : preparedEditValidationScheme),
		values: initialValues,
		mode: 'all',
	});

	return formConfig;
};
