import { NoCommentsAlert } from '@/entities';
import { Comment, SideModal } from '@/shared';
import { useCreateCommentForTechMapMutation, useGetProductTechMapCommentsQuery } from '@/shared/state/api/swagger';
import { Button, Flex, Spinner, Textarea } from '@chakra-ui-kraud/react';
import { FC, useState } from 'react';
import { TechCardCommentsModalProps } from './tech-card-comments-modal.types';

export const TechCardCommentsModal: FC<TechCardCommentsModalProps> = ({ isOpen, onClose, productTechMapId }) => {
	const [createCommentText, setCreateCommentText] = useState('');
	const { data: comments, isFetching: commentsFetching } = useGetProductTechMapCommentsQuery({ productTechMapId });
	const [createComment, { isLoading: createCommentLoading }] = useCreateCommentForTechMapMutation();

	return (
		<SideModal
			title="Комментарии"
			isOpen={isOpen}
			onClose={onClose}
			footer={
				<Flex w="100%" direction="column" gap={3}>
					<Textarea
						autoFocus
						placeholder="Комментарий..."
						value={createCommentText}
						onChange={(e) => setCreateCommentText(e.target.value)}
						resize="none"
						fontSize="xs"
					/>
					<Button
						size="md"
						variant="solid"
						colorScheme="primary"
						isDisabled={createCommentText === ''}
						isLoading={createCommentLoading}
						onClick={() =>
							createComment({
								productTechMapId,
								productTechMapCommentCreateRequest: { text: createCommentText },
							}).then(() => setCreateCommentText(''))
						}
					>
						Добавить комментарий
					</Button>
				</Flex>
			}
		>
			{commentsFetching ? (
				<Spinner margin="50%" />
			) : comments?.length ? (
				comments?.map(({ text, created_at, id, user, able_edit, product_tech_map_id }) => (
					<Comment
						productTechMapId={product_tech_map_id}
						key={id}
						productCommentId={id}
						author={user}
						text={text}
						created_at={created_at}
						ableEdit={able_edit}
					/>
				))
			) : (
				<NoCommentsAlert noBackground={true} />
			)}
		</SideModal>
	);
};
