import { ProductTechMapResponse } from '@/shared/state/api/swagger';

export const formatCurrentOperation = (
	stepId: string | number | undefined | null,
	stepName: string | number | undefined | null,
): string => {
	if (!stepId && !stepName) return '';
	return `${stepId ? String(stepId).padStart(2, '0') : '\u2014'} — ${stepName ? stepName : '\u2014'}`;
};

export const anyFieldHaveValue = (fields: Record<string, boolean>): boolean => {
	return Object.values(fields).every((value) => !value);
};

export const selectFontColor = (
	isDisabled: boolean,
	isFocus: boolean,
	isInvalid: boolean,
	changeLabelColor: boolean,
) => {
	if (isDisabled) {
		return 'gray.300';
	}

	if (isFocus && !isInvalid) {
		return 'gray.900';
	}

	if (isInvalid && changeLabelColor) {
		return 'danger.400';
	}

	return 'gray.400';
};

export const selectArrowStyle = (propName: string | undefined): string => {
	if (!propName) return 'tooltip-arrow__left';
	const correlations: { [key: string]: string } = {
		'material.gost_na_sortament': 'tooltip-arrow__right',
		standard_consumption_meters: 'tooltip-arrow__middle',
	};
	return correlations[propName] || 'tooltip-arrow__left';
};

export const selectTooltipStyle = (propName: string | undefined): string => {
	if (!propName) return '';
	const correlations: { [key: string]: string } = {
		standard_consumption_meters: 'wide-tooltip__centered',
		symbol: 'wide-tooltip',
		name: 'wide-tooltip',
		approval_card: 'wide-tooltip',
		'material.symbol': 'wide-tooltip',
		'material.sortament': 'wide-tooltip',
		'material.gost': 'wide-tooltip',
		'material.gost_na_sortament': 'wide-tooltip',
	};
	return correlations[propName] || '';
};

export const getTechCardName = (card: Pick<ProductTechMapResponse, 'number' | 'symbol'> | undefined): string => {
	if (!card) return '';
	return card.symbol ? card.symbol + '-' + String(card.number) : String(card.number);
};
