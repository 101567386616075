import { ColumnsType } from 'antd/es/table';
import { UserResponseAdmin, UserRoleEnum } from '@/shared/state/api/swagger';
import { CellTableParagraph, DEFAULT_YEAR_FORMAT } from '@/shared';
import styles from './employees-table.module.scss';
import { SortOrder } from 'antd/es/table/interface';
import dayjs from 'dayjs';
import { UserActionColumns, UserRoleColumn } from '@/widgets/users-table';

const sortDirections = ['ascend', 'descend'] as keyof SortOrder;

export const USER_COLUMNS: ColumnsType<UserResponseAdmin> = [
	{
		key: 'last_name',
		title: 'Фамилия',
		dataIndex: 'last_name',
		className: styles['last-name'],
		sorter: true,
		align: 'left',
		sortDirections,
		render: (value: string) => <CellTableParagraph value={value} />,
	},
	{
		key: 'first_name',
		title: 'Имя',
		dataIndex: 'first_name',
		className: styles['first-name'],
		sorter: true,
		sortDirections,
		render: (value: string) => <CellTableParagraph value={value} />,
	},
	{
		key: 'middle_name',
		title: 'Отчество',
		dataIndex: 'middle_name',
		className: styles['middle-name'],
		sorter: true,
		sortDirections,
		render: (value: string) => <CellTableParagraph value={value} />,
	},
	{
		key: 'email',
		title: 'Почта',
		dataIndex: 'email',
		sorter: true,
		sortDirections,
		className: styles['mail'],
		render: (value: string) => <CellTableParagraph value={value} minWidth={99} maxWidth={window.innerWidth / 4} />,
	},
	{
		key: 'role',
		title: 'Роль',
		dataIndex: 'role',
		className: styles['role'],
		sorter: true,
		sortDirections,
		render: (value: UserRoleEnum) => <UserRoleColumn role={value} />,
	},
	{
		key: 'position',
		title: 'Должность',
		dataIndex: 'position',
		className: styles['position'],
		sortDirections,
		render: (value: string) => <CellTableParagraph value={value} />,
	},
	{
		key: 'date_of_work_start',
		dataIndex: 'date_of_work_start',
		title: 'Дата приема',
		sorter: true,
		className: styles['date'],
		sortDirections,
		render: (value) => (
			<CellTableParagraph
				value={dayjs(value).isValid() ? dayjs(value).format(DEFAULT_YEAR_FORMAT) : ''}
				textAlign={'right'}
			/>
		),
	},
	{
		key: 'date_of_dismissal',
		dataIndex: 'date_of_dismissal',
		title: 'Дата увольнения',
		className: styles['date'],
		sorter: true,
		sortDirections,
		render: (value) => (
			<CellTableParagraph
				value={dayjs(value).isValid() ? dayjs(value).format(DEFAULT_YEAR_FORMAT) : ''}
				textAlign={'right'}
			/>
		),
	},
	{
		key: 'actions',
		title: 'Действия',
		dataIndex: 'actions',
		align: 'left',
		fixed: 'right',
		width: '120px',
		render: (_, row: UserResponseAdmin) => <UserActionColumns user={row} />,
	},
];

export const OTHER_USERS_COLUMNS = USER_COLUMNS.map((item) => item.key as string);

export const EXECUTOR_COLUMNS = OTHER_USERS_COLUMNS.filter((item) => !['role', 'email'].includes(item as string));
