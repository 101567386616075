import { TechCardStatuses } from '@/shared';
import { MAP_STATUS_TO_NAME } from '@/widgets/operation/model/statuses.types';
import { Checkbox, CheckboxGroup } from '@chakra-ui-kraud/react';
import { isEmpty } from 'lodash';
import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FilterInputGroup } from './filter-input-group';

export const CheckboxGroupStatus: FC<{ statuses: TechCardStatuses[] }> = ({ statuses }) => {
	const { control } = useFormContext();

	return (
		<Controller
			control={control}
			name="statuses"
			render={({ field }) => (
				<FilterInputGroup name="statuses" label="Статус" showClearButton={!isEmpty(field.value)}>
					<CheckboxGroup {...field}>
						{statuses.map((status) => (
							<Checkbox key={status} value={status}>
								{MAP_STATUS_TO_NAME[status]}
							</Checkbox>
						))}
					</CheckboxGroup>
				</FilterInputGroup>
			)}
		/>
	);
};
