import { StandardConsumptionTypeEnum } from '@/shared';
import { ProductPlanDomain } from '@/shared/state/api/swagger';

export const returnFieldNamesMatcher: Record<
	`return_count_${Exclude<StandardConsumptionTypeEnum, 'old'>}`,
	`${keyof Pick<
		ProductPlanDomain,
		'return_count' | 'return_count_grams' | 'return_count_square_meters' | 'return_count_kg'
	>}`
> = {
	return_count_grams: 'return_count_grams',
	return_count_kg: 'return_count_kg',
	return_count_meters: 'return_count',
	return_count_square_meters: 'return_count_square_meters',
};
