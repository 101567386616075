import { FormT } from '@/pages/tech-card-page/formConfig';
import { NotFoundContentDropdown, useTechCard } from '@/shared';
import { Select } from 'antd';
import { useGetAssemblyTechMapsQuery } from '@/shared/state/api/swagger';
import { IconButton, Input, InputAddon, InputGroup, Text } from '@chakra-ui-kraud/react';
import { FC, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import _debounce from 'lodash/debounce';
import { MadIcon } from 'madsoft-icons';
import styles from './SGD.module.scss';
import { SDGReceiverProps } from './SGD.types';

export const SDGReceiver: FC<SDGReceiverProps> = ({ index }) => {
	const { cardInfo } = useTechCard();
	const { control, setValue } = useFormContext<FormT>();
	const initialValue = cardInfo?.product_sgd_out?.[index]?.assembly_tech_map_receiver?.number;

	const [numberQuery, setNumberQuery] = useState(null);
	const { options, isFetching } = useGetAssemblyTechMapsQuery(
		{
			number: numberQuery ?? undefined,
			statuses: ['accepted_at_sgd', 'progress', 'partially_issued'],
		},
		{
			selectFromResult: (result) => {
				return {
					...result,
					options: result?.data?.payload?.map((item) => ({
						value: item.id,
						label: item.number,
					})),
				};
			},
		},
	);
	const debouncedMapSearch = _debounce((search) => setNumberQuery(search), 700);

	const [mode, setMode] = useState<'text' | 'select'>('text');

	return (
		<>
			{initialValue && (
				<Text p="8px 12px 6px" fontWeight="normal">
					{initialValue}
				</Text>
			)}
			{!initialValue && (
				<>
					{mode === 'text' ? (
						<InputGroup className={styles['receiver-select-group']}>
							<Controller
								name={`product_sgd_out.${index}.receiver`}
								control={control}
								render={({ field: { onChange, value }, fieldState: { error } }) => (
									<>
										<Input
											tabIndex={10}
											isInvalid={!!error}
											onChange={onChange}
											value={value}
											name={`assembly_sgd_out.${index}.receiver`}
											backgroundColor="transparent"
										/>
									</>
								)}
							/>
							<InputAddon bg="none" border="none" p="0">
								<IconButton
									size="sm"
									aria-label="swap"
									variant="ghost"
									colorScheme="tertiary"
									icon={<MadIcon module="basic" name="arrows-right-left" type="outline" />}
									onClick={() => {
										setValue(`product_sgd_out.${index}.receiver`, undefined);
										setMode('select');
									}}
								></IconButton>
							</InputAddon>
						</InputGroup>
					) : (
						<Controller
							name={`product_sgd_out.${index}.assembly_tech_map_receiver.id`}
							control={control}
							render={({ field: { onChange, value }, fieldState: { error } }) => (
								<InputGroup className={styles['receiver-select-group']}>
									<Select
										status={error ? 'error' : ''}
										size="small"
										tabIndex={1}
										showSearch
										placeholder="Поиск..."
										optionFilterProp="children"
										onChange={(e) => {
											onChange(e);
										}}
										filterOption={() => true}
										onSearch={(search) => {
											const number = Number(search);
											if (!Number.isNaN(number)) {
												debouncedMapSearch(search);
											}
										}}
										style={{
											width: '100%',
										}}
										value={value}
										onClear={() => {
											setValue(`product_sgd_out.${index}.assembly_tech_map_receiver`, undefined);
											setMode('text');
										}}
										options={options}
										notFoundContent={
											<NotFoundContentDropdown
												isFetchingNaming={isFetching}
												alertName={'Введите обозначение'}
											/>
										}
									/>
									<IconButton
										size="sm"
										p={'9px'}
										aria-label="swap"
										variant="ghost"
										colorScheme="tertiary"
										icon={<MadIcon module="basic" name="x-circle" type="outline" />}
										onClick={() => {
											setValue(`product_sgd_out.${index}.assembly_tech_map_receiver`, undefined);
											setMode('text');
										}}
									></IconButton>
								</InputGroup>
							)}
						/>
					)}
				</>
			)}
		</>
	);
};
