import { PreformTechMapCreateRequest } from '@/shared/state/api/swagger';

export const prepareCreatePreformMap = (payload: PreformTechMapCreateRequest): PreformTechMapCreateRequest => {
	return {
		...payload,
		preform_num: {
			...payload.preform_num,
			date_of_manufacture: !payload.preform_num.date_of_manufacture
				? undefined
				: payload.preform_num.date_of_manufacture,
		},
		created_at: payload.created_at ? new Date(payload.created_at).toISOString() : new Date().toISOString(),
	};
};
