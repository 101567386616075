import { selectNumOptions } from '@/entities/num';
import { FormHandle } from '@/features/solve-issue-form';
import {
	CustomInput,
	CustomSelect,
	DEFAULT_YEAR_FORMAT,
	OVorDateSelectOption,
	showErrorToast,
	StandardConsumptionTypeEnum,
	STANDARD_CONSUMPTION_TYPES,
} from '@/shared';
import {
	OvOrDateOfManufactureDecision,
	useGetEmployeeInitialsQuery,
	useGetNumsQuery,
} from '@/shared/state/api/swagger';
import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import { forwardRef, useCallback, useImperativeHandle, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FormT as FormType, TechCardFormProps } from '../model/tech-card-form.types';
import _debounce from 'lodash/debounce';
import { convertNumberToNumberStringWithComma } from '@/shared/core/utils/convert-string-to-number-string';
import { Stack, useToast } from '@chakra-ui-kraud/react';
import { selectEmployesForOptions } from '@/entities/employee';
import { FormT } from '../model/schema';

const DEFAULT_NUM = {
	id: undefined,
	num: undefined,
	number_of_melt: '',
	o_v: '',
	date_of_manufacture: '',
	symbol: undefined,
	sortament: '',
	gost: '',
	gost_na_sortament: '',
	o_v_or_date_of_manufacture: 'empty' as OvOrDateOfManufactureDecision,
	standard_consumption_type: 'kg' as StandardConsumptionTypeEnum,
};

export const TechCardForm = forwardRef<FormHandle, TechCardFormProps>(({ onSubmit, product }, ref) => {
	const toast = useToast();

	const formProps = useForm<FormType>({
		resolver: yupResolver(FormT),
		defaultValues: {
			product,
			product_operations:
				[...(product?.operation_types ?? [])]?.sort((a, b) => (a.step_id ?? 0) - (b.step_id ?? 0)) || [],
		},
	});

	const [numsQuery, setNumsQuery] = useState('');

	const debouncedNumsSearch = _debounce((search) => setNumsQuery(search), 700);

	const { handleSubmit, setValue, control, getValues } = formProps;

	useImperativeHandle(ref, () => {
		return {
			onSubmit() {
				handleSubmit(onSubmit)();
			},
			getValues,
		};
	});

	const {
		data: nums,
		options: numsOptions,
		isFetching: isNumsFetching,
	} = useGetNumsQuery(
		{ stringToSearch: numsQuery },
		{
			selectFromResult: (result) => ({
				...result,
				options: selectNumOptions(result?.data?.payload),
			}),
		},
	);
	const { options: employeeOptions, isFetching: isEmployeesFetching } = useGetEmployeeInitialsQuery(undefined, {
		selectFromResult: (result) => ({
			...result,
			options: selectEmployesForOptions(result?.data),
		}),
	});

	const [OVorDateSelectOptions, setOVorDateSelectOptions] = useState<OVorDateSelectOption[]>(
		[
			getValues('num.o_v') && {
				value: 'o_v',
				label: getValues('num.o_v'),
			},
			getValues('num.date_of_manufacture') && {
				value: 'date_of_manufacture',
				label: dayjs(getValues('num.date_of_manufacture')).format(DEFAULT_YEAR_FORMAT),
			},
			{ value: 'empty', label: '-' },
		].filter(Boolean) as OVorDateSelectOption[], // Удаляет undefined элементы
	);

	const handleNumSelect = useCallback(
		(value: number) => {
			const selectedNum = nums?.payload?.find((num) => num.id === value);

			if (selectedNum) {
				if (
					selectedNum.standard_consumption_type !== 'old' &&
					!product?.[
						`standard_consumption_${selectedNum.standard_consumption_type}` as
							| `standard_consumption_${Exclude<StandardConsumptionTypeEnum, 'old'>}`
					]
				) {
					showErrorToast(toast, {
						title: 'Не подходящий материал',
						description: `Вы не можете выбрать этот материал потому что в детали не указано норма расхода на ${
							STANDARD_CONSUMPTION_TYPES[selectedNum.standard_consumption_type!]
						}`,
					});
					return;
				}

				if (selectedNum.standard_consumption_type === 'old') {
					setValue(
						'product_plan.NR_kg',
						convertNumberToNumberStringWithComma(product?.standard_consumption_kg),
						{ shouldValidate: true },
					);
					setValue(
						'product_plan.NR_meters',
						convertNumberToNumberStringWithComma(product?.standard_consumption_meters),
						{ shouldValidate: true },
					);
				} else if (selectedNum.standard_consumption_type) {
					setValue(
						`product_plan.NR_${selectedNum.standard_consumption_type}`,
						convertNumberToNumberStringWithComma(
							product?.[
								`standard_consumption_${selectedNum.standard_consumption_type}` as
									| `standard_consumption_${Exclude<StandardConsumptionTypeEnum, 'old'>}`
							],
						),
						{ shouldValidate: true },
					);
				}

				const nextOVorDateSelectOptions = [
					selectedNum?.o_v && { value: 'o_v', label: selectedNum?.o_v },
					selectedNum?.date_of_manufacture && {
						value: 'date_of_manufacture',
						label: dayjs(selectedNum?.date_of_manufacture || getValues('num.date_of_manufacture')).format(
							DEFAULT_YEAR_FORMAT,
						),
					},
					{ value: 'empty', label: '-' },
				].filter(Boolean) as OVorDateSelectOption[];

				setOVorDateSelectOptions(nextOVorDateSelectOptions);
				setValue(
					'num',
					{
						id: selectedNum?.id || 0,
						num: selectedNum?.num,
						number_of_melt: selectedNum?.number_of_melt || '-',
						o_v: selectedNum?.o_v || '',
						date_of_manufacture: selectedNum?.date_of_manufacture || '',
						symbol: selectedNum?.symbol || '-',
						sortament: selectedNum?.sortament || '-',
						gost: selectedNum?.gost || '-',
						gost_na_sortament: selectedNum?.gost_na_sortament || '-',
						o_v_or_date_of_manufacture: nextOVorDateSelectOptions[0].value,
						standard_consumption_type: selectedNum?.standard_consumption_type,
					},
					{ shouldValidate: true },
				);
			}
		},
		[nums?.payload, product, getValues, setValue, toast],
	);

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<Stack gap={3}>
				<Controller
					name="product.symbol"
					control={control}
					render={({ field, fieldState: { error } }) => (
						<CustomSelect
							{...field}
							label={'Выберите обозначение для новой МК'}
							disabled={true}
							allowClear={false}
							initialValue={undefined}
							options={[]}
							isInvalid={!!error}
							defaultValue={product?.symbol}
							value={product?.symbol}
						/>
					)}
				/>
				<Controller
					name="num.id"
					control={control}
					render={({ field, fieldState: { error } }) => (
						<CustomSelect
							label={'Выберите НУМ для новой МК'}
							allowClear
							onClear={() => {
								setValue('num', DEFAULT_NUM);
							}}
							initialValue={undefined}
							options={numsOptions}
							isInvalid={!!error}
							isLoading={isNumsFetching}
							onSearch={debouncedNumsSearch}
							onSelect={handleNumSelect}
							{...field}
						/>
					)}
				/>
				<Controller
					name="num.o_v_or_date_of_manufacture"
					render={({ field: { onChange, value, ...field }, fieldState: { error } }) => {
						return (
							<CustomSelect
								{...field}
								value={value}
								onChange={onChange}
								initialValue={undefined}
								options={OVorDateSelectOptions}
								label={'σв / дата изготовления'}
								style={{ width: '100%' }}
								allowClear={false}
								isInvalid={!!error}
								onSelect={(value) => {
									onChange(value);
									setValue('num.o_v_or_date_of_manufacture', value);
								}}
							/>
						);
					}}
				/>
				<Controller
					name="num.replace_decision"
					control={control}
					render={({ field, fieldState: { error } }) => (
						<CustomInput
							{...field}
							label="Решение о замене материала"
							isInvalid={!!error}
							onChange={(e) => {
								setValue('num.replace_decision', e.target.value);
								field.onChange(e.target.value);
							}}
						/>
					)}
				/>
				<Controller
					name="product_plan.count"
					control={control}
					render={({ field, fieldState: { error } }) => {
						return (
							<CustomInput
								{...field}
								label="Введите план по количеству деталей"
								isInvalid={!!error}
								onChange={(e) => {
									setValue('product_plan.count', Number(e.target.value.replace(/\D/g, '')));
									field.onChange(e.target.value.replace(/\D/g, ''));
								}}
							/>
						);
					}}
				/>
				<Controller
					name="product_plan.fio"
					control={control}
					render={({ field, fieldState: { error } }) => (
						<CustomSelect
							{...field}
							label={'Выберите ФИО ПДС'}
							allowClear
							onClear={() => {
								setValue('product_plan.fio', '');
							}}
							onChange={(e) => {
								field.onChange(e);
								setValue('product_plan.fio', employeeOptions.find((el) => el.value === e)?.label, {
									shouldValidate: true,
								});
							}}
							value={employeeOptions.find((el) => el.label === field.value)?.value}
							initialValue={undefined}
							options={employeeOptions}
							isInvalid={!!error}
							isLoading={isEmployeesFetching}
						/>
					)}
				/>
			</Stack>
		</form>
	);
});
