import { Nullable } from '@/shared/core/interfaces/utility.types';
import {
	ProductTechMapLinkedRequestWithExistedCreate,
	ProductTechMapLinkedRequestWithNewCreate,
} from '@/shared/state/api/swagger';
import * as yup from 'yup';

export const createLinkWithNewTechCardSchema = yup.object({
	// id родительской карты (текущей карты)
	parent_product_tech_map_id: yup.number().required(),
	// обозначение новой мк
	children_product_catalog_id: yup.number().required('Заполните это поле, оно обязательное'),
	// план по количеству деталей
	plan_count: yup.number().default(null).required('Заполните это поле, оно обязательное'),
	// ФИО ПДС
	fio: yup.string().required('Заполните это поле, оно обязательное'),
	// куда (может быть дефолтная или обычная операция)
	children_operation_type_id: yup.number().nullable(),
	children_default_operation_id: yup.number().nullable(),
	// step-id выбранной операции (нужен бэку)
	children_operation_step_id: yup.number().nullable(),
	// откуда (будет id текущей операции current_product_operation)
	parent_operation_id: yup.number().required('Заполните это поле, оно обязательное'),
	// ответственный (ФИО)
	responsible_employee_fio: yup.string().required('Заполните это поле, оно обязательное'),
	// дата
	from_parent_created_at: yup.string().required('Заполните это поле, оно обязательное'),
	// грамм
	count_gram: yup.number().default(undefined),
	// штук
	count_number: yup.number().default(undefined).required('Заполните это поле, оно обязательное'),
});

// дефолтные значения при создании связи с новой МК
export const defaultValueLinkNewTechCard: Nullable<
	ProductTechMapLinkedRequestWithNewCreate & { parent_operation_id: number }
> = {
	parent_product_tech_map_id: null,
	children_product_catalog_id: null,
	children_operation_type_id: null,
	children_default_operation_id: null,
	children_operation_step_id: null,
	plan_count: null,
	fio: '',
	responsible_employee_fio: '',
	from_parent_created_at: '',
	parent_operation_id: null,
	// hotfix
	count_gram: 0,
	count_number: null,
};

export const createLinkWithCurrentTechCardSchema = yup.object({
	// id родительской карты (текущей карты)
	parent_product_tech_map_id: yup.number().required('Заполните это поле, оно обязательное'),
	// выбор номера существующей МК
	children_product_tech_map_id: yup.number().required('Заполните это поле, оно обязательное'),
	// ответственный (ФИО)
	responsible_employee_fio: yup.string().required('Заполните это поле, оно обязательное'),
	// дата
	from_parent_created_at: yup.string().required('Заполните это поле, оно обязательное'),
	// грамм
	count_gram: yup.number().default(undefined).notRequired(),
	// штук
	count_number: yup.number().default(undefined).required('Заполните это поле, оно обязательное'),
});

// дефолтные значения при создании связи с существующей МК
export const defaultValueLinkCurrentTechCard: Nullable<ProductTechMapLinkedRequestWithExistedCreate> = {
	parent_product_tech_map_id: null,
	children_product_tech_map_id: null,
	// новое поле fio, на бэке его нет
	responsible_employee_fio: '',
	from_parent_created_at: '',
	count_gram: undefined,
	count_number: null,
};
