import { PreformCatalogTable } from '@/entities/tables/preform-catalog-table/preform-catalog-table';
import { MainContentLoader, Search, useAppSelector, UserRoles } from '@/shared';
import { Layout } from '@/widgets';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { Button, TextHeader } from '@chakra-ui-kraud/react';
import { useCallback, useState } from 'react';
import _debounce from 'lodash/debounce';
import styles from './preform.module.scss';
import { PreformModal } from '@/widgets/preform-modal';
import { useGetPreformsQuery, GetPreformsApiArg, PreformCatalogResponse } from '@/shared/state/api/swagger';

export const PreformPage = () => {
	const [isSidebarOpen, setSidebarOpen] = useState(false);

	const userRole = useAppSelector((state) => state.auth.userProfile?.role);

	const [searchValue, setSearchValue] = useState('');

	const [queryParams, setQueryParams] = useState<GetPreformsApiArg>({
		stringToSearch: undefined,
		pageSize: 32,
		currentPage: 1,
		orderBy: undefined,
		orderDir: undefined,
	});

	const debouncedSearch = useCallback(
		_debounce((e: React.ChangeEvent<HTMLInputElement>) => {
			setQueryParams((prev) => ({
				...prev,
				stringToSearch: e.target.value.trim(),
				currentPage: 1,
			}));
		}, 200),
		[],
	);

	const { data, isLoading } = useGetPreformsQuery(queryParams);

	const [variant, setVariant] = useState<'create' | 'edit'>('create');
	const [selectedPreform, setSelectedPreform] = useState<PreformCatalogResponse | null>(null);

	return (
		<>
			<Layout
				title={
					<TextHeader className={styles['preform__title']} variant="h1">
						Справочник процессов заготовки
					</TextHeader>
				}
				search={
					<Search
						handleChange={(e) => {
							debouncedSearch(e);
							setSearchValue(e.target.value);
						}}
						value={searchValue}
					/>
				}
				headerLeftButton={
					userRole !== UserRoles.user && (
						<Button
							size="md"
							variant="ghost"
							colorScheme="primary"
							lineHeight="24px"
							rightIcon={<ChevronRightIcon />}
							onClick={() => {
								setSelectedPreform(null);
								setVariant('create');
								setSidebarOpen(true);
							}}
						>
							Добавить процесс заготовки
						</Button>
					)
				}
			>
				{isLoading ? (
					<MainContentLoader />
				) : (
					<PreformCatalogTable
						data={data?.payload ? data?.payload : ([] as PreformCatalogResponse[])}
						page={data?.page}
						pageSize={queryParams.pageSize ?? 32}
						total={data?.total}
						setQueryParams={setQueryParams}
						handleClick={(preform, variant) => {
							setSidebarOpen(true);
							setVariant(variant);
							setSelectedPreform(preform);
						}}
					/>
				)}
			</Layout>
			<PreformModal
				isOpen={isSidebarOpen}
				onClose={() => {
					setSidebarOpen(false);
					setSelectedPreform(null);
				}}
				variant={variant}
				selectedPreform={selectedPreform as PreformCatalogResponse}
			/>
		</>
	);
};
