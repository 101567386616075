import type { FC } from 'react';
import type { CalendarRangePickerProps } from './calendar-range-picker.types';

import { useEffect, useState, useRef } from 'react';
import {
	Flex,
	chakra,
	CalendarDatepicker,
	CalendarDatepickerTrigger,
	CalendarContainer,
	CalendarHeader,
	CalendarDaysGrid,
	Text,
	Button,
} from '@chakra-ui-kraud/react';
import { MadIcon } from 'madsoft-icons';
import { format, parse } from 'date-fns';

import { CustomInput } from '@/shared/components/custom-input';

import styles from './calendar-range-picker.module.scss';
import { DEFAULT_DATE_FNS_YEAR_FORMAT } from '@/shared/constants';

export const CalendarRangePicker: FC<CalendarRangePickerProps> = ({ textLabel, setDates, defaultValue, onClear }) => {
	const containerRef = useRef<HTMLDivElement>(null);

	// стейт для начального периода
	const [startCalendarOptions, setStartCalendarOptions] = useState<{
		open: boolean;
		value: Date[];
	}>({ open: false, value: defaultValue?.from ? [parse(defaultValue.from, 'yyyy-MM-dd', new Date())] : [] });
	// стейт для конечного периода
	const [endCalendarOptions, setEndCalendarOptions] = useState<{
		open: boolean;
		value: Date[];
	}>({ open: false, value: defaultValue?.to ? [parse(defaultValue.to, 'yyyy-MM-dd', new Date())] : [] });

	useEffect(() => {
		if (startCalendarOptions.value.length && endCalendarOptions.value.length) {
			setDates(
				format(startCalendarOptions.value[0], 'yyyy-MM-dd'),
				format(endCalendarOptions.value[0], 'yyyy-MM-dd'),
			);
		}

		if (containerRef.current && (startCalendarOptions.open || endCalendarOptions.open)) {
			const onClick = (e: MouseEvent) => {
				for (const element of e.composedPath()) {
					if (element === containerRef.current) {
						return;
					}
				}

				setStartCalendarOptions((prev) => ({ ...prev, open: false }));
				setEndCalendarOptions((prev) => ({ ...prev, open: false }));
			};

			window.addEventListener('mousedown', onClick);

			return () => {
				window.removeEventListener('mousedown', onClick);
			};
		}
	}, [startCalendarOptions, endCalendarOptions, containerRef]);

	return (
		<chakra.div ref={containerRef} display="flex" flexDirection="column" gap="5px">
			<Flex flexDirection="row" justifyContent="space-between">
				<Text size="md" lineHeight="6" fontWeight="600">
					{textLabel}
				</Text>
				{(!!startCalendarOptions.value?.length || !!endCalendarOptions.value?.length) && (
					<Button
						size="xs"
						variant="ghost"
						onClick={() => {
							if (onClear) onClear();
							setStartCalendarOptions((prev) => ({ ...prev, value: [] }));
							setEndCalendarOptions((prev) => ({ ...prev, value: [] }));
						}}
					>
						Очистить
					</Button>
				)}
			</Flex>
			<Flex w="100%" gap="8px">
				<chakra.div w={'100%'}>
					<CalendarDatepicker
						isOpen={startCalendarOptions.open}
						onOpen={() => {
							setEndCalendarOptions((prev) => ({
								...prev,
								open: false,
							}));
							setStartCalendarOptions((prev) => {
								return { ...prev, open: false };
							});
						}}
						closeOnSelect
						selectedDates={startCalendarOptions.value}
						onValueChange={(dates) => {
							setStartCalendarOptions((prev) => {
								return { open: false, value: dates };
							});
							setEndCalendarOptions((prev) => ({
								...prev,
								open: true,
							}));
						}}
						range={false}
						maxSelectedCount={1}
						onClose={() => {
							setStartCalendarOptions((prev) => {
								return { ...prev, open: true };
							});
						}}
					>
						<CalendarDatepickerTrigger>
							<CustomInput
								wrapperClassName={styles['calendar-range-picker-header-input']}
								className={styles['calendar-range-picker-header-input']}
								labelonTop
								label="От"
								isReadOnly
								value={
									startCalendarOptions.value.length
										? format(startCalendarOptions.value[0], DEFAULT_DATE_FNS_YEAR_FORMAT)
										: ''
								}
								onChange={() => null}
								onClick={() => {
									setEndCalendarOptions((prev) => ({
										...prev,
										open: false,
									}));
									setStartCalendarOptions((prev) => {
										return { ...prev, open: !prev.open };
									});
								}}
								rightIcon={
									<MadIcon
										name="calendar"
										size="default"
										mode="default"
										type="outline"
										module="basic"
									/>
								}
								// placeholder={format(new Date(), DEFAULT_DATE_FNS_YEAR_FORMAT)}
								defaultValue={format(new Date(), DEFAULT_DATE_FNS_YEAR_FORMAT)}
							/>
						</CalendarDatepickerTrigger>
						<chakra.div
							__css={{
								'& > *': {
									zIndex: 2,
								},
							}}
						>
							<CalendarContainer>
								<CalendarHeader />
								<CalendarDaysGrid />
							</CalendarContainer>
						</chakra.div>
					</CalendarDatepicker>
				</chakra.div>
				<chakra.div w={'100%'}>
					<CalendarDatepicker
						placement="bottom-end"
						isOpen={endCalendarOptions.open}
						onOpen={() => {
							setStartCalendarOptions((prev) => ({
								...prev,
								open: false,
							}));
							setEndCalendarOptions((prev) => {
								return { ...prev, open: false };
							});
						}}
						closeOnSelect
						selectedDates={endCalendarOptions.value}
						onValueChange={(dates) => {
							setEndCalendarOptions((prev) => {
								return { open: false, value: dates };
							});
						}}
						range={false}
						maxSelectedCount={1}
						onClose={() => {
							setEndCalendarOptions((prev) => {
								return { ...prev, open: true };
							});
						}}
					>
						<CalendarDatepickerTrigger>
							<CustomInput
								wrapperClassName={styles['calendar-range-picker-header-input']}
								className={styles['calendar-range-picker-header-input']}
								labelonTop
								label="До"
								isReadOnly
								value={
									endCalendarOptions.value.length
										? format(endCalendarOptions.value[0], DEFAULT_DATE_FNS_YEAR_FORMAT)
										: ''
								}
								onClick={() => {
									setStartCalendarOptions((prev) => ({
										...prev,
										open: false,
									}));
									setEndCalendarOptions((prev) => {
										return { ...prev, open: !prev.open };
									});
								}}
								rightIcon={
									<MadIcon
										name="calendar"
										size="default"
										mode="default"
										type="outline"
										module="basic"
									/>
								}
								// placeholder={format(new Date(), DEFAULT_YEAR_FORMAT)}
								defaultValue={format(new Date(), DEFAULT_DATE_FNS_YEAR_FORMAT)}
							/>
						</CalendarDatepickerTrigger>
						<chakra.div
							__css={{
								'& > *': {
									zIndex: 2,
								},
							}}
						>
							<CalendarContainer>
								<CalendarHeader />
								<CalendarDaysGrid />
							</CalendarContainer>
						</chakra.div>
					</CalendarDatepicker>
				</chakra.div>
			</Flex>
			<Text fontSize="12px" lineHeight="4" color="gray.600" marginTop="4px">
				Выберите диапазон дат
			</Text>
		</chakra.div>
	);
};
