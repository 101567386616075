import { AssemblyCatalogTable } from '@/entities/tables/assembly-catalog-table';
import { MainContentLoader, Search, useAppSelector, UserRoles } from '@/shared';
import { AssemblyCatalogResponse, GetAssembliesApiArg, useGetAssembliesQuery } from '@/shared/state/api/swagger';
import { Layout } from '@/widgets';
import { Button, TextHeader } from '@chakra-ui-kraud/react';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { useCallback, useState } from 'react';
import styles from './assembly.module.scss';
import _debounce from 'lodash/debounce';
import { AssemblyModal } from '@/widgets/assembly-modal';

export const AssemblyPage = () => {
	const [isSidebarOpen, setSidebarOpen] = useState(false);

	const userRole = useAppSelector((state) => state.auth.userProfile?.role);
	const [searchValue, setSearchValue] = useState('');

	const [queryParams, setQueryParams] = useState<GetAssembliesApiArg>({
		stringToSearch: undefined,
		pageSize: 32,
		currentPage: 1,
		orderBy: undefined,
		orderDir: undefined,
	});

	const { data, isLoading } = useGetAssembliesQuery(queryParams);
	const debouncedSearch = useCallback(
		_debounce((e: React.ChangeEvent<HTMLInputElement>) => {
			setQueryParams((prev) => ({
				...prev,
				stringToSearch: e.target.value.trim(),
				currentPage: 1,
			}));
		}, 200),
		[],
	);

	const [variant, setVariant] = useState<'create' | 'edit'>('create');
	const [selectedAssembly, setSelectedAssembly] = useState<AssemblyCatalogResponse | null>(null);

	return (
		<>
			<Layout
				title={
					<TextHeader className={styles['assembly__title']} variant="h1">
						Справочник сборочных единиц
					</TextHeader>
				}
				search={
					<Search
						handleChange={(e) => {
							debouncedSearch(e);
							setSearchValue(e.target.value);
						}}
						value={searchValue}
					/>
				}
				headerLeftButton={
					userRole !== UserRoles.user && (
						<Button
							size="md"
							variant="ghost"
							colorScheme="primary"
							lineHeight="24px"
							rightIcon={<ChevronRightIcon />}
							onClick={() => {
								setSelectedAssembly(null);
								setVariant('create');
								setSidebarOpen(true);
							}}
						>
							Добавить сборочную единицу
						</Button>
					)
				}
			>
				{isLoading ? (
					<MainContentLoader />
				) : (
					<AssemblyCatalogTable
						pageSize={queryParams.pageSize ?? 32}
						data={data?.payload || []}
						page={data?.page}
						total={data?.total}
						setQueryParams={setQueryParams}
						isLoading={isLoading}
						handleClick={(assembly, variant) => {
							setSidebarOpen(true);
							setVariant(variant);
							setSelectedAssembly(assembly);
						}}
					/>
				)}
			</Layout>
			<AssemblyModal
				isOpen={isSidebarOpen}
				onClose={() => {
					setSidebarOpen(false);
					setSelectedAssembly(null);
				}}
				variant={variant}
				selectedAssembly={selectedAssembly}
			/>
		</>
	);
};
