import { TechCardsFiltersNullableArg } from '@/entities/tables/tech-card';

export const productTechMapDefaults = (defaultValue?: Partial<TechCardsFiltersNullableArg>) => ({
	symbol: defaultValue?.symbol,
	productName: defaultValue?.productName,
	currentOperationsNames: defaultValue?.currentOperationsNames,
	productSymbol: defaultValue?.productSymbol,
	statuses: defaultValue?.statuses ?? [],
	wasted: defaultValue?.wasted,
	createdAtTo: defaultValue?.createdAtTo,
	createdAtFrom: defaultValue?.createdAtFrom,
	storageAtTo: defaultValue?.storageAtTo,
	storageAtFrom: defaultValue?.storageAtFrom,
	numStrict: defaultValue?.numStrict,
});

export const assemblyTechMapDefaults = productTechMapDefaults;

export const preformTechMapDefaults = (defaultValue?: Partial<TechCardsFiltersNullableArg>) => ({
	symbol: defaultValue?.symbol,
	preformName: defaultValue?.preformName,
	currentOperationsNames: defaultValue?.currentOperationsNames,
	statuses: defaultValue?.statuses ?? [],
	numStrict: defaultValue?.numStrict,
	numSymbolStrict: defaultValue?.numSymbolStrict,
	numGostStrict: defaultValue?.numGostStrict,
	numSortamentStrict: defaultValue?.numSortamentStrict,
	numGostSortamentStrict: defaultValue?.numGostSortamentStrict,
	wasted: defaultValue?.wasted,
	createdAtTo: defaultValue?.createdAtTo,
	createdAtFrom: defaultValue?.createdAtFrom,
	storageAtTo: defaultValue?.storageAtTo,
	storageAtFrom: defaultValue?.storageAtFrom,
});

export const allTechMapDefaults = (defaultValue?: Partial<TechCardsFiltersNullableArg>) => ({
	group: defaultValue?.group,
	name: defaultValue?.name,
	symbol: defaultValue?.symbol,
	numStrict: defaultValue?.numStrict,
	statuses: defaultValue?.statuses ?? [],
	wasted: defaultValue?.wasted,
	createdAtFrom: defaultValue?.createdAtFrom,
	createdAtTo: defaultValue?.createdAtTo,
});
