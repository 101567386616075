import { EngineerValidateSuccess, Stub } from '@/shared';
import { OperationAlertProps } from './alert.types';

export const CancelOperationAlert = ({ stepId, operationName }: OperationAlertProps) => {
	return (
		<Stub
			icon={<EngineerValidateSuccess />}
			header="Операция отменена"
			label={
				<p>
					Информация по отмене операции{' '}
					<strong>
						{stepId}
						{operationName ? ` — ${operationName}` : null}
					</strong>{' '}
					сохранена
				</p>
			}
		/>
	);
};
