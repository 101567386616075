import { useCallback, useState } from 'react';
import { TablePaginationConfig } from 'antd';
import type { FilterValue, SorterResult } from 'antd/es/table/interface';

interface useUserTableParamsProps<SortingFields extends string | string[] = string> {
	stringToSearch?: string;
	pageSize?: number;
	currentPage?: number;
	orderBy?: SortingFields;
	orderDir?: 'asc' | 'desc';
}

export const useUserTableParams = <SortingFields extends string>({
	pageSize = 32,
	currentPage = 1,
	...props
}: useUserTableParamsProps<SortingFields>) => {
	const [queryParams, setQueryParams] = useState({
		pageSize,
		currentPage,
		...props,
	});

	const handleTableChange = useCallback(
		(
			pagination: TablePaginationConfig,
			filters: Record<string, FilterValue | null>,
			sorter: SorterResult<Partial<SortingFields>> | SorterResult<Partial<SortingFields>>[],
		) => {
			const sortedBy = Array.isArray(sorter) ? sorter[0].column?.key?.toString() : sorter.column?.key?.toString();
			const sortOrder = Array.isArray(sorter) ? sorter[0].order : sorter.order;
			const preparedSortDir = sortOrder ? (sortOrder === 'ascend' ? 'asc' : 'desc') : undefined;

			setQueryParams((prev) => ({
				...prev,
				orderBy: sortedBy as unknown as SortingFields,
				orderDir: preparedSortDir,
			}));
		},
		[],
	);

	const handlePageChange = useCallback((page: number, pageSize: number) => {
		setQueryParams((prev) => ({
			...prev,
			currentPage: page,
			pageSize: pageSize,
		}));
	}, []);

	const handleSearch = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		setQueryParams((prev) => ({
			...prev,
			stringToSearch: e.target.value.trim(),
			currentPage: 1,
		}));
	}, []);

	const handleResetTableFilters = useCallback(() => {
		setQueryParams((prev) => ({ ...prev, currentPage: 1 }));
	}, []);

	return {
		handleResetTableFilters,
		handleTableChange,
		handlePageChange,
		handleSearch,
		setQueryParams,
		queryParams,
	};
};
