import * as yup from 'yup';

export const issueDetailsSchema = yup.object({
	date: yup.string().required('Заполните это поле, оно обязательное'),
	count: yup.number().required('Заполните это поле, оно обязательное'),
	receiver: yup.string().required('Заполните это поле, оно обязательное'),
	certificate: yup.string().required('Заполните это поле, оно обязательное'),
	fio: yup.string().required('Заполните это поле, оно обязательное'),
});

export const operationFillSchema = yup.object({
	step_id: yup.number().required('Заполните это поле, оно обязательное'),
	fio: yup.string().required('Заполните это поле, оно обязательное'),
	date: yup.string().required('Заполните это поле, оно обязательное'),
});

export const cancelOperationSchema = yup.object({
	operation_id: yup.number().required('Заполните это поле, оно обязательное'),
	closing_employee_fio: yup.number().required('Заполните это поле, оно обязательное'),
	date: yup.string().required('Заполните это поле, оно обязательное'),
});
