import { useEffect, useState } from 'react';
import { Block, FocusedBlock, TechCardProps } from '../tech-card.types';

export const useFocusedBlock = ({ isEditable, focusedBlock }: Pick<TechCardProps, 'isEditable' | 'focusedBlock'>) => {
	const [focused, setIsFocused] = useState<FocusedBlock>(() => {
		return {
			block: focusedBlock ? focusedBlock : null,
			focusedBy: focusedBlock ? 'tab' : null,
			atCurrentBlock: focusedBlock ? true : false,
		};
	});

	useEffect(() => {
		if (focusedBlock) {
			setIsFocused((prev) => {
				return { ...prev, block: focusedBlock, focusedBy: 'tab', atCurrentBlock: true };
			});
			return;
		}

		document.addEventListener('mousedown', function () {
			setIsFocused((prev) => ({ ...prev, focusedBy: 'mouse' }));
		});

		document.addEventListener('keydown', function (e) {
			if ((e.shiftKey && e.key === 'Tab') || e.key === 'Tab') {
				setIsFocused((prev) => ({
					...prev,
					focusedBy: 'tab',
				}));
			}
		});
		return () => {
			document.removeEventListener('mousedown', function () {
				setIsFocused((prev) => ({ ...prev, focusedBy: 'mouse' }));
			});

			document.removeEventListener('keydown', function (e) {
				if ((e.shiftKey && e.key === 'Tab') || e.key === 'Tab') {
					setIsFocused((prev) => ({
						...prev,
						focusedBy: 'tab',
					}));
				}
			});
		};
	}, [focusedBlock]);

	useEffect(() => {
		if (!isEditable) {
			setIsFocused({
				block: null,
				focusedBy: null,
				atCurrentBlock: false,
			});
		}
	}, [isEditable]);

	const handleFocus = (value: Block) => {
		setIsFocused((prev) => ({
			...prev,
			block: value,
			atCurrentBlock: prev.block === value,
		}));
	};

	return {
		handleFocus,
		setIsFocused,
		focused,
	};
};
