import { selectAssemblyOptions } from '@/entities/assembly';
import {
	useLazyGetAllCatalogSymbolsQuery,
	useLazyGetAssembliesQuery,
	useLazyGetProductSymbolForFilterQuery,
} from '@/shared/state/api/swagger';
import { useHandleSelectOption } from '../../lib/handle-select-option';
import { FilterSelect } from './filter-select';

export const AssemblySymbolLazySelect = () => {
	const [trigger, { options, isFetching }] = useLazyGetAssembliesQuery({
		selectFromResult: (result) => {
			return {
				...result,
				options: selectAssemblyOptions(result?.data?.payload),
			};
		},
	});
	const { handleSelectOption } = useHandleSelectOption('assemblySymbol', options);

	return (
		<FilterSelect
			label="Обозначение"
			name="assemblySymbol"
			options={options}
			onSearch={trigger}
			isLoading={isFetching}
			onChange={handleSelectOption}
			alertName="Введите обозначение"
		/>
	);
};

export const ProductSymbolLazySelect = () => {
	const [trigger, { options, isFetching }] = useLazyGetProductSymbolForFilterQuery({
		selectFromResult: (result) => ({
			...result,
			options: result?.data?.payload?.map((product, idx) => ({
				value: idx,
				label: product,
			})),
		}),
	});
	const { handleSelectOption } = useHandleSelectOption('productSymbol', options);

	return (
		<FilterSelect
			label="Обозначение"
			name="productSymbol"
			options={options}
			onSearch={trigger}
			isLoading={isFetching}
			onChange={handleSelectOption}
			alertName="Введите обозначение"
		/>
	);
};

export const AllTechMapSymbolLazySelect = () => {
	const [trigger, { options, isFetching }] = useLazyGetAllCatalogSymbolsQuery({
		selectFromResult: (result) => ({
			...result,
			options: result?.data?.payload?.map((product, idx) => ({
				value: idx,
				label: product,
			})),
		}),
	});
	const { handleSelectOption } = useHandleSelectOption('symbol', options);

	return (
		<FilterSelect
			label="Обозначение"
			name="symbol"
			options={options}
			onSearch={trigger}
			isLoading={isFetching}
			onChange={handleSelectOption}
			alertName="Введите обозначение"
		/>
	);
};
