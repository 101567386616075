import { TechCardStatus, TechCardStatuses } from '@/shared';

export const MAP_STATUS_TO_NAME: Record<TechCardStatus, string> = {
	[TechCardStatuses.released]: 'Отгружена',
	[TechCardStatuses.progress]: 'В работе',
	[TechCardStatuses.accepted_at_sgd]: 'Принята на СГД',
	[TechCardStatuses.canceled]: 'Аннулирована',
	[TechCardStatuses.partially_issued]: 'Частично выдана',
	[TechCardStatuses.completed]: 'Завершена',
	[TechCardStatuses.accepted_at_storage]: 'На складе',
	[TechCardStatuses.wasted]: 'Забракована',
};
