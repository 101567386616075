import { EngineerValidateSuccess, Stub } from '@/shared';
import { OperationAlertProps } from './alert.types';

export const DetailsIssuedAlert = ({ productTechMapNumber }: OperationAlertProps) => {
	return (
		<Stub
			icon={<EngineerValidateSuccess />}
			header="Детали выданы"
			label={
				<p>
					Информация по выдаче деталей для маршрутной карты <strong>№{productTechMapNumber}</strong> успешно
					сохранена
				</p>
			}
		/>
	);
};
