import { Dispatch, useEffect, useState, SetStateAction } from 'react';

export const useShowTooltip = (show: boolean) => {
	const [isTooltipVisible, setIsTooltipVisible] = useState(show);

	useEffect(() => {
		setIsTooltipVisible(show);
	}, [show]);

	return [isTooltipVisible, setIsTooltipVisible] as [boolean, Dispatch<SetStateAction<boolean>>];
};
