import _debounce from 'lodash/debounce';
import type { FC } from 'react';
import { useCallback, useEffect, useState } from 'react';

import { UserRoles } from '@/shared/core';
import { useAppSelector } from '@/shared/state';

import { Button, TextHeader, useToast } from '@chakra-ui-kraud/react';
import { ChevronRightIcon } from '@chakra-ui/icons';

import { ProductCatalogTable } from '@/entities';
import { MainContentLoader } from '@/shared/components/main-content-loader';
import { Search } from '@/shared/components/search';
import { showErrorToast } from '@/shared/components/toasts';
import { ProductModal } from '@/widgets/product-modal';

import { GetProductsApiArg, ProductCatalogResponse, useGetProductsQuery } from '@/shared/state/api/swagger';
import { Layout } from '@/widgets';
import styles from './product-catalog.module.scss';

export const ProductCatalog: FC = () => {
	const [isCreateProductModalOpen, setIsCreateProductModalOpen] = useState(false);
	const [selectedProduct, setSelectedProduct] = useState<{
		product: ProductCatalogResponse | undefined;
		variant: 'edit' | 'create';
	}>({ product: undefined, variant: 'create' });
	const [queryParams, setQueryParams] = useState<GetProductsApiArg>({
		stringToSearch: undefined,
		pageSize: 32,
		currentPage: 1,
		orderBy: undefined,
		orderDir: undefined,
	});
	const [searchValue, setSearchValue] = useState('');
	const toast = useToast();
	const userRole = useAppSelector((state) => state.auth.userProfile?.role);
	const {
		data,
		isLoading,
		isFetching,
		isError: isProductError,
	} = useGetProductsQuery(queryParams, { refetchOnMountOrArgChange: true });

	const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
		setQueryParams((prev) => ({
			...prev,
			stringToSearch: e.target.value.trim(),
			currentPage: 1,
		}));
	};

	const debouncedSearch = useCallback(_debounce(handleSearch, 700), []);

	useEffect(() => {
		if (isProductError) {
			showErrorToast(toast, {
				description: 'При получении справочника деталей произошла ошибка',
			});
		}
	}, [isProductError]);

	const handlePageChange = (page: number, pageSize: number) => {
		setQueryParams((prev) => ({
			...prev,
			currentPage: page,
			pageSize: pageSize,
		}));
	};

	const onOpenModal = (selectedProduct: ProductCatalogResponse, variant: 'create' | 'edit') => {
		setIsCreateProductModalOpen(true);
		setSelectedProduct({ product: selectedProduct, variant });
	};

	return (
		<>
			<Layout
				title={
					<TextHeader className={styles['product-catalog__title']} variant="h1">
						Справочник деталей
					</TextHeader>
				}
				search={
					<Search
						handleChange={(e) => {
							debouncedSearch(e);
							setSearchValue(e.target.value);
						}}
						value={searchValue}
					/>
				}
				headerLeftButton={
					userRole !== UserRoles.user && (
						<Button
							size="md"
							variant="ghost"
							colorScheme="primary"
							lineHeight="24px"
							rightIcon={<ChevronRightIcon />}
							onClick={() => setIsCreateProductModalOpen(true)}
						>
							Добавить деталь
						</Button>
					)
				}
			>
				{isFetching && searchValue && !isLoading ? <MainContentLoader /> : <></>}

				<ProductCatalogTable
					pageSize={queryParams.pageSize ?? 32}
					products={data?.payload ?? []}
					onPageChange={handlePageChange}
					page={data?.page}
					total={data?.total}
					setQueryParams={setQueryParams}
					isLoading={isLoading}
					handleClick={onOpenModal}
				/>
			</Layout>
			{isCreateProductModalOpen && (
				<ProductModal
					isOpen={isCreateProductModalOpen}
					onClose={() => {
						setIsCreateProductModalOpen(false);
						setSelectedProduct({ product: undefined, variant: 'create' });
					}}
					title={selectedProduct.variant === 'create' ? 'Добавление детали' : 'Редактирование детали'}
					selectedProduct={selectedProduct.product}
					variant={selectedProduct.variant}
				/>
			)}
		</>
	);
};
