import { Comment } from '@/shared/components';
import { Stack } from '@chakra-ui-kraud/react';
import { FC } from 'react';
import { CommentsProps } from '../model/comments.types';
import styles from './issue-comments.module.scss';

export const IssueComments: FC<CommentsProps> = ({ comments = [], ...props }) => {
	return (
		<Stack {...props} className={styles['comments']}>
			{comments.map(({ id, text, created_at, user }) => (
				<Comment productCommentId={id} key={id} author={user} text={text} created_at={created_at} />
			))}
		</Stack>
	);
};
