import { FC, useCallback, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FilterSelectProps } from '../filter.types';
import _debounce from 'lodash/debounce';
import { FilterInputGroup } from './filter-input-group';
import { Select } from 'antd';
import styles from '../../../../shared/components/custom-select/custom-select.module.scss';
import clsx from 'clsx';
import { NotFoundContentDropdown } from '@/shared';

export const FilterSelect: FC<FilterSelectProps> = ({
	isLoading,
	options,
	allowClear = false,
	showSearch = true,
	alertName = '',
	onSearch,
	name,
	label,
	filterOption = false,
	...props
}) => {
	const { control } = useFormContext();

	const [isFocused, setIsFocused] = useState(false);
	const handleSearch = useCallback(
		_debounce((stringToSearch: string) => {
			onSearch?.({ stringToSearch });
		}, 300),
		[onSearch],
	);

	return (
		<Controller
			name={name}
			control={control}
			render={({ field, fieldState: { error } }) => (
				<FilterInputGroup name={name} label={label}>
					<Select
						{...field}
						onFocus={() => setIsFocused(true)}
						onBlur={() => setIsFocused(false)}
						showSearch={showSearch}
						allowClear={allowClear}
						onSearch={handleSearch}
						dropdownStyle={{ zIndex: 1400 }}
						size={'large'}
						optionFilterProp={'children'}
						className={clsx(styles['select'], styles['select-large'], {
							[styles['select-invalid']]: error,
							[styles['select-focus']]: isFocused,
						})}
						notFoundContent={
							<NotFoundContentDropdown isFetchingNaming={!!isLoading} alertName={alertName} />
						}
						loading={isLoading}
						filterOption={filterOption}
						options={options}
						{...props}
					/>
				</FilterInputGroup>
			)}
		></Controller>
	);
};
