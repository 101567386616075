import { EngineerValidateError, Stub } from '@/shared';
import { Button, chakra } from '@chakra-ui-kraud/react';
import { OperationAlertProps } from './alert.types';

interface ErrorFillOperationProps extends OperationAlertProps {
	handleCompleteOperationAgain: () => void;
}

export const ErrorFillOperationAlert = ({
	handleCompleteOperationAgain,
	productTechMapNumber,
}: ErrorFillOperationProps) => {
	return (
		<Stub
			icon={<EngineerValidateError />}
			header="Ошибка заполнения операции"
			label={
				<p>
					Изменения по маршрутной карте <strong>№{productTechMapNumber}</strong> не были сохранены. Попробуйте
					ещё раз.
				</p>
			}
			footer={
				<chakra.div width="100%">
					<Button variant="solid" width="100%" onClick={handleCompleteOperationAgain}>
						Заполнить еще раз
					</Button>
				</chakra.div>
			}
		/>
	);
};
