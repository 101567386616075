import _debounce from 'lodash/debounce';
import type { FC } from 'react';
import { useCallback, useEffect, useState } from 'react';

import { UserRoles } from '@/shared/core';
import { useAppSelector } from '@/shared/state';
import { format } from 'date-fns';

import { NumCatalogTable } from '@/entities';
import { DEFAULT_DATE_FNS_YEAR_FORMAT, MainContentLoader, Search, showErrorToast } from '@/shared';
import { NumModal } from '@/widgets/num-modal';
import { Button, chakra, Spinner, TextHeader, useToast } from '@chakra-ui-kraud/react';
import { ChevronRightIcon } from '@chakra-ui/icons';

import { Layout } from '@/widgets';
import { MadIcon } from 'madsoft-icons';
import styles from './num-catalog.module.scss';
import {
	GetNumsApiArg,
	NumCatalogResponse,
	useGetNumCatalogListExcelMutation,
	useGetNumsQuery,
} from '@/shared/state/api/swagger';

export const NumCatalog: FC = () => {
	const [isCreateNumModalOpen, setIsCreateNumModalOpen] = useState(false);
	const [getNumCatalogListExcel, { isLoading: isGetNumsExcelLoading }] = useGetNumCatalogListExcelMutation();
	const [selectedNum, setSelectedNum] = useState<{
		num: NumCatalogResponse | undefined;
		variant: 'edit' | 'create';
	}>({ num: undefined, variant: 'create' });
	const [queryParams, setQueryParams] = useState<GetNumsApiArg>({
		stringToSearch: undefined,
		pageSize: 32,
		currentPage: 1,
		orderBy: undefined,
		orderDir: undefined,
	});
	const [searchValue, setSearchValue] = useState('');
	const {
		data,
		isLoading,
		isFetching,
		isError,
		refetch: refetchNums,
	} = useGetNumsQuery({ ...queryParams }, { refetchOnMountOrArgChange: true });
	const userRole = useAppSelector((state) => state.auth.userProfile?.role);
	const toast = useToast();

	const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
		setQueryParams((prev) => ({
			...prev,
			stringToSearch: e.target.value.trim(),
			currentPage: 1,
		}));
	};

	const debouncedSearch = useCallback(_debounce(handleSearch, 700), []);

	const handlePageChange = (page: number, pageSize: number) => {
		setQueryParams((prev) => ({
			...prev,
			currentPage: page,
			pageSize: pageSize,
		}));
	};

	useEffect(() => {
		if (isError) {
			showErrorToast(toast, {
				description: 'При получении справочника материалов произошла ошибка',
			});
		}
	}, [isError]);

	const onOpenModal = (selectedNum: NumCatalogResponse | undefined, variant: 'create' | 'edit') => {
		if (selectedNum?.id) {
			setSelectedNum({ num: selectedNum, variant });
		} else setSelectedNum({ num: undefined, variant: 'create' });

		setIsCreateNumModalOpen(true);
	};

	const onCancel = () => {
		setIsCreateNumModalOpen(false);
		setSelectedNum({ num: undefined, variant: 'create' });
	};

	const handleGetNumsExcel = useCallback(() => {
		getNumCatalogListExcel(queryParams)
			.unwrap()
			.then((res) => {
				const href = window.URL.createObjectURL(new Blob([res]));
				const link = document.createElement('a');
				link.href = href;
				link.download = `Num_list_${format(new Date(), DEFAULT_DATE_FNS_YEAR_FORMAT)}.xlsx`;
				link.click();
				link.remove();
			})
			.catch((err) => {
				console.error(err);
				showErrorToast(toast, { description: 'При скачивании Excel произошла ошибка' });
			});
	}, [queryParams]);

	return (
		<>
			<Layout
				title={
					<TextHeader className={styles['num-catalog__title']} variant="h1">
						Справочник материалов
					</TextHeader>
				}
				search={
					<Search
						handleChange={(e) => {
							debouncedSearch(e);
							setSearchValue(e.target.value);
						}}
						value={searchValue}
					/>
				}
				headerLeftButton={
					userRole !== UserRoles.user && (
						<Button
							size="md"
							variant="ghost"
							colorScheme="primary"
							lineHeight="24px"
							rightIcon={<ChevronRightIcon />}
							onClick={() => setIsCreateNumModalOpen(true)}
						>
							Добавить материал
						</Button>
					)
				}
				filters={
					<chakra.div>
						<Button
							size="md"
							variant="ghost"
							colorScheme="tertiary"
							rightIcon={
								isGetNumsExcelLoading ? (
									<Spinner size="xs" />
								) : (
									<MadIcon
										module="basic"
										mode="default"
										size="default"
										type="outline"
										name="arrow-down-tray"
									/>
								)
							}
							minW="125px"
							onClick={handleGetNumsExcel}
						>
							{isGetNumsExcelLoading ? 'Обработка данных' : 'Выгрузить данные'}
						</Button>
					</chakra.div>
				}
			>
				{isFetching && searchValue && !isLoading ? <MainContentLoader /> : <></>}

				<NumCatalogTable
					pageSize={queryParams.pageSize ?? 32}
					data={data?.payload || []}
					onPageChange={handlePageChange}
					page={data?.page}
					total={data?.total}
					setQueryParams={setQueryParams}
					isLoading={isLoading}
					handleClick={onOpenModal}
				/>
			</Layout>
			{isCreateNumModalOpen && (
				<NumModal
					refetchNums={refetchNums}
					isOpen={isCreateNumModalOpen}
					onClose={onCancel}
					title={selectedNum.variant === 'create' ? 'Добавление материала' : 'Редактирование материала'}
					selectedNum={selectedNum.num}
					variant={selectedNum.variant}
				/>
			)}
		</>
	);
};
