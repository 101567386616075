import { Box, Button, Flex, Text } from '@chakra-ui-kraud/react';
import { FC, PropsWithChildren } from 'react';
import { useFormContext } from 'react-hook-form';
import styles from '../filter.module.scss';

interface InputGroupProps extends PropsWithChildren {
	label: string;
	name: string;
	onClear?: () => void;
	showClearButton?: boolean;
}

export const FilterInputGroup: FC<InputGroupProps> = ({ label, onClear, children, name, ...props }) => {
	const { setValue, getValues } = useFormContext();
	const value = getValues(name);
	const showClearValue = props.showClearButton != undefined ? props.showClearButton : value != undefined;

	return (
		<Box className={styles['input-group']}>
			<Flex justifyContent="space-between">
				<Text size="md" className={styles['input-group_label']}>
					{label}
				</Text>
				{showClearValue && (
					<Button
						size="xs"
						variant="ghost"
						onClick={() => {
							setValue(name, null);
							onClear?.();
						}}
					>
						Очистить
					</Button>
				)}
			</Flex>
			{children}
		</Box>
	);
};
