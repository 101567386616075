import { ProductOperationDomain, ProductOperationUpdate, ProductTechMapResponse } from '@/shared/state/api/swagger';

export const sortAllOperations = (techMap: ProductTechMapResponse) => {
	return [...(techMap?.product_operations ?? []), ...(techMap?.default_product_operations ?? [])].sort(
		(a, b) => a.step_id - b.step_id,
	);
};

export const sortOperations = (operationsPayload: ProductOperationUpdate[]) => {
	return operationsPayload.sort((a, b) => Number(a.step_id) - Number(b.step_id));
};

export const getOperationByStepId = (
	techMap: ProductTechMapResponse,
	stepId: number,
): ProductOperationDomain | undefined => {
	return sortAllOperations(techMap).find((op) => op.step_id === stepId);
};

export const mapOperationsByStepId = (techMap: ProductTechMapResponse) => {
	const allOperations = sortAllOperations(techMap);
	return allOperations.map(({ name, step_id }) => ({ label: `${step_id} - ${name}`, value: step_id }));
};

export const mapOperations = (techMap: ProductTechMapResponse) => {
	const allOperations = sortAllOperations(techMap);
	return allOperations.map(({ name, step_id }) => ({ label: name, value: step_id }));
};
