import { MKSelect } from '@/features/mk-select';
import { DEFAULT_YEAR_FORMAT, useTechCard } from '@/shared';
import { ProductTechMapResponse } from '@/shared/state/api/swagger';
import { Flex, Input, Table, Td, Th, Tr, Text, Thead, Tbody } from '@chakra-ui-kraud/react';
import dayjs from 'dayjs';
import { FC, useCallback, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { AssemblyConfig } from '../../form-assembly-config';
import { useFormValues } from '../../lib/use-form-values';
import styles from './product.module.scss';
import clsx from 'clsx';
import { ProductsBlockProps } from './product.types';
import { isOperationDone } from '../operations/lib/operations-utils';

export const ProductsBlock: FC<ProductsBlockProps> = ({ isEditable }) => {
	const { isCreationMode } = useTechCard();
	const { control, setValue } = useFormContext<AssemblyConfig>();
	const [products, setProducts] = useState<ProductTechMapResponse[]>([]);
	const { assembly_products: fields = [], assembly, assembly_operations } = useFormValues();

	useEffect(() => {
		setProducts([]);
	}, [assembly, setValue]);

	const isComplectOperationDone = useCallback(
		(index: number) => {
			const complectOperations = assembly_operations?.filter((operation) => operation.step_id === 5);
			const complectOperation = complectOperations?.[index];
			if (!complectOperation) {
				return false;
			}

			return isOperationDone(complectOperation);
		},
		[assembly_operations],
	);

	return (
		<Flex className={styles['product']} mt={'-1px'} maxW="100%">
			<Table
				sx={{
					tr: {
						height: '40px',
						maxWidth: '100%',
					},
				}}
			>
				<Thead>
					<Tr>
						<Th maxW="180px">Обозначение детали</Th>
						<Th maxW="180px">Наименование детали</Th>
						<Th maxW="180px">Марка материала</Th>
						<Th maxW="160px">ТУ, ГОСТ на материал</Th>
						<Th maxW="80px">Сортамент</Th>
						<Th maxW="160px">ТУ, ГОСТ на сортамент</Th>
						<Th w="100px">НУМ</Th>
						<Th w="100px">№ плавки (партии)</Th>
						<Th maxW="116px">Замена материал</Th>
						<Th maxW="116px">Дата изготовления</Th>
						<Th maxW="180px">№ маршрутной карты</Th>
						<Th w="120px">Количество деталей</Th>
						<Th w="160px">Подпись лица выдавшего детали</Th>
					</Tr>
				</Thead>
				<Tbody>
					{fields.map((product, idx) => (
						<Tr key={`${product.product_symbol}_${idx}`} maxWidth={'180px'}>
							<Td overflow="hidden" maxW="180px">
								{product.product_symbol}
							</Td>
							<Td key={`${product.product_name}_${idx}`} overflow="hidden" maxW="180px">
								{product.product_name}
							</Td>
							<Td overflow="hidden" maxW="180px">
								{isCreationMode ? (
									<Text className={clsx(styles['text-in-cell'], styles['print_bold_font'])}>
										{products[idx]?.num?.symbol}
									</Text>
								) : (
									<Text className={clsx(styles['text-in-cell'], styles['print_bold_font'])}>
										{product.product_tech_map?.num?.symbol}
									</Text>
								)}
							</Td>
							<Td overflow="hidden" maxW="180px">
								{isCreationMode ? (
									<Text className={clsx(styles['text-in-cell'], styles['print_bold_font'])}>
										{products[idx]?.num?.gost}
									</Text>
								) : (
									<Text className={clsx(styles['text-in-cell'], styles['print_bold_font'])}>
										{product.product_tech_map?.num?.gost}
									</Text>
								)}
							</Td>
							<Td overflow="hidden" maxW="160px">
								{isCreationMode ? (
									<Text className={clsx(styles['text-in-cell'], styles['print_bold_font'])}>
										{products[idx]?.num?.sortament}
									</Text>
								) : (
									<Text className={clsx(styles['text-in-cell'], styles['print_bold_font'])}>
										{product.product_tech_map?.num?.sortament}
									</Text>
								)}
							</Td>
							<Td overflow="hidden" maxW="160px">
								{isCreationMode ? (
									<Text className={clsx(styles['text-in-cell'], styles['print_bold_font'])}>
										{products[idx]?.num?.gost_na_sortament}
									</Text>
								) : (
									<Text className={clsx(styles['text-in-cell'], styles['print_bold_font'])}>
										{product.product_tech_map?.num?.gost_na_sortament}
									</Text>
								)}
							</Td>
							<Td overflow="hidden">
								{isCreationMode ? (
									<Text className={clsx(styles['text-in-cell'], styles['print_bold_font'])}>
										{products[idx]?.num?.num}
									</Text>
								) : (
									<Text className={clsx(styles['text-in-cell'], styles['print_bold_font'])}>
										{product.product_tech_map?.num?.num}
									</Text>
								)}
							</Td>
							<Td overflow="hidden">
								{isCreationMode ? (
									<Text className={clsx(styles['text-in-cell'], styles['print_bold_font'])}>
										{products[idx]?.num?.number_of_melt}
									</Text>
								) : (
									<Text className={clsx(styles['text-in-cell'], styles['print_bold_font'])}>
										{product.product_tech_map?.num?.number_of_melt}
									</Text>
								)}
							</Td>
							<Td overflow="hidden">
								{isCreationMode ? (
									<Text className={clsx(styles['text-in-cell'], styles['print_bold_font'])}>
										{products[idx]?.num?.replace_decision}
									</Text>
								) : (
									<Text className={clsx(styles['text-in-cell'], styles['print_bold_font'])}>
										{product.product_tech_map?.num?.replace_decision}
									</Text>
								)}
							</Td>
							<Td>
								{isCreationMode ? (
									<Text className={clsx(styles['text-in-cell'], styles['print_bold_font'])}>
										{products[idx]?.storage_at &&
											dayjs(products[idx].storage_at).format(DEFAULT_YEAR_FORMAT)}
									</Text>
								) : (
									<Text className={clsx(styles['text-in-cell'], styles['print_bold_font'])}>
										{product.product_tech_map?.storage_at &&
											dayjs(product.product_tech_map?.storage_at).format(DEFAULT_YEAR_FORMAT)}
									</Text>
								)}
							</Td>
							<Td>
								{isEditable || isCreationMode ? (
									<MKSelect
										product={product}
										onSelect={(item) => {
											const newProducts = [...products];
											newProducts[idx] = {
												...newProducts[idx],
												num: {
													...item.num!,
												},
											};

											const newAssembliesProducts = [...fields];

											newAssembliesProducts[idx].product_tech_map_id = item.id;

											if (!isCreationMode) {
												newAssembliesProducts[idx].product_tech_map = item;
											}

											setValue(`assembly_products`, newAssembliesProducts);
											setValue(`assembly_products.${idx}.product_tech_map.number`, item.number);
											setProducts(newProducts);
										}}
										query={{
											statuses: ['accepted_at_sgd', 'progress', 'partially_issued'],
											productNameStrict: product.product_name,
											productSymbolStrict: product.product_symbol,
										}}
										name={`assembly_products.${idx}.product_tech_map.number`}
										defaultValue={product.product_tech_map?.number}
										disabled={isComplectOperationDone(idx)}
										className={styles['custom-select']}
									/>
								) : (
									<Text>{product.product_tech_map?.number}</Text>
								)}
							</Td>
							<Td w="120px" overflow="hidden">
								{isEditable && !isCreationMode ? (
									<Controller
										name={`assembly_products.${idx}.product_sgd_out.count`}
										control={control}
										render={({ field: { onChange, value } }) => (
											<Input
												backgroundColor="transparent"
												isDisabled={isComplectOperationDone(idx)}
												tabIndex={2}
												onChange={(e) => {
													onChange(e.target.value.replace(/\D/g, ''));
												}}
												value={value as number}
												name="assembly.name"
											/>
										)}
									/>
								) : (
									<Text className={clsx(styles['text-in-cell'], styles['print_bold_font'])}>
										{product?.product_sgd_out?.count}
									</Text>
								)}
							</Td>
							<Td w="120px"></Td>
						</Tr>
					))}
				</Tbody>
			</Table>
		</Flex>
	);
};
