import { AssemblyCatalogResponse } from '@/shared/state/api/swagger';

export const selectAssemblyOptions = (assemblyCatalog: AssemblyCatalogResponse[] = []) =>
	assemblyCatalog?.map((assembly) => ({
		value: assembly.id,
		label: `${assembly.symbol || 'Нет обозначения'} (${assembly.name || '-'})`,
	})) || [{ value: 0, label: '' }];

export const selectAssemblyNamingOptions = (assemblyCatalog: AssemblyCatalogResponse[] = []) =>
	assemblyCatalog?.map((assembly) => ({
		value: assembly.id,
		label: assembly.name,
	})) || [{ value: 0, label: '' }];
