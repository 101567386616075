import { selectAssemblyNamingOptions } from '@/entities/assembly';
import { selectPreforms } from '@/entities/preform';
import {
	useLazyGetAllCatalogNamesQuery,
	useLazyGetAssembliesQuery,
	useLazyGetPreformsQuery,
	useLazyGetProductNamesForFilterQuery,
} from '@/shared/state/api/swagger';
import { useHandleSelectOption } from '../../lib/handle-select-option';
import { FilterSelect } from './filter-select';

export const AssemblyNamingLazySelect = () => {
	const [trigger, { options, isFetching }] = useLazyGetAssembliesQuery({
		selectFromResult: (result) => {
			return {
				...result,
				options: selectAssemblyNamingOptions(result?.data?.payload),
			};
		},
	});
	const { handleSelectOption } = useHandleSelectOption('assemblyName', options);

	return (
		<FilterSelect
			label="Наименование"
			name="assemblyName"
			options={options}
			isLoading={isFetching}
			onSearch={trigger}
			alertName={'Введите наименование'}
			onChange={handleSelectOption}
		/>
	);
};

export const PreformNamingLazySelect = () => {
	const [trigger, { options, isFetching }] = useLazyGetPreformsQuery({
		selectFromResult: (result) => {
			return {
				...result,
				options: selectPreforms(result?.data?.payload),
			};
		},
	});
	const { handleSelectOption } = useHandleSelectOption('preformName', options);

	return (
		<FilterSelect
			label="Наименование"
			name="preformName"
			options={options}
			isLoading={isFetching}
			onSearch={trigger}
			alertName={'Введите наименование'}
			onChange={handleSelectOption}
		/>
	);
};

export const ProductNamingLazySelect = () => {
	const [trigger, { options, isFetching }] = useLazyGetProductNamesForFilterQuery({
		selectFromResult: (result) => ({
			...result,
			options: result?.data?.payload?.map((product, idx) => ({
				value: idx,
				label: product,
			})),
		}),
	});
	const onSearch = ({ stringToSearch }: { stringToSearch: string }) => {
		trigger({ stringToSearch });
	};
	const { handleSelectOption } = useHandleSelectOption('productName', options);

	return (
		<FilterSelect
			label="Наименование"
			name="productName"
			options={options}
			isLoading={isFetching}
			onSearch={onSearch}
			onChange={handleSelectOption}
			alertName={'Введите наименование'}
		/>
	);
};

export const AllTechMapNamingLazySelect = () => {
	const [trigger, { options, isFetching }] = useLazyGetAllCatalogNamesQuery({
		selectFromResult: (result) => ({
			...result,
			options: result?.data?.payload?.map((product, idx) => ({
				value: idx,
				label: product,
			})),
		}),
	});
	const onSearch = ({ stringToSearch }: { stringToSearch: string }) => {
		trigger({ stringToSearch });
	};
	const { handleSelectOption } = useHandleSelectOption('name', options);

	return (
		<FilterSelect
			label="Наименование"
			name="name"
			options={options}
			isLoading={isFetching}
			onSearch={onSearch}
			onChange={handleSelectOption}
			alertName={'Введите наименование'}
		/>
	);
};
