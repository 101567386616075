import { FC, useMemo, useRef, useState } from 'react';

import { ProductCatalogForm } from '@/entities';
import { SideModal } from '@/shared/components';
import { showErrorToast, showSuccessToast } from '@/shared/components/toasts';
import { Button, ButtonGroup, useToast } from '@chakra-ui-kraud/react';

import {
	BodyCreateProduct,
	BodyUpdateProduct,
	ProductCatalogResponse,
	ProductOperationTypesCatalog,
	useAddFileToDetailMutation,
	useCreateProductMutation,
	useUpdateProductMutation,
} from '@/shared/state/api/swagger';
import { ProductModalProps } from './product-modal.types';
// import styles from './product-modal.module.scss';

export const ProductModal: FC<ProductModalProps> = ({ isOpen, onClose, title, variant, onBlur, selectedProduct }) => {
	const toast = useToast();
	const operations = useMemo(() => selectedProduct?.operation_types ?? [], [selectedProduct]);
	const [createProduct, { isLoading: isCreateProductLoading }] = useCreateProductMutation();
	const [updateProduct, { isLoading: isUpdateProductLoading }] = useUpdateProductMutation();
	const [addFileToDetail] = useAddFileToDetailMutation();

	const [operation_types, setOperationType] = useState<ProductOperationTypesCatalog[]>([]);
	const handleSubmit = (data: ProductCatalogResponse) => {
		const standard_consumption_meters = data.standard_consumption_meters
			? Number(String(data.standard_consumption_meters).replace(',', '.'))
			: '';

		const standard_consumption_square_meters = data.standard_consumption_square_meters
			? Number(String(data.standard_consumption_square_meters).replace(',', '.'))
			: '';

		const standard_consumption_kg = data.standard_consumption_kg
			? Number(String(data.standard_consumption_kg).replace(',', '.'))
			: '';

		const standard_consumption_grams = data.standard_consumption_grams
			? Number(String(data.standard_consumption_grams).replace(',', '.'))
			: '';

		if (variant === 'create') {
			const bodyCreateProduct: BodyCreateProduct = {
				product: {
					name: data?.name,
					extra_name: data?.extra_name,
					symbol: data?.symbol,
					extra_symbol: data?.extra_symbol,
					standard_consumption_meters: Number(standard_consumption_meters),
					standard_consumption_square_meters: Number(standard_consumption_square_meters),
					standard_consumption_kg: Number(standard_consumption_kg),
					standard_consumption_grams: Number(standard_consumption_grams),
					approval_card: data?.approval_card || '-',
					extra_approval_card: data?.extra_approval_card,
				},
				material: {
					symbol: data?.material?.symbol,
					gost: data?.material?.gost,
					sortament: data?.material?.sortament,
					gost_na_sortament: data?.material?.gost_na_sortament,
				},
				product_operations:
					data?.operation_types
						?.filter((el) => el.name)
						.map((el, index) => ({ name: el.name ?? '', step_id: (index + 1) * 5 })) ?? [],
			};
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			//@ts-ignore
			createProduct({ bodyCreateProduct })
				.unwrap()
				.then((product) => {
					if (data?.file && data?.file instanceof Blob && product?.id) {
						return addFileToDetail({
							bodyAddFileToDetail: { file: data.file },
							id: product?.id,
						});
					}
				})
				.then(() => {
					showSuccessToast(toast, { description: 'Деталь создана' });
					onClose();
				})
				.catch((e) => {
					if (e.status === 422 && !bodyCreateProduct.product_operations?.length) {
						showErrorToast(toast, {
							description: 'Для создания детали необходимо заполнить блок операции',
						});
					} else {
						showErrorToast(toast, {
							description: 'При создании детали произошла ошибка',
						});
					}
				});
		}
		if (variant === 'edit') {
			if (selectedProduct?.material_id) {
				const bodyUpdateProduct: BodyUpdateProduct = {
					update_product_request: {
						name: data?.name,
						symbol: data?.symbol,
						extra_name: data?.extra_name,
						extra_symbol: data?.extra_symbol,
						standard_consumption_meters: standard_consumption_meters ? standard_consumption_meters : '',
						standard_consumption_square_meters: standard_consumption_square_meters
							? standard_consumption_square_meters
							: '',
						standard_consumption_kg: standard_consumption_kg ? standard_consumption_kg : '',
						standard_consumption_grams: standard_consumption_grams ? standard_consumption_grams : '',
						id: Number(selectedProduct?.id),
						approval_card: data?.approval_card,
						extra_approval_card: data?.extra_approval_card,
					},
					material: {
						symbol: data?.material?.symbol,
						gost: data?.material?.gost,
						sortament: data?.material?.sortament,
						gost_na_sortament: data?.material?.gost_na_sortament,
						id: selectedProduct.material_id as number,
					},
					product_operations: operation_types
						.filter((el) => el?.name)
						.map((el, index) => ({
							name: el.name ?? '',
							step_id: (index + 1) * 5,
							// AGAT-1580 hot-fix
							//id: el.operation_type_id ? el.operation_type_id : undefined,
						})),
				};

				Promise.all([
					updateProduct({ bodyUpdateProduct }),
					data?.file &&
						data?.file instanceof Blob &&
						addFileToDetail({
							bodyAddFileToDetail: { file: data.file },
							id: Number(selectedProduct?.id),
						}),
				])
					.then(() => {
						showSuccessToast(toast, { description: 'Деталь обновлена' });
						onClose();
					})
					.catch(() => {
						showErrorToast(toast, {
							description: 'При обновлении детали произошла ошибка',
						});
					});
			}
		}
	};

	const formRef = useRef<HTMLFormElement>();
	return (
		<SideModal
			title={title}
			isOpen={isOpen}
			onClose={onClose}
			maxWidth={'644px'}
			footer={
				<ButtonGroup>
					<Button size="md" variant="ghost" colorScheme="tertiary" onClick={onClose}>
						Отменить
					</Button>
					<Button
						size="md"
						variant="solid"
						colorScheme="primary"
						onClick={() => {
							formRef?.current?.submitForm();
						}}
						isDisabled={isCreateProductLoading || isUpdateProductLoading}
					>
						{variant === 'edit' ? 'Сохранить' : 'Добавить деталь'}
					</Button>
				</ButtonGroup>
			}
			onBlur={onBlur ?? onClose}
		>
			<ProductCatalogForm
				onSubmit={handleSubmit}
				reference={formRef}
				selectedProduct={selectedProduct}
				oper={operations}
				setOperationType={setOperationType}
			/>
		</SideModal>
	);
};
