import type { FC } from 'react';
import type { LinkTechCardModalProps } from './link-tech-card-modal.types';

import { useCallback, useRef, useState } from 'react';
import { Button, ButtonGroup, useToast } from '@chakra-ui-kraud/react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import {
	ProductTechMapLinkedRequestWithNewCreate,
	useCreateLinkedTechMapWithNewMutation,
	StandardConsumptionTypeEnum,
	useCreateLinkedTechMapWithExistedMutation,
	ProductTechMapLinkedRequestWithExistedCreate,
} from '@/shared/state/api/swagger';
import { LinkTechCardForm } from '@/entities';
import { LINK_TO_TECH_CARD_INFO, STANDARD_CONSUMPTION_TYPES } from '@/shared';
import { Loader, SideModal, SubmitModal } from '@/shared/components';
import { showErrorToast, showSuccessToast } from '@/shared/components/toasts';

export const LinkTechCardModal: FC<LinkTechCardModalProps> = ({
	isOpen,
	title,
	onClose,
	onBlur,
	cardInfo,
	employee,
	variant,
}) => {
	const formRef = useRef<HTMLFormElement>();
	const navigate = useNavigate();
	const toast = useToast();
	const [submitModal, setSubmitModal] = useState<null | {
		error: {
			remainder: string;
			standardConsumptionType: StandardConsumptionTypeEnum;
		};
		data: ProductTechMapLinkedRequestWithNewCreate;
	}>(null);

	const [isLoading, setIsLoading] = useState(false);

	const [linkWithNewCard, { isLoading: isLinkWithNewMkLoading }] = useCreateLinkedTechMapWithNewMutation();
	const [createLinkedTechMapWithExisted, { isLoading: isLinkWithExistedMkLoading }] =
		useCreateLinkedTechMapWithExistedMutation();

	const {
		setError,
		formState: { errors },
		clearErrors,
	} = useForm<ProductTechMapLinkedRequestWithNewCreate | ProductTechMapLinkedRequestWithExistedCreate>({});

	const handleSubmit = useCallback(
		({
			data,
			force = false,
		}: {
			data: ProductTechMapLinkedRequestWithNewCreate | ProductTechMapLinkedRequestWithExistedCreate;
			force: boolean;
		}) => {
			if (variant === 'create') {
				setIsLoading(true);
				// связь с созданием новой карты
				linkWithNewCard({
					productTechMapLinkedRequestWithNewCreate: { ...data } as ProductTechMapLinkedRequestWithNewCreate,
					'x-disable-catalog-num-remainder-check': force ? 'true' : undefined,
				})
					.unwrap()
					.then((res) => {
						if (res.number) {
							navigate(LINK_TO_TECH_CARD_INFO(res.number));
							showSuccessToast(toast, { description: 'Связь с новой картой успешно создана' });
							window.location.reload();
						}
					})
					.catch((err) => {
						if (err.status === 479) {
							setSubmitModal({
								error: {
									remainder: err?.data?.detail?.remainder,
									standardConsumptionType: err?.data?.detail?.standard_consumption_type,
								},
								data: data as ProductTechMapLinkedRequestWithNewCreate,
							});
						} else if (err.status === 475) {
							showErrorToast(toast, {
								description: 'В текущей карте отсутствует значение в "ИТОГО Расход материала, м"',
							});
						} else {
							showErrorToast(toast, { description: 'Не удалось создать связь с новой картой' });
						}
					})
					.finally(() => {
						setIsLoading(false);
						onClose();
					});
			} else {
				// сязь с существующей картой
				// createLinkedTechMapWithExisted(data as ILinkExistedTechCardData)
				createLinkedTechMapWithExisted({
					productTechMapLinkedRequestWithExistedCreate: data as ProductTechMapLinkedRequestWithExistedCreate,
				})
					.unwrap()
					.then((res) => {
						res.number && navigate(LINK_TO_TECH_CARD_INFO(res.number));
						showSuccessToast(toast, { description: `Связь с картой №${res.number} успешно создана` });
						window.location.reload();
						onClose();
					})
					.catch((err) => {
						if (err.status === 422) {
							showErrorToast(toast, {
								description: 'Некорректное количество грамм/штук',
							});
							return;
						}

						if (err.status === 473) {
							showErrorToast(toast, {
								description: 'У выбранной МК нет текущей операции или статус выбранной МК не "В работе',
							});

							onClose();

							return;
						}
						if (err.status === 474) {
							setError('children_product_tech_map_id', {
								message: 'Вы не можете создать связь с этой МК, потому что детали пришли из неё',
							});
							return;
						}
						showErrorToast(toast, { description: 'Не удалось создать связь c картой' });
					});
			}
		},
		[variant],
	);

	return !isLoading ? (
		<>
			<SideModal
				title={title}
				isOpen={isOpen}
				onClose={onClose}
				maxWidth="485px"
				header={null}
				footer={
					<ButtonGroup>
						<Button
							size="md"
							variant="ghost"
							colorScheme="tertiary"
							onClick={() => {
								onClose();
							}}
						>
							Отменить
						</Button>

						<Button
							size="md"
							variant="solid"
							colorScheme="primary"
							onClick={() => {
								formRef?.current && formRef?.current?.submitForm();
							}}
							isDisabled={isLinkWithNewMkLoading || isLinkWithExistedMkLoading}
						>
							Создать
						</Button>
					</ButtonGroup>
				}
				onBlur={onBlur ?? onClose}
			>
				<LinkTechCardForm
					cardInfo={cardInfo}
					employee={employee}
					reference={formRef}
					onSubmit={handleSubmit}
					variant={variant}
					tooltipErrorContent={errors}
					resetTooltipErrors={clearErrors}
				/>
			</SideModal>
			{submitModal && (
				<SubmitModal
					isOpen={!!submitModal}
					onClose={() => setSubmitModal(null)}
					onSubmit={() => handleSubmit({ data: submitModal.data, force: true })}
					text={`Сейчас на складе ${submitModal.error.remainder} ${
						STANDARD_CONSUMPTION_TYPES[submitModal.error.standardConsumptionType]
					}. материала. Вы уверены, что хотите создать карту с таким материалом?`}
					closeBtnText="Изменить материал"
					submitBtnText="Создать карту"
				/>
			)}
		</>
	) : (
		<Loader />
	);
};
