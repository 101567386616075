import type { FC } from 'react';
import { useEffect, useRef, useState, useCallback } from 'react';
import debounce from 'lodash/debounce';

import type { CommonTableProps } from './common-table.types';

import { Table, TableProps } from 'antd';

import styles from './common-table.module.scss';
import clsx from 'clsx';
import { TablesFooter } from '@/shared/components/tables-footer';

const headerClass = 'table-header';

export const CommonTable: FC<CommonTableProps> = ({
	columns,
	data,
	onChange,
	superHeader,
	rowKey = 'id',
	...footerProps
}) => {
	const table = useRef<HTMLDivElement>(null);

	// тут можно определять по индексу так как нам нужен только порядок елемента
	const [hoveredHeader, setHoveredHeader] = useState<number | null>(null);
	const [scroll, setScroll] = useState<TableProps<any>['scroll']>({});

	const calculateScroll = useCallback(
		debounce(() => {
			// это main
			const parent = table.current?.parentElement;

			// это хедер таблицы
			const header = table.current?.getElementsByClassName(headerClass)[0];

			const parentBounds = parent?.getBoundingClientRect();

			const headerBounds = header?.getBoundingClientRect();

			if (parent && parentBounds && headerBounds) {
				// высота других элементов в родителе (футер)
				const otherElementsHeight = Array.from(parent?.children ?? [])
					.filter((el) => el !== table.current)
					.reduce((acc, curr) => {
						const height = curr.getBoundingClientRect().height ?? 0;
						const elementStyles = window.getComputedStyle(curr);
						const margins = Number(elementStyles.marginBottom.replace(/[^\d]/gm, ''));

						return acc + height + margins;
					}, 0);

				// Стили родителя для учтета паддингов
				const parentStyle = window.getComputedStyle(parent);

				setScroll((prev) => ({
					...prev,
					x: 'max-content',
					y:
						parentBounds.height -
						headerBounds.height -
						otherElementsHeight -
						Number(parentStyle.paddingTop.replace(/[^\d]/gm, '')) -
						Number(parentStyle.paddingBottom.replace(/[^\d]/gm, '')),
				}));
			}
		}, 120),
		[],
	);

	useEffect(() => {
		if (table.current) {
			const windowResizeObserver = new ResizeObserver(calculateScroll);

			windowResizeObserver.observe(window.document.body);

			return () => {
				windowResizeObserver.disconnect();
			};
		}
	}, [table]);

	// выглядит как шляпа, но как сделать иначе идей особо нет
	const updatedColumns = columns.map((column, idx) => {
		if (typeof column.title === 'string') {
			column.title = column.title.replace(',', '/');
		}
		return {
			...column,
			// если наведены на столбец – ставим его ховер
			// если у столбца был класс – добавлем его обратно
			className: clsx(column.className, idx === hoveredHeader ? styles['column-hovered'] : undefined),
		};
	});

	const onHeaderRow = useCallback<Exclude<TableProps<typeof data>['onHeaderRow'], undefined>>(
		() => ({
			className: headerClass,
			onMouseOver: (e) => {
				// проходимся по всех елеметам которые задел евент
				for (const element of (e.nativeEvent as MouseEvent).composedPath()) {
					if ((element as HTMLElement).tagName !== 'TH') {
						// скипаем все, что не th, как правло th на 1-2 месте
						continue;
					} else {
						const header = table.current?.getElementsByClassName(headerClass)[0];
						if (header) {
							header.childNodes.forEach((el, idx) => {
								if (el === element) {
									// устаналиваем текущий елемент
									setHoveredHeader(idx);
								}
							});
						}
						// выходим из цикла, чтобы не проверять лишнее
						break;
					}
				}
			},
			onMouseLeave: () => {
				setHoveredHeader(null);
			},
		}),
		[],
	);

	return (
		<div className={styles['wrapper']}>
			{superHeader}
			<Table
				ref={table}
				onHeaderRow={onHeaderRow}
				onChange={onChange}
				rowKey={(row) => row[rowKey]}
				className={styles['table']}
				columns={updatedColumns}
				dataSource={data}
				pagination={false}
				style={{ fontFamily: 'monospace' }}
				showSorterTooltip={false}
				scroll={scroll}
			/>
			{footerProps?.total && footerProps.total > 0 ? <TablesFooter {...footerProps} /> : null}
		</div>
	);
};
