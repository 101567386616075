import { DefaultOptionType } from 'antd/es/select';

export const convertToOptions = <T extends { [key: string]: string | number | undefined }>(
	array: T[] | undefined,
	searchValue: string | undefined,
	settings: { key: keyof T },
) => {
	let isSearchFind = false;

	const options: DefaultOptionType[] =
		array?.map((el) => {
			if (!isSearchFind && el.name === searchValue) {
				isSearchFind = true;
			}

			return {
				label: el[settings.key] as string,
				value: el[settings.key] as string,
				id: el.id,
			};
		}) ?? [];

	if (!isSearchFind && searchValue) {
		options.push({ label: searchValue, value: searchValue, id: -1 });
	}

	return options;
};
