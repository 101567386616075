import { MAP_STATUS_TO_NAME } from '@/entities/tech-map';
import {
	DEFAULT_YEAR_FORMAT,
	LINK_TO_TECH_CARD_ASSEMBLY_INFO,
	LINK_TO_TECH_CARD_INFO,
	LINK_TO_TECH_CARD_PREFORM_INFO,
	TechCardStatusColors,
	TechCardStatuses,
} from '@/shared';
import { CellTableParagraph } from '@/shared/components';
import { formatCurrentOperation } from '@/shared/core/utils/tech-card-utils';
import { TechMapForAll } from '@/shared/state/api/swagger';
import { Badge } from '@chakra-ui-kraud/react';
import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import styles from '../tech-card-table.module.scss';

export const TECH_MAP_ALL_COLUMNS = (
	onHeaderCell: ColumnsType<TechMapForAll>[number]['onHeaderCell'],
): ColumnsType<TechMapForAll> => [
	{
		key: 'group',
		dataIndex: 'group',
		title: 'Группа',
		align: 'left',
		className: styles['group'],
		sorter: false,
		sortDirections: ['ascend', 'descend'],
		onHeaderCell,
		render: (value) => <CellTableParagraph value={value} />,
		fixed: 'left',
	},
	{
		key: 'number',
		title: 'Номер карты',
		dataIndex: 'number',
		align: 'left',
		className: styles['number'],
		sorter: true,
		sortDirections: ['ascend', 'descend'],
		onHeaderCell,
		render: (value, row) => {
			const routes: Record<string, (cardNumber: number) => string> = {
				product_tech_map: LINK_TO_TECH_CARD_INFO,
				assembly_tech_map: LINK_TO_TECH_CARD_ASSEMBLY_INFO,
				preform_tech_map: LINK_TO_TECH_CARD_PREFORM_INFO,
			};

			return (
				<Link color="red" className={styles.link} to={routes[row.type](value)}>
					<CellTableParagraph value={value} />
				</Link>
			);
		},
		fixed: 'left',
	},
	{
		key: `type`,
		dataIndex: `type`,
		title: 'Тип карты',
		sorter: true,
		className: styles['type'],
		sortDirections: ['ascend', 'descend'],
		onHeaderCell,
		render: (value) => {
			const labels: Record<string, string> = {
				product_tech_map: 'На деталь',
				assembly_tech_map: 'Сборочная',
				preform_tech_map: 'Заготовительная',
			};

			return <CellTableParagraph value={labels[value]} />;
		},
	},
	{
		key: `symbol`,
		dataIndex: `symbol`,
		title: 'Обозначение',
		sorter: true,
		className: styles['labeling'],
		sortDirections: ['ascend', 'descend'],
		onHeaderCell,
		render: (value) => <CellTableParagraph value={value} />,
	},
	{
		key: `name`,
		dataIndex: `name`,
		title: 'Наименование',
		className: styles['naming'],
		sorter: true,
		sortDirections: ['ascend', 'descend'],
		onHeaderCell,
		render: (value) => {
			return <CellTableParagraph value={value} />;
		},
	},
	{
		key: 'num',
		dataIndex: 'num',
		title: 'НУМ',
		className: styles['preform_num'],
		sortDirections: ['ascend', 'descend'],
		onHeaderCell,
		render: (value) => <CellTableParagraph value={value} />,
	},
	{
		key: `current_operation_name`,
		dataIndex: `current_operation_name`,
		title: 'Текущая операция',
		className: styles['current_operation'],
		sorter: true,
		sortDirections: ['ascend', 'descend'],
		onHeaderCell,
		render: (_, row) => {
			return (
				<CellTableParagraph
					value={formatCurrentOperation(row?.current_operation_name, row?.current_operation_step_id)}
				/>
			);
		},
	},
	{
		key: 'status',
		dataIndex: 'status',
		title: 'Статус',
		sorter: true,
		className: styles['status'],
		sortDirections: ['ascend', 'descend'],
		onHeaderCell,
		render: (value: TechCardStatuses) => (
			<Badge
				variant={'solid'}
				background={TechCardStatusColors[value]}
				borderRadius="6px"
				textTransform="initial"
				fontFamily="Raleway"
				lineHeight="16px"
				fontWeight={600}
				padding="4px 8px 4px 8px"
				style={{ width: 'fit-content' }}
			>
				<CellTableParagraph value={MAP_STATUS_TO_NAME[value]} />
			</Badge>
		),
	},

	{
		key: 'created_at',
		dataIndex: 'created_at',
		title: 'Дата создания',
		align: 'left',
		sorter: true,
		className: styles['created-at'],
		sortDirections: ['ascend', 'descend'],
		onHeaderCell,
		render: (value) => <CellTableParagraph value={dayjs(value).format(DEFAULT_YEAR_FORMAT)} textAlign={'right'} />,
	},
	{
		key: 'storage_at',
		dataIndex: 'storage_at',
		title: 'Дата принятия на склад',
		align: 'left',
		sorter: true,
		className: styles['storage-at'],
		sortDirections: ['ascend', 'descend'],
		onHeaderCell,
		render: (value) => (
			<CellTableParagraph
				value={dayjs(value).isValid() ? dayjs(value).format(DEFAULT_YEAR_FORMAT) : undefined}
				textAlign={'right'}
			/>
		),
	},
	{
		key: 'current_details_count',
		dataIndex: 'current_details_count',
		title: 'Годных',
		align: 'left',
		sorter: true,
		className: styles['current-details-count'],
		sortDirections: ['ascend', 'descend'],
		onHeaderCell,
		render: (value) => <CellTableParagraph value={value} textAlign={'right'} />,
	},
	{
		key: 'wasted',
		dataIndex: 'wasted',
		title: 'Брак',
		align: 'left',
		sorter: true,
		className: styles['wasted'],
		sortDirections: ['ascend', 'descend'],
		onHeaderCell,
		render: (value) => <CellTableParagraph value={value && value > 0 ? value : ''} textAlign={'right'} />,
	},
	{
		key: 'remaining_count',
		dataIndex: 'remaining_count',
		title: 'На складе',
		align: 'left',
		sorter: true,
		className: styles['remaining_count'],
		sortDirections: ['ascend', 'descend'],
		onHeaderCell,
		render: (value) => (
			<CellTableParagraph noFormatting value={value === null ? '\u2014' : value} textAlign={'right'} />
		),
	},
];
