import {
	DefaultProductOperationUpdateInOperation,
	ProductOperationDomain,
	ProductOperationUpdate,
	ProductTechMapResponse,
	useUpdateOperationsOperatorMutation,
} from '@/shared/state/api/swagger';
import { sortAllOperations, sortOperations } from '../utils/map-data';

export const useOperationFillForm = () => {
	const [updateOperation] = useUpdateOperationsOperatorMutation();

	const handleCurrentOperation = (data: ProductOperationUpdate, techMap: ProductTechMapResponse) => {
		return new Promise((resolve, reject) => {
			try {
				const { is_canceled, id, created_at, has_link } =
					techMap.current_product_operation as ProductOperationDomain;
				const { default_product_operations, product_operations: operations } = techMap;
				if (!id || !default_product_operations || !operations) return;

				// создаем объект операции с измененными значениями
				const payload: ProductOperationUpdate = { ...data, is_canceled, id, created_at, has_link };

				// вставляем объект операции на место измененного
				const operationsPayload = operations.map((op) =>
					op.id === id ? payload : op,
				) as ProductOperationUpdate[];
				const defaultOperationsPayload = default_product_operations.map((op) =>
					op.id === id ? payload : op,
				) as DefaultProductOperationUpdateInOperation[];

				const { operationsWithCopyFields, defaultOperationsWithCopyFields } = getAllOperationsWithCopyFields(
					payload,
					operationsPayload,
					defaultOperationsPayload,
				);

				updateOperation({
					bodyUpdateOperationsOperator: {
						default_operations_payload: defaultOperationsWithCopyFields,
						operations_payload: operationsWithCopyFields,
					},
				})
					.unwrap()
					.then((res) => {
						resolve(res);
					})
					.catch((err) => {
						reject(err);
					});
			} catch (error) {
				console.error('Update operation error:', error);
				reject(error);
			}
		});
	};

	const handleNotCurrentOperation = (data: ProductOperationUpdate, techMap: ProductTechMapResponse) => {
		return new Promise<ProductOperationDomain[]>((resolve, reject) => {
			try {
				const allOperations = sortAllOperations(techMap);

				// достаем значения предыдущего состояния измененного объекта операции
				const { id, is_canceled, created_at, has_link } = allOperations.find(
					(op) => op.step_id === data.step_id,
				) as ProductOperationDomain;

				const { default_product_operations, product_operations: operations } = techMap;
				if (!id || !default_product_operations || !operations) return;

				// создаем объект операции с измененными значениями
				const payload: ProductOperationUpdate = { ...data, id, is_canceled, created_at, has_link };

				// вставляем объект операции на место измененного
				const operationsPayload = operations.map((op) =>
					op.id === id ? payload : op,
				) as ProductOperationUpdate[];
				const defaultOperationsPayload = default_product_operations.map((op) =>
					op.id === id ? payload : op,
				) as DefaultProductOperationUpdateInOperation[];

				const { operationsWithCopyFields, defaultOperationsWithCopyFields } = getAllOperationsWithCopyFields(
					payload,
					operationsPayload,
					defaultOperationsPayload,
				);

				const allOperationsWithCopyFields = [...operationsWithCopyFields, ...defaultOperationsWithCopyFields];

				const currentOperationIdx = allOperationsWithCopyFields.findIndex(
					(op) => op.step_id === techMap.current_product_operation?.step_id,
				);
				const selectedOperationIdx = allOperationsWithCopyFields.findIndex((op) => op.step_id === data.step_id);
				const skippedOperations = allOperationsWithCopyFields.slice(currentOperationIdx, selectedOperationIdx);

				updateOperation({
					bodyUpdateOperationsOperator: {
						default_operations_payload: defaultOperationsWithCopyFields,
						operations_payload: operationsWithCopyFields,
					},
				})
					.unwrap()
					.then(() => {
						resolve(skippedOperations as ProductOperationDomain[]);
					})
					.catch((err) => {
						reject(err);
					});
			} catch (error) {
				console.error('Update operation error: ', error);
				reject(error);
			}
		});
	};

	return { handleCurrentOperation, handleNotCurrentOperation };
};

// функция для переноса годных гр/шт в поля кол-во гр/шт
const getAllOperationsWithCopyFields = (
	payload: ProductOperationUpdate,
	operationsPayload: ProductOperationUpdate[],
	defaultOperationsPayload: DefaultProductOperationUpdateInOperation[],
) => {
	const operations = sortOperations(operationsPayload);
	const defaultOperations = sortOperations(defaultOperationsPayload);

	let operationsWithCopyFields: ProductOperationUpdate[] = [];
	let defaultOperationsWithCopyFields: DefaultProductOperationUpdateInOperation[] = [];

	const filledCustomOperationIndex = operations.findIndex((op) => op.step_id === payload.step_id);

	if (filledCustomOperationIndex >= 0) {
		if (filledCustomOperationIndex === operations.length - 2) {
			// перенос годных из поля "Контрольная ПСИ" в поля количества "Сдачи на СГД"
			// предпоследняя кастомная операция  -> первая дефолтная операция
			operationsWithCopyFields = operations;
			defaultOperationsWithCopyFields = defaultOperations.map((op, index) => {
				return index === defaultOperations.length - 2 && !op.is_canceled
					? {
							...op,
							count_in_gram: payload.count_out_gram,
							count_in_number: payload.count_out_number,
					  }
					: op;
			});
		} else if (filledCustomOperationIndex === operations.length - 1) {
			// переносй годных из поля "Консервации" в поля количества "Приёмка на СГД"
			// последняя кастомная операция -> вторая дефолтная операция
			operationsWithCopyFields = operations;
			defaultOperationsWithCopyFields = defaultOperations.map((op, index) => {
				return index === defaultOperations.length - 1 && !op.is_canceled
					? {
							...op,
							count_in_gram: payload.count_out_gram,
							count_in_number: payload.count_out_number,
					  }
					: op;
			});
		} else {
			// если кастомная операция, то заполняем поля следующей неотмененной операции
			operationsWithCopyFields = (operationsWithCopyFields.length ? operationsWithCopyFields : operations).reduce(
				(acc: { result: ProductOperationUpdate[]; replaced: boolean }, op, index) => {
					if (index <= filledCustomOperationIndex) {
						acc.result.push(op);
					} else {
						if (!acc.replaced && !op.is_canceled) {
							acc.result.push({
								...op,
								count_in_gram: payload.count_out_gram ?? undefined,
								count_in_number: payload.count_out_number ?? undefined,
							});
							acc.replaced = true;
						} else {
							acc.result.push(op);
						}
					}
					return acc;
				},
				{ result: [], replaced: false },
			).result;

			defaultOperationsWithCopyFields = defaultOperationsWithCopyFields.length
				? defaultOperationsWithCopyFields
				: defaultOperations;
		}
	} else {
		// перенос значения из предпоследней дефолтной операции в последнюю кастомную
		// поля  Сдача на СГД -> раздел Кол-во, Консервация
		defaultOperationsWithCopyFields = defaultOperations;
		operationsWithCopyFields = operations.map((op, index) => {
			return index === operations.length - 1 &&
				payload.step_id === defaultOperationsWithCopyFields[0].step_id &&
				!op.is_canceled
				? {
						...op,
						count_in_gram: payload.count_out_gram,
						count_in_number: payload.count_out_number,
				  }
				: op;
		});
	}

	return { operationsWithCopyFields, defaultOperationsWithCopyFields };
};
