import { forwardRef, useEffect, useState } from 'react';
import styles from './main.module.scss';
import { Flex, Input, Table, Td, Th, Tr, Text } from '@chakra-ui-kraud/react';
import { Controller, useFormContext } from 'react-hook-form';
import { Select } from 'antd';
import {
	useGetAssembliesQuery,
	type CustomAssemblyResponse,
	type AssemblyTechMapProductTechMapResponse,
	AssemblyOperationResponse,
	useGetEmployeeInitialsQuery,
} from '@/shared/state/api/swagger';
import { NotFoundContentDropdown, useAppSelector, UserRoles, useTechCard } from '@/shared';
import _debounce from 'lodash/debounce';
import { selectAssemblyNamingOptions } from '@/entities/assembly/model/slice';
import { AssemblyConfig } from '../../form-assembly-config';
import { convertNumberToNumberStringWithComma } from '@/shared/core/utils/convert-string-to-number-string';
import clsx from 'clsx';
import { MainBlockAssemblyTypes } from './main.types';

export const MainBlockAssembly = forwardRef<HTMLDivElement, MainBlockAssemblyTypes>(({ isEditable }, ref) => {
	const userRole = useAppSelector((state) => state.auth.userProfile?.role);

	const { isCreationMode, assemblyCard } = useTechCard();
	const { control, setValue, trigger, formState } = useFormContext<AssemblyConfig>();
	const [assembliesQuery, setAssembliesQuery] = useState('');
	const debouncedAssembliesSearch = _debounce((search) => setAssembliesQuery(search), 700);

	useEffect(() => {
		if (isCreationMode) trigger();
	}, [isCreationMode, trigger]);

	const { data: employee } = useGetEmployeeInitialsQuery();
	const employeeForSelect = employee?.map((el) => ({
		value: el.id,
		label: el.initials,
	}));

	const {
		data: assembliesData,
		assembliesOptions,
		isFetching: isAssembliesFetching,
	} = useGetAssembliesQuery(
		{
			stringToSearch: assembliesQuery,
		},
		{
			selectFromResult: (result) => {
				return {
					...result,
					assembliesOptions: selectAssemblyNamingOptions(result?.data?.payload),
				};
			},
		},
	);
	const { payload: assemblies } = assembliesData || {};
	const handleAssemblySelect = (value: string | number) => {
		setValue('assembly_operations', []);
		const selectedAssembly = assemblies?.find((assembly) => assembly.id === Number(value));

		if (selectedAssembly) {
			setValue(
				'assembly',
				{
					...selectedAssembly,
					name: selectedAssembly.name,
					symbol: selectedAssembly.symbol,
				} as CustomAssemblyResponse,
				{ shouldValidate: true },
			);
			setValue(
				'assembly_products',
				[...(selectedAssembly.products || [])] as AssemblyTechMapProductTechMapResponse[],
				{ shouldValidate: true },
			);

			const complectOperations = selectedAssembly?.products?.map((product) => ({
				name: product?.product_name ? `Комплектовочная ${product?.product_name}` : '',
				step_id: 5,
			})) as AssemblyOperationResponse[];

			const operations = [
				...complectOperations,
				...(selectedAssembly.operation_types || []),
			] as AssemblyOperationResponse[];
			setValue('assembly_operations', operations, { shouldValidate: true });

			const sortedOperationsTypes = operations?.sort((a, b) => (a.step_id ?? 0) - (b.step_id ?? 0));

			sortedOperationsTypes?.forEach((operation, index) => {
				setValue(`assembly_operations.${index}.step_id`, operation.step_id ?? 0);
				setValue(`assembly_operations.${index}.name`, operation.name || '-');
			});
		}
	};

	return (
		<Flex className={clsx(styles['main-block'], styles['main-block__assembly'])}>
			<Table
				sx={{
					tr: {
						height: '24px',
					},
				}}
			>
				<Tr className={styles['main-block__header']}>
					{/* значение ширины колонки "Маршрутная карта" подобрано вручную для предпросмотра печати */}
					<Th minW={'160px'} maxW="360px" className={styles['without_border_bottom']}>
						Обозначение
					</Th>
					<Th minW={'160px'} maxW={'360px'} className={styles['without_border_bottom']}>
						Наименование
					</Th>
					<Th minW={'160px'} maxW={'360px'} className={styles['without_border_bottom']}>
						КС / ИИ
					</Th>
					<Th minW={'160px'} maxW={'360px'} className={styles['without_border_bottom']}>
						Техническое решение
					</Th>
					<Th minW={'160px'} maxW={'360px'} className={clsx(styles['without_border_bottom'], styles['plan'])}>
						План шт.
					</Th>
					<Th minW={'160px'} maxW={'360px'} className={styles['without_border_bottom']}>
						Подпись
					</Th>
					<Th minW={'160px'} maxW={'360px'} className={styles['without_border_bottom']}>
						ФИО ПДС
					</Th>
				</Tr>
				<Tr>
					<Td textAlign={'left'}>
						{isEditable && isCreationMode ? (
							<Controller
								name="assembly.symbol"
								control={control}
								render={({ field: { onChange, value }, fieldState: { error } }) => {
									return (
										<Select
											status={'error'}
											tabIndex={1}
											showSearch
											placeholder="Поиск..."
											optionFilterProp="children"
											onChange={(e) => {
												onChange(e);
												handleAssemblySelect(e);
											}}
											filterOption={() => true}
											onSearch={(search) => debouncedAssembliesSearch(search)}
											value={value}
											options={assembliesOptions}
											className={styles['custom-select']}
											style={{
												width: '100%',
												// maxWidth: '280px',
												boxShadow: error?.message ? '0 0 0 1px #fc5e6e' : '',
											}}
											notFoundContent={
												<NotFoundContentDropdown
													isFetchingNaming={isAssembliesFetching}
													alertName={'Введите обозначение'}
												/>
											}
										/>
									);
								}}
							/>
						) : (
							<Text className={clsx(styles['text-in-cell'], styles['print_bold_font'])}>
								{assemblyCard?.assembly?.symbol}
							</Text>
						)}
					</Td>
					<Td>
						{isEditable ? (
							<Controller
								name="assembly.name"
								control={control}
								render={({ field: { onChange, value } }) => (
									<Input
										backgroundColor="transparent"
										isReadOnly={userRole === UserRoles.operator}
										className={userRole === UserRoles.operator ? styles['input-readonly'] : ''}
										tabIndex={2}
										onChange={onChange}
										value={value}
										name="assembly.name"
									/>
								)}
							/>
						) : (
							<Text className={clsx(styles['text-in-cell'], styles['print_bold_font'])}>
								{assemblyCard?.assembly?.name}
							</Text>
						)}
					</Td>
					<Td>
						{isEditable ? (
							<Controller
								name="approval_card"
								control={control}
								render={({ field: { onChange, value } }) => (
									<Input
										backgroundColor="transparent"
										tabIndex={2}
										onChange={onChange}
										value={value}
										name="approval_card"
									/>
								)}
							/>
						) : (
							<Text className={clsx(styles['text-in-cell'], styles['print_bold_font'])}>
								{assemblyCard?.approval_card}
							</Text>
						)}
					</Td>
					<Td>
						{isEditable ? (
							<Controller
								name="tech_decision"
								control={control}
								render={({ field: { onChange, value }, fieldState: { error } }) => (
									<Input
										isInvalid={!!error}
										onChange={onChange}
										value={convertNumberToNumberStringWithComma(
											String(value).replace(/[^0-9,.хx]/g, ''),
										)}
										name="tech_decision"
										backgroundColor="transparent"
									/>
								)}
							/>
						) : (
							<Text className={clsx(styles['text-in-cell'], styles['print_bold_font'])}>
								{assemblyCard?.tech_decision}
							</Text>
						)}
					</Td>
					<Td>
						{isEditable ? (
							<Controller
								name="plan"
								control={control}
								render={({ field: { onChange, value }, fieldState: { error } }) => (
									<Input
										tabIndex={5}
										isInvalid={!!error}
										onChange={onChange}
										value={convertNumberToNumberStringWithComma(
											String(value).replace(/[^0-9,.хx]/g, ''),
										)}
										name="plan"
										backgroundColor="transparent"
									/>
								)}
							/>
						) : (
							<Text className={clsx(styles['text-in-cell'], styles['print_bold_font'])}>
								{assemblyCard?.plan}
							</Text>
						)}
					</Td>
					<Td></Td>
					<Td>
						{isEditable ? (
							<Controller
								name="fio_pds"
								control={control}
								render={({ field: { value, onChange } }) => (
									<Select
										allowClear
										tabIndex={5}
										filterOption={(input, option) =>
											(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
										}
										showSearch
										placeholder="Выберите ФИО"
										optionFilterProp="children"
										onChange={(e) => {
											onChange(e);
											setValue(
												'fio_pds',
												e ? employeeForSelect?.find((el) => el.value === Number(e))?.label : '',
											);
										}}
										value={value}
										options={employeeForSelect}
										style={{ whiteSpace: 'nowrap' }}
										className={styles['custom-select']}
									/>
								)}
							/>
						) : (
							<Text className={clsx(styles['text-in-cell'], styles['print_bold_font'])}>
								{assemblyCard?.fio_pds}
							</Text>
						)}
					</Td>
				</Tr>
			</Table>
		</Flex>
	);
});
