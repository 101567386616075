import { pirntAreaBlockId, TechCardStatuses, useAppSelector, UserRoles, useTechCard } from '@/shared';
import { Flex } from '@chakra-ui-kraud/react';
import styles from './tech-card.module.scss';

import { forwardRef, useEffect, useMemo } from 'react';
import { MainBlockPreform } from './blocks';
import { TechCardPreformProps } from './tech-card.types';
import { PlanPreform } from './blocks/plan/plan-preform';
import { NumBlock } from './blocks/num/num-block';
import { useFormContext } from 'react-hook-form';
import { OperationsPreformBlock } from './blocks/operations/custom-operations/operations-preform';
import { useGetPreformTechMapByNumberQuery } from '@/shared/state/api/swagger';
import { useLocation, useParams } from 'react-router-dom';
import { SgdPreformBlock } from './blocks/SGD/sgd-preform';
import { useFocusedBlock } from './lib/use-focused-block';

export const TechCardPreform = forwardRef<HTMLDivElement, TechCardPreformProps>(({ isEditable, isPrinting }, ref) => {
	const location = useLocation();
	const focusedBlock = useMemo(
		() => (location.state?.focusedBlock ? location.state.focusedBlock : null),
		[location?.state?.focusedBlock],
	);
	const { focused, handleFocus } = useFocusedBlock({ isEditable, focusedBlock });
	const { isCreationMode } = useTechCard();
	const { trigger } = useFormContext();
	const userRole = useAppSelector((state) => state.auth.userProfile?.role);

	const mapNumber = Number(useParams().number);
	const { data: preformMap } = useGetPreformTechMapByNumberQuery(
		{ preformTechMapNumber: mapNumber },
		{
			skip: !mapNumber || Number.isNaN(mapNumber),
		},
	);
	useEffect(() => {
		if (isCreationMode) trigger();
	}, [isCreationMode, trigger]);

	return (
		<div className={styles['tech-card']} id={pirntAreaBlockId} ref={ref}>
			<Flex gap="9px" flexDir={'column'} className={styles['main_flex']}>
				<Flex gap={'9px'} alignItems="stretch">
					<MainBlockPreform isEditable={isEditable} isPrinting={isPrinting} />
					<PlanPreform isEditable={isEditable} isPrinting={isPrinting} />
				</Flex>
				<NumBlock isEditable={isEditable} />
			</Flex>
			<Flex flexDir="column" className={styles['operations_sgd_flex']}>
				<OperationsPreformBlock
					isEditable={
						isEditable &&
						!!preformMap &&
						(preformMap.status === TechCardStatuses.progress || userRole === UserRoles.admin)
					}
				/>
				<SgdPreformBlock
					isEditable={
						isEditable &&
						!!preformMap &&
						(preformMap.status === TechCardStatuses.accepted_at_storage ||
							preformMap.status === TechCardStatuses.partially_issued ||
							userRole === UserRoles.admin)
					}
					isPrinting={isPrinting}
					focusedBlock={focused}
					setFocusedBlock={focused.block === 'select_date_conservation' ? handleFocus : undefined}
				/>
			</Flex>
		</div>
	);
});
