import { FC, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';

import { UserChangePasswordModalProps } from './user-change-password-modal.types';
import { IChangePassword } from '@/shared/core';
import { useChangePasswordMutation } from '@/shared/state/api';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { SideModal } from '../../shared/components/side-modal';
import { CustomInput } from '@/shared/components/custom-input';
import { showErrorToast, showSuccessToast } from '@/shared/components/toasts';
import { Button, Text, useToast, UnorderedList, ListItem, ButtonGroup } from '@chakra-ui-kraud/react';

import styles from './user-change-password-modal.module.scss';
import { MadIcon } from 'madsoft-icons';

export const UserChangePasswordModal: FC<UserChangePasswordModalProps> = ({ isOpen, onClose }) => {
	const [oldPassword, showOldPassword] = useState('password');
	const [newPassword, showNewPassword] = useState('password');
	const [newPasswordRepeat, showNewPasswordRepeat] = useState('password');

	const [isRegisterSucces, setRegisterSucces] = useState<boolean>(false);
	const [isNumberSucces, setNumberSucces] = useState<boolean>(false);
	const [isSymbolSucces, setSymbolSucces] = useState<boolean>(false);

	const scheme = yup.object({
		old_password: yup.string().required('Обязательное поле'),
		new_password: yup
			.string()
			.required('Обязательное поле')
			.min(6, 'Минимальная длина 6 символов')
			//  Длина не менее 6 символов.
			//  Символы в разных регистрах (A-z).
			//  Цифры (0-9)
			//  Знаки препинания и/или специальные символы (!,@;%$^).
			.matches(
				/^.*(?=.{5,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
				'Пароль не подходит',
			),
		new_password_repeat: yup
			.string()
			.required('Обязательное поле')
			.oneOf([yup.ref('new_password')], 'Пароль не совпадает'),
	});
	const {
		control,
		handleSubmit,
		setError,
		formState: { errors, isDirty, isValid, dirtyFields },
	} = useForm<IChangePassword>({
		resolver: yupResolver(scheme),
	});

	const { new_password } = useWatch({ control });

	useEffect(() => {
		setRegisterSucces(/(?=.*[A-Z])(?=.*[a-z])/.test(String(new_password)));
		setNumberSucces(/(?=.*[0-9])/.test(String(new_password)));
		setSymbolSucces(/(?=.*[!@#$%^&*,.])/.test(String(new_password)));
	}, [new_password]);

	const [changePassword] = useChangePasswordMutation();
	const toast = useToast();
	const onSubmit = (data: IChangePassword) => {
		if (data.old_password === data.new_password) {
			showErrorToast(toast, { description: 'Текущий и новый пароли совпадают' });
		} else {
			changePassword(data)
				.unwrap()
				.then(() => {
					showSuccessToast(toast, { description: 'Пароль изменен' });
					onClose();
				})
				.catch((e) => {
					if (e.status === 422) {
						showErrorToast(toast, { description: 'Текущий пароль неверен' });
						setError('old_password', { message: 'Текущий пароль неверен' }, { shouldFocus: true });
					} else {
						showErrorToast(toast, { description: 'При изменении пароля произошла ошибка' });
					}
				});
		}
	};
	const formRef = useRef<{ submitForm: () => void }>(null);

	useImperativeHandle(formRef, () => ({
		submitForm() {
			handleSubmit(onSubmit)();
		},
	}));

	return (
		<SideModal
			footer={
				<ButtonGroup>
					<Button size="md" variant="ghost" colorScheme="tertiary" onClick={onClose}>
						Отменить
					</Button>
					<Button
						isDisabled={!isValid || !isDirty}
						size="md"
						variant="solid"
						colorScheme="primary"
						onClick={() => formRef?.current?.submitForm()}
					>
						Сменить
					</Button>
				</ButtonGroup>
			}
			isOpen={isOpen}
			onClose={onClose}
			title="Смена пароля"
		>
			<form onSubmit={handleSubmit(onSubmit)} className={styles['form']}>
				<label style={{ position: 'relative' }}>
					<Controller
						control={control}
						name="old_password"
						render={({ fieldState: { error }, field: { value, name, onChange, onBlur } }) => (
							<CustomInput
								type={oldPassword}
								isInvalid={!!error?.message}
								value={value}
								name={name}
								onBlur={onBlur}
								onChange={onChange}
								label="Текущий пароль"
								rightIcon={
									<MadIcon
										module="basic"
										type="outline"
										size="default"
										mode="default"
										name={oldPassword === 'password' ? 'eye' : 'eye-slash'}
										onClick={() =>
											showOldPassword((val) => (val === 'password' ? 'text' : 'password'))
										}
									/>
								}
								tooltipContent={errors.old_password?.message}
								showTooltip={!!errors.old_password?.message?.length && dirtyFields.old_password}
							/>
						)}
					/>
				</label>
				<label>
					<Controller
						control={control}
						name="new_password"
						render={({ field, fieldState: { error } }) => (
							<CustomInput
								type={newPassword}
								{...field}
								isInvalid={!!error?.message}
								label="Новый пароль"
								tooltipContent={errors.new_password?.message}
								showTooltip={
									(!!errors.new_password?.message?.length && dirtyFields.new_password) || !isDirty
								}
								rightIcon={
									<MadIcon
										module="basic"
										type="outline"
										size="default"
										mode="default"
										name={newPassword === 'password' ? 'eye' : 'eye-slash'}
										onClick={() =>
											showNewPassword((val) => (val === 'password' ? 'text' : 'password'))
										}
									/>
								}
							/>
						)}
					/>
				</label>
				<label>
					<Controller
						control={control}
						name="new_password_repeat"
						render={({ field, fieldState: { error } }) => (
							<CustomInput
								type={newPasswordRepeat}
								{...field}
								isInvalid={!!error?.message}
								label="Повторите новый пароль"
								tooltipContent={errors.new_password_repeat?.message}
								showTooltip={
									(!!errors.new_password_repeat?.message?.length &&
										dirtyFields.new_password_repeat) ||
									!isDirty
								}
								rightIcon={
									<MadIcon
										module="basic"
										type="outline"
										size="default"
										mode="default"
										name={newPasswordRepeat === 'password' ? 'eye' : 'eye-slash'}
										onClick={() =>
											showNewPasswordRepeat((val) => (val === 'password' ? 'text' : 'password'))
										}
									/>
								}
							/>
						)}
					/>
				</label>
			</form>
			<Text
				style={{ fontSize: '12px', lineHeight: '16px', fontWeight: '600', marginBottom: '8px' }}
				color="gray.500"
			>
				Пароль должен быть:
			</Text>
			<UnorderedList className={styles['list']}>
				<ListItem
					className={
						new_password
							? new_password.length > 5
								? styles['list__marker-success']
								: styles['list__marker-danger']
							: styles['list__marker-gray']
					}
				>
					<Text color={new_password ? (new_password.length > 5 ? 'success.400' : 'danger.400') : 'gray.500'}>
						Длиной не менее 6 символов.
					</Text>
				</ListItem>
				<ListItem
					className={
						new_password
							? isRegisterSucces
								? styles['list__marker-success']
								: styles['list__marker-danger']
							: styles['list__marker-gray']
					}
				>
					<Text color={new_password ? (isRegisterSucces ? 'success.400' : 'danger.400') : 'gray.500'}>
						Содержать символы в разных регистрах (A-z).
					</Text>
				</ListItem>
				<ListItem
					className={
						new_password
							? isNumberSucces
								? styles['list__marker-success']
								: styles['list__marker-danger']
							: styles['list__marker-gray']
					}
				>
					<Text color={new_password ? (isNumberSucces ? 'success.400' : 'danger.400') : 'gray.500'}>
						Включать цифры (0-9).
					</Text>
				</ListItem>
				<ListItem
					className={
						new_password
							? isSymbolSucces
								? styles['list__marker-success']
								: styles['list__marker-danger']
							: styles['list__marker-gray']
					}
				>
					<Text color={new_password ? (isSymbolSucces ? 'success.400' : 'danger.400') : 'gray.500'}>
						Включать знаки препинания и/или специальные символы (!,@;%$^).
					</Text>
				</ListItem>
			</UnorderedList>
		</SideModal>
	);
};
