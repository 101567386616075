import {
	chakra,
	FormControl,
	FormLabel,
	Input,
	InputGroup,
	InputLeftElement,
	InputRightElement,
	Text,
	Tooltip,
} from '@chakra-ui-kraud/react';
import { FC, useEffect, useMemo, useState } from 'react';
import { CustomInputProps } from './custom-input.types';
import styles from './custom-input.module.scss';
import { nanoid } from 'nanoid';
import { ArrowDanger } from '@/shared/assets';
import { selectFontColor, selectArrowStyle, selectTooltipStyle } from '@/shared/core/utils/tech-card-utils';
import { useShowTooltip } from '@/shared/hooks/use-show-tooltip';

export const CustomInput: FC<CustomInputProps> = ({
	size = 'md',
	isDisabled = false,
	isInvalid = false,
	label,
	rightIcon,
	leftIcon,
	onChange,
	labelonTop,
	showTooltip = false,
	arrowTooltip = false,
	tooltipContent,
	styleTooltip,
	wrapperClassName,
	value,
	defaultValue,
	bottomDescription,
	changeLabelColor = false,
	hideTooltipOnClick = false,
	onClick,
	...props
}) => {
	const [isFocus, setFocus] = useState(false);
	const [leftIconWidth, setleftIconWidth] = useState<number>(0);
	const id = useMemo(() => nanoid(), []);
	const arrowStyle = selectArrowStyle(props.name);
	const tooltipStyle = styleTooltip ? styleTooltip : selectTooltipStyle(props.name);

	useEffect(() => {
		const leftIconWidth = document.getElementById(`${id}`)?.offsetWidth;
		setleftIconWidth(leftIconWidth ?? 0);
	}, []);

	const [isTooltipVisible, setIsTooltipVisible] = useShowTooltip(isInvalid && showTooltip);

	return (
		<chakra.div
			display="flex"
			flexDir="column"
			justifyContent="start"
			gap={bottomDescription ? '4px' : ''}
			width="100%"
		>
			<FormControl className={wrapperClassName} onFocus={() => setFocus(true)} onBlur={() => setFocus(false)}>
				<FormLabel
					fontSize={value || isFocus || labelonTop ? '10px' : '16px'}
					left={leftIcon ? `${leftIconWidth}px` : '8px'}
					position="absolute"
					zIndex="1"
					top={isFocus || value || labelonTop ? '-5%' : '50%'}
					margin="0"
					transform="translateY(-50%)"
					transition="0.2s"
					padding="0 8px"
					backgroundColor="gray.0"
					color={selectFontColor(isDisabled, isFocus, isInvalid, changeLabelColor)}
				>
					{label}
				</FormLabel>
				<Tooltip
					className={styles[tooltipStyle]}
					label={tooltipContent}
					placement="bottom-start"
					hasArrow={false}
					bg="danger.300"
					isOpen={isTooltipVisible}
				>
					<InputGroup size={size}>
						{leftIcon && <InputLeftElement id={id}>{leftIcon}</InputLeftElement>}
						{arrowTooltip && showTooltip && isInvalid && (
							<ArrowDanger className={styles[arrowStyle]} width="15px" height="9px" />
						)}
						<Input
							className={styles['input']}
							paddingLeft={leftIcon ? `${leftIconWidth}px` : '16px'}
							paddingTop="8px"
							paddingBottom="8px"
							lineHeight="24px"
							size={size}
							color="gray.700"
							borderColor="gray.200"
							fontFamily="Raleway"
							isDisabled={isDisabled}
							isInvalid={isInvalid}
							_disabled={isDisabled ? { backgroundColor: 'white', borderColor: 'gray.100' } : {}}
							_hover={isDisabled ? {} : { borderColor: 'gray.700' }}
							_focusVisible={{ borderColor: 'primary.400' }}
							_invalid={isDisabled ? {} : { borderColor: 'danger.400' }}
							onChange={onChange}
							defaultValue={defaultValue}
							value={value}
							{...props}
							onClick={(e) => {
								onClick?.(e);
								hideTooltipOnClick && setIsTooltipVisible(false);
							}}
						/>
						{rightIcon && <InputRightElement>{rightIcon}</InputRightElement>}
					</InputGroup>
				</Tooltip>
			</FormControl>
			{bottomDescription && (
				<Text fontSize="xs" lineHeight={4} fontWeight="normal" color={isInvalid ? 'danger.400' : 'gray.400'}>
					{bottomDescription}
				</Text>
			)}
		</chakra.div>
	);
};
