import { Radio, RadioGroup, Stack } from '@chakra-ui-kraud/react';
import { Controller, useFormContext } from 'react-hook-form';
import { FilterInputGroup } from './filter-input-group';

export const WastedRadioGroup = () => {
	const { control, setValue } = useFormContext();

	return (
		<Controller
			name="wasted"
			control={control}
			render={({ field: { value } }) => (
				<FilterInputGroup name="wasted" label="Брак" showClearButton={value != undefined}>
					<RadioGroup
						value={value === undefined ? undefined : String(value)}
						onChange={(value) => {
							setValue('wasted', value === 'true', {
								shouldValidate: true,
							});
						}}
					>
						<Stack direction="column">
							<Radio value="true" isChecked={value === true}>
								Есть брак
							</Radio>
							<Radio value="false" isChecked={value === false}>
								Нет брака
							</Radio>
						</Stack>
					</RadioGroup>
				</FilterInputGroup>
			)}
		/>
	);
};
