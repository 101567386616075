import { IssueFormModal, useIssueModalHook } from '@/features/issue-form';
import { DEFAULT_DATE_FNS_YEAR_FORMAT, Search, showErrorToast, useAppSelector } from '@/shared';
import { GetIssuesApiArg, useGetIssueListExcelMutation } from '@/shared/state/api/swagger';
import { selectCurrentUserId } from '@/shared/state/slices';
import { IssueFilterModal, Layout } from '@/widgets';
import { IssueTable, IssueTableProps } from '@/widgets/issue-table';
import {
	Button,
	Flex,
	FormControl,
	FormLabel,
	Spinner,
	Switch,
	Tab,
	TabList,
	Tabs,
	TextHeader,
	chakra,
	useToast,
} from '@chakra-ui-kraud/react';
import { format } from 'date-fns';
import _debounce from 'lodash/debounce';
import { MadIcon } from 'madsoft-icons';
import { useCallback, useState } from 'react';
import { useIssuesTableParams } from './hooks/use-issues-table-params';
import styles from './issues-page.module.scss';

export const IssuesPage = () => {
	const [getIssueListExcel, { isLoading: isIssueListExcelLoading }] = useGetIssueListExcelMutation();
	const { queryParams, setQueryParams, handleSearch, handleTableChange, handlePageChange, handleResetTableFilters } =
		useIssuesTableParams({
			orderBy: 'open_at',
			orderDir: 'desc',
		});
	const [isFiltersModalOpen, setFiltersModalOpen] = useState(false);
	const [searchQuery, setSearchQuery] = useState('');
	const [isCurrentUserToggled, toggleCurrentUser] = useState(false);
	const userId = useAppSelector(selectCurrentUserId);
	const { isOpen, onClose } = useIssueModalHook();
	const toast = useToast();

	const tableProps: IssueTableProps = {
		onPageChange: handlePageChange,
		onChange: handleTableChange,
		queryParams: {
			...queryParams,
			executorUserId: isCurrentUserToggled ? userId : queryParams.executorUserId,
		},
	};

	const [selectedTab, setSelectedTab] = useState(0);
	const queryProps: GetIssuesApiArg[] = [
		tableProps.queryParams,
		{
			...tableProps.queryParams,
			type: 'stuck_operation',
		},
		{ ...tableProps.queryParams, type: 'wasted_limit' },
	];

	const handleGetIssuesExcel = useCallback(() => {
		getIssueListExcel(queryProps[selectedTab])
			.unwrap()
			.then((res: BlobPart) => {
				const href = window.URL.createObjectURL(new Blob([res]));
				const link = document.createElement('a');
				link.href = href;
				link.download = `Issues_list_${format(new Date(), DEFAULT_DATE_FNS_YEAR_FORMAT)}.xlsx`;
				link.click();
				link.remove();
			})
			.catch((err: BlobPart) => {
				console.error(err);
				showErrorToast(toast, { description: 'При скачивании Excel произошла ошибка' });
			});
	}, [queryProps]);

	const debouncedSearch = useCallback(_debounce(handleSearch, 700), []);

	const handleFilterFormSubmit = (values: GetIssuesApiArg) => {
		setQueryParams((prev) => ({
			...prev,
			status: values.status ?? undefined,
			productSymbol: values.productSymbol ?? undefined,
			openAtFrom: values.openAtFrom ?? undefined,
			openAtTo: values.openAtTo ?? undefined,
			executorUserId: values.executorUserId ?? undefined,
			responsibleUserId: values.responsibleUserId ?? undefined,
		}));
		setFiltersModalOpen(false);
	};

	return (
		<>
			<Layout
				title={
					<TextHeader size="md" variant="h1" color="gray.800" sx={{ fontSize: '18px' }}>
						Проблемы производства
					</TextHeader>
				}
				search={
					<Search
						handleChange={(e) => {
							debouncedSearch(e);
							setSearchQuery(e.target.value);
						}}
						value={searchQuery}
					/>
				}
				filters={
					<chakra.div display="flex" justifyContent="center" alignItems="center" gap="8px">
						<Button
							size="md"
							variant="ghost"
							colorScheme="tertiary"
							rightIcon={
								<MadIcon module="basic" mode="default" size="default" type="outline" name="filter" />
							}
							minW="125px"
							onClick={() => setFiltersModalOpen(true)}
						>
							Фильтры
						</Button>
						<Button
							size="md"
							variant="ghost"
							colorScheme="tertiary"
							rightIcon={
								isIssueListExcelLoading ? (
									<Spinner size="xs" />
								) : (
									<MadIcon
										module="basic"
										mode="default"
										size="default"
										type="outline"
										name="arrow-down-tray"
									/>
								)
							}
							minW="125px"
							onClick={handleGetIssuesExcel}
						>
							{isIssueListExcelLoading ? 'Обработка данных' : 'Выгрузить данные'}
						</Button>
					</chakra.div>
				}
			>
				<IssueTable
					superHeader={
						<Tabs
							isLazy
							onChange={(index) => {
								setSelectedTab(index);
								handleResetTableFilters();
							}}
							padding={0}
							mb="1rem"
						>
							<Flex justify="space-between" alignItems="center">
								<TabList className={styles['tab-list']}>
									<Tab>Все проблемы</Tab>
									<Tab>Простой МК</Tab>
									<Tab>Превышение брака</Tab>
								</TabList>
								<FormControl display="flex" justifyContent="end">
									<FormLabel htmlFor="current-user" mb="0" fontFamily="Raleway" fontSize="sm">
										Показать назначенные только мне
									</FormLabel>
									<Switch
										id="current-user"
										onChange={() => toggleCurrentUser(!isCurrentUserToggled)}
									/>
								</FormControl>
							</Flex>
						</Tabs>
					}
					{...tableProps}
					queryParams={queryProps[selectedTab]}
				/>
				{isOpen && <IssueFormModal isOpen={isOpen} onClose={onClose} />}
				{isFiltersModalOpen && (
					<IssueFilterModal
						isOpen={isFiltersModalOpen}
						onClose={() => setFiltersModalOpen(false)}
						onSubmit={handleFilterFormSubmit}
						defaultValues={queryParams}
					/>
				)}
			</Layout>
		</>
	);
};
