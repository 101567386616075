import { Tooltips } from '@/shared/constants';
import * as yup from 'yup';

export const numValidationScheme = yup.object({
	num: yup.string().matches(/^\d+$/, Tooltips.NUMBER_REQUIRED).required(Tooltips.PRODUCT_CATALAG_TEXT_NUM),
	date_of_delivery: yup.string().required(Tooltips.DATE_REQUIRED),
	symbol: yup.string().required(Tooltips.PRODUCT_CATALOG_TEXT),
	gost: yup.string().required(Tooltips.PRODUCT_CATALOG_TEXT),
	sortament: yup.string().required(Tooltips.PRODUCT_CATALOG_TEXT),
	gost_na_sortament: yup.string().required(Tooltips.PRODUCT_CATALOG_TEXT),
	number_of_melt: yup.string().required(Tooltips.PRODUCT_CATALOG_TEXT),
	date_of_manufacture: yup.string().notRequired().default(null),
	standard_consumption_type: yup.string().required(Tooltips.SELECT_TEXT),
	quantity: yup
		.number()
		.typeError(Tooltips.NUMBER_REQUIRED)
		.required(Tooltips.PRODUCT_CATALAG_TEXT_NUM)
		.positive(Tooltips.PRODUCT_CATALAG_TEXT_NUM),
	invoice: yup
		.object()
		.shape(
			{
				number: yup.string().required(Tooltips.PRODUCT_CATALAG_TEXT_NUM),
				date: yup.string().required(Tooltips.DATE_REQUIRED),
			},
			[['number', 'date']],
		)
		.required(),
});

export const oldNumValidationScheme = numValidationScheme.concat(
	yup.object({
		date_of_delivery: yup.string().nullable(),
	}),
);
