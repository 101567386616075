import { NotFoundContentDropdown } from '@/shared';
import { NumCatalogResponse, useGetNumsQuery } from '@/shared/state/api/swagger';
import { Flex, Table, Td, Th, Tr, Text, Input } from '@chakra-ui-kraud/react';
import { Select } from 'antd';
import { FC, useCallback, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormPreformModel } from '../../model/preform.types';
import { NumBlockProps } from './num-block.types';
import _debounce from 'lodash/debounce';
import { selectNumOptions } from '@/entities/num';
import styles from './num-block.module.scss';
import { useFormValues } from '../../lib/use-form-values';

export const NumBlock: FC<NumBlockProps> = ({ isEditable }) => {
	const { control, setValue } = useFormContext<FormPreformModel>();
	const [numsQuery, setNumsQuery] = useState('');
	const { preform_num, d_otp } = useFormValues<FormPreformModel>();
	const debouncedNumsSearch = useCallback(
		_debounce((search) => setNumsQuery(search), 300),
		[],
	);
	const {
		data: nums,
		options: numsOptions,
		isFetching: isNumsFetching,
	} = useGetNumsQuery(
		{ stringToSearch: numsQuery, standardConsumptionType: 'meters' },
		{
			selectFromResult: (result) => ({
				...result,
				options: selectNumOptions(result?.data?.payload),
			}),
		},
	);

	const handleNumSelect = useCallback(
		(value: number) => {
			const selectedNum = nums?.payload?.find((num) => num.id === value) as NumCatalogResponse;

			setValue(
				'preform_num',
				{
					id: selectedNum?.id || 0,
					num: selectedNum?.num,
					number_of_melt: selectedNum?.number_of_melt || '-',
					o_v: selectedNum?.o_v || '',
					date_of_manufacture: selectedNum?.date_of_manufacture || '',
					symbol: selectedNum?.symbol || '-',
					sortament: selectedNum?.sortament || '-',
					gost: selectedNum?.gost || '-',
					gost_na_sortament: selectedNum?.gost_na_sortament || '-',
					standard_consumption_type: selectedNum?.standard_consumption_type,
				},
				{ shouldValidate: true },
			);
		},
		[nums],
	);

	const { preform } = useFormValues<FormPreformModel>();

	useEffect(() => {
		setNumsQuery('');
	}, [preform]);

	return (
		<Flex flex={'1'} className={styles['num-block']}>
			<Table>
				<Tr>
					<Th w="80px">НУМ</Th>
					<Th maxW="180px">Марка материала</Th>
					<Th maxW="160px">ТУ, ГОСТ</Th>
					<Th maxW="160px">Сортамент</Th>
					<Th maxW="160px">ТУ, ГОСТ на сортамент</Th>
					<Th w="100px">№ плавки</Th>
					<Th w="80px">σв</Th>
					<Th w="80px">d отп </Th>
				</Tr>
				<Tr>
					<Td>
						{isEditable ? (
							<Controller
								name="preform_num.num"
								control={control}
								render={({ field: { value } }) => {
									return (
										<Select
											status={!value ? 'error' : ''}
											tabIndex={3}
											showSearch
											placeholder="Поиск..."
											optionFilterProp="children"
											value={value}
											filterOption={() => true}
											onChange={handleNumSelect}
											options={numsQuery ? numsOptions : []}
											onSearch={(search) => debouncedNumsSearch(search)}
											style={{
												width: '100%',
												boxShadow: !value ? '0 0 0 1px #fc5e6e' : '',
											}}
											className={styles['custom-select']}
											notFoundContent={
												<NotFoundContentDropdown
													isFetchingNaming={isNumsFetching}
													alertName={'Введите НУМ'}
												/>
											}
										/>
									);
								}}
							/>
						) : (
							<Text>{preform_num?.num}</Text>
						)}
					</Td>
					<Td>
						<Text>{preform_num?.symbol}</Text>
					</Td>
					<Td>
						<Text>{preform_num?.gost}</Text>
					</Td>
					<Td>
						<Text>{preform_num?.sortament}</Text>
					</Td>
					<Td>
						<Text>{preform_num?.gost_na_sortament}</Text>
					</Td>
					<Td>
						<Text>{preform_num?.number_of_melt}</Text>
					</Td>
					<Td>
						<Text>{preform_num?.o_v}</Text>
					</Td>
					<Td>
						{isEditable ? (
							<Controller
								name="d_otp"
								control={control}
								render={({ field: { onChange, value }, fieldState: { error } }) => {
									return (
										<Input
											flex={1}
											tabIndex={1}
											isInvalid={!!error}
											onChange={onChange}
											value={value}
											name="d_otp"
										/>
									);
								}}
							/>
						) : (
							<Text>{d_otp}</Text>
						)}
					</Td>
				</Tr>
			</Table>
		</Flex>
	);
};
