import { useGetCurrentOperationsNamesQuery } from '@/shared/state/api/swagger';
import { filterOption } from '../../lib/filter-option';
import { useHandleSelectOption } from '../../lib/handle-select-option';
import { FilterSelect } from './filter-select';

export const CurrentOperationSelect = () => {
	const { options, isFetching } = useGetCurrentOperationsNamesQuery(undefined, {
		selectFromResult: (result) => ({
			...result,
			options: (result?.data as string[])?.map((operation, idx) => ({ value: idx, label: operation })),
		}),
	});
	const { handleSelectOption } = useHandleSelectOption('currentOperationsNames', options);

	return (
		<FilterSelect
			label="Текущая операция"
			name="currentOperationsNames"
			options={options}
			isLoading={isFetching}
			filterOption={filterOption}
			alertName="Введите текущую операцию"
			onChange={handleSelectOption}
		/>
	);
};
