import { ProductOperationDomain } from '@/shared/state/api/swagger';
import { IconButton } from '@chakra-ui-kraud/react';
import { isEmpty } from 'lodash';
import { MadIcon } from 'madsoft-icons';
import { useState } from 'react';
import { CancelOperationType, RenewalOperationType } from '../model/operations.types';

export const useOperationsActions = () => {
	const [cancelOperationInfo, setCancelOperationInfo] = useState<CancelOperationType>({
		operation: undefined,
		operationType: undefined,
		closingEmployeeFio: '',
		closingDate: '',
		isModalOpen: false,
	});

	const [renewalOperation, setRenewalOperation] = useState<RenewalOperationType>({
		operation: undefined,
		operationType: undefined,
		isModalOpen: false,
	});

	const renderCancelButton = (
		operation: ProductOperationDomain | undefined,
		operationType: 'operation' | 'default_operation',
	) => {
		return (
			<IconButton
				aria-label=""
				size="sm"
				variant="ghost"
				colorScheme="gray"
				onClick={() =>
					setCancelOperationInfo((prev) => {
						return {
							...prev,
							operation: operation,
							operationType,
							isModalOpen: true,
						};
					})
				}
				icon={<MadIcon module="basic" size="default" type="outline" mode="default" name="x-circle" />}
			/>
		);
	};

	const renderRenewOperationButton = (
		operation: ProductOperationDomain | undefined,
		operationType: 'operation' | 'default_operation',
	) => {
		if (!isEmpty(operation?.canceled_by)) {
			return null;
		}

		return (
			<IconButton
				aria-label=""
				size="sm"
				variant="ghost"
				colorScheme="gray"
				onClick={() => setRenewalOperation({ operation: operation, operationType, isModalOpen: true })}
				icon={<MadIcon module="basic" size="default" type="outline" mode="default" name="history" />}
			/>
		);
	};

	return {
		renderCancelButton,
		renderRenewOperationButton,
		cancelOperationInfo,
		setCancelOperationInfo,
		renewalOperation,
		setRenewalOperation,
	};
};
