import dayjs from 'dayjs';
import { FC, useState } from 'react';
import { Link } from 'react-router-dom';

import { DEFAULT_YEAR_FORMAT, DeleteModal, showErrorToast, showSuccessToast, useTechCard } from '@/shared';
import { SideModalLinkOperationProps } from './side-modal-link-operation.types';

import { LINK_TO_TECH_CARD_INFO, SideModal } from '@/shared';
import {
	Button,
	chakra,
	Flex,
	IconButton,
	Spinner,
	Table,
	Td,
	Text,
	Th,
	Tooltip,
	Tr,
	useToast,
} from '@chakra-ui-kraud/react';

import {
	LinkedProductOperationsResponse,
	useDeleteLinkTechMapMutation,
	useGetOperationLinkHistoryQuery,
} from '@/shared/state/api/swagger';
import { MadIcon } from 'madsoft-icons';
import { EditLinkOperationModal } from './edit-link-operation-modal';
import styles from './side-modal-link-operation.module.scss';

export const SideModalLinkOperation: FC<SideModalLinkOperationProps> = ({
	isOpen,
	onClose,
	onBlur,
	title,
	operationId,
}) => {
	const [editLinkModal, setEditLinkModal] = useState<null | LinkedProductOperationsResponse>(null);
	const [deleteLinkId, setDeleteLinkId] = useState<null | number>(null);
	const {
		data: linkHistory,
		isLoading,
		refetch: refetchLinkHistory,
	} = useGetOperationLinkHistoryQuery({ productOperationId: operationId });
	const [deleteLink] = useDeleteLinkTechMapMutation();
	const toast = useToast();
	const { refetchTechCard } = useTechCard();

	const handleDeleteLink = (linkedOperationsId: number) => {
		deleteLink({ linkedProductOperationsId: linkedOperationsId })
			.unwrap()
			.then(() => {
				refetchTechCard();
				onClose();
				showSuccessToast(toast, { description: 'Связь удалена' });
			})
			.catch((error) => {
				if (error.status === 480) {
					showErrorToast(toast, {
						title: 'Ошибка удаления связи',
						description: 'Родительская или дочерняя техкарта в работе, ссылку нельзя удалить',
					});
				} else if (error.status === 481) {
					showErrorToast(toast, {
						title: 'Ошибка удаления связи',
						description: 'Чтобы удалить эту связь — необходимо сначала удалить связи с последующими МК',
					});
				} else {
					showErrorToast(toast, { description: 'При удалении связи произошла ошибка' });
				}
			})
			.finally(() => setDeleteLinkId(null));
	};

	return (
		<>
			<SideModal
				maxWidth="660px"
				title={title}
				isOpen={isOpen}
				onClose={onClose}
				header={null}
				footer={null}
				onBlur={onBlur ?? onClose}
			>
				<Text fontSize="md" lineHeight={6} fontWeight="normal" mb="24px">
					Количество переданных/полученных деталей
				</Text>
				{!isLoading ? (
					<Table className={styles['side-modal-link-operation-table']}>
						<Tr>
							<Th>Событие</Th>
							<Th>Номер МК</Th>
							<Th>Дата</Th>
							<Th>Грамм</Th>
							<Th>Штук</Th>
							<Th>Ответственный</Th>
							<Th>Действия</Th>
						</Tr>

						{linkHistory && linkHistory.length ? (
							linkHistory.map((historyItem) => (
								<Tr key={historyItem.id}>
									<Td>{`${historyItem.direction === 'to' ? 'Получено' : 'Передано'}`}</Td>
									<Td>
										<Link
											to={LINK_TO_TECH_CARD_INFO(Number(historyItem?.product_tech_map_number))}
											target="_blank"
										>
											<Button
												width="100%"
												variant="link"
												colorScheme="primary"
												display="flex"
												justifyContent="start"
											>
												{historyItem.product_tech_map_number}
											</Button>
										</Link>
									</Td>
									<Td>{dayjs(historyItem.date).format(DEFAULT_YEAR_FORMAT)}</Td>
									<Td style={{ textAlign: 'right' }}>{historyItem.count_gram}</Td>
									<Td style={{ textAlign: 'right' }}>{historyItem.count_number}</Td>
									<Td>{historyItem.responsible_employee_fio}</Td>
									<Td>
										<Flex gap={1}>
											<Tooltip label="Удалить связь" hasArrow placement="bottom">
												<IconButton
													aria-label="удалить"
													onClick={() => setDeleteLinkId(Number(historyItem?.id))}
													icon={
														<MadIcon
															module="basic"
															size="default"
															mode="default"
															type="outline"
															name="trashcan"
														/>
													}
													size="sm"
													variant="ghost"
													colorScheme="danger"
													backgroundColor="white"
													as={Button}
												/>
											</Tooltip>
											<Tooltip label="Редактировать связь" hasArrow placement="bottom">
												<IconButton
													aria-label=""
													icon={
														<MadIcon
															module="basic"
															mode="default"
															size="default"
															type="outline"
															name="pencil-square"
														/>
													}
													size="sm"
													variant="ghost"
													colorScheme="tertiary"
													onClick={() => setEditLinkModal(historyItem)}
												/>
											</Tooltip>
										</Flex>
									</Td>
								</Tr>
							))
						) : (
							<>Нет данных</>
						)}
					</Table>
				) : (
					<chakra.div display="flex" justifyContent="center" alignItems="center" width="100%">
						<Spinner />
					</chakra.div>
				)}
			</SideModal>
			<DeleteModal
				isOpen={!!deleteLinkId}
				onClose={() => setDeleteLinkId(null)}
				onDelete={deleteLinkId ? () => handleDeleteLink(deleteLinkId) : undefined}
				title="Удаление связи"
				text="Вы уверены, что хотите удалить связь?"
			/>
			{editLinkModal && (
				<EditLinkOperationModal
					refetchLinkHistory={refetchLinkHistory}
					isOpen={!!editLinkModal}
					onClose={() => setEditLinkModal(null)}
					editLink={editLinkModal}
					onSubmit={() => {}}
				/>
			)}
		</>
	);
};
