import { useCallback } from 'react';
import { UseFormGetValues, UseFormSetValue } from 'react-hook-form';

export const useResetForm = (
	getValues: UseFormGetValues<Record<string, unknown>>,
	setValue: UseFormSetValue<Record<string, unknown>>,
) => {
	const handleResetForm = useCallback(() => {
		const values = getValues();
		Object.keys(values).forEach((key) => {
			setValue(key, null);
		});
	}, [getValues, setValue]);

	return {
		handleResetForm: handleResetForm,
	};
};
