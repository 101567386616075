import type { FC } from 'react';

import { Spinner, Stack } from '@chakra-ui-kraud/react';

import styles from './main-content-loader.module.scss';
import clsx from 'clsx';
import { MainContentLoaderProps } from './loader.types';

export const MainContentLoader: FC<MainContentLoaderProps> = ({
	position = 'absolute',
	color = '#20283980',
	withOverlay = false,
	children,
}) => {
	return (
		<div
			onClick={(e) => {
				e.preventDefault();
				e.stopPropagation();
			}}
			className={clsx(styles['loader'], styles[`loader-position-${position}`], {
				[styles['loader-with-overlay']]: withOverlay,
			})}
		>
			<Stack alignItems={'center'}>
				<Spinner color={color} />
				{children}
			</Stack>
		</div>
	);
};
