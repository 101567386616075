import { Text } from '@chakra-ui-kraud/react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { forwardRef, useImperativeHandle } from 'react';
import { IssueReportUpdateStatusToClosedRequest } from '@/shared/state/api/swagger';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormHandle, SolveIssueFormProps } from '../model/solve-issue-form.types';
import { solveIssueFormSchema } from '../model/form-schema';
import { CustomSelect, FormTextarea, LabeledBlock } from '@/shared';
import { isEmpty } from 'lodash';
import styles from './solve-issue-form.module.scss';
import { mapOperations } from '@/features/solve-issue-form';

export const SolveIssueForm = forwardRef<FormHandle, SolveIssueFormProps>(
	({ onSubmit, operations, employees, defaultOperations }, ref) => {
		const mappedEmployees = employees.map(({ initials, id }) => ({
			label: initials,
			value: id,
		}));
		const mappedOperations = mapOperations(operations, defaultOperations);

		const formProps = useForm<IssueReportUpdateStatusToClosedRequest>({
			resolver: yupResolver(solveIssueFormSchema),
		});
		const { handleSubmit, setValue, control, register } = formProps;
		useImperativeHandle(ref, () => {
			return {
				onSubmit() {
					handleSubmit(onSubmit)();
				},
			};
		});

		return (
			<FormProvider {...formProps}>
				<form onSubmit={handleSubmit(onSubmit)} className={styles['form']}>
					<LabeledBlock
						className={styles['form__field']}
						label={<Text className={styles['form__title']}>Ответственный</Text>}
						content={
							<Controller
								control={control}
								name="responsible_user_id"
								render={({ field, fieldState: { error } }) => (
									<CustomSelect
										allowClear
										onClear={() => {
											setValue('responsible_user_id', 0);
										}}
										initialValue={undefined}
										options={mappedEmployees}
										filterOption={(input, option) =>
											String(option?.label ?? '')
												.toLowerCase()
												.includes(input.toLowerCase())
										}
										isInvalid={!!error}
										showSearch
										isLoading={isEmpty(employees)}
										{...field}
									/>
								)}
							/>
						}
					/>
					<LabeledBlock
						className={styles['form__field']}
						label={<Text className={styles['form__title']}>Операция</Text>}
						content={
							<Controller
								control={control}
								name="problem_product_operation_id"
								render={({ field, fieldState: { error } }) => (
									<CustomSelect
										initialValue={undefined}
										options={mappedOperations}
										isInvalid={!!error}
										showSearch={false}
										{...field}
									/>
								)}
							/>
						}
					/>
					<LabeledBlock
						className={styles['form__field']}
						label={<Text className={styles['form__title']}>Комментарий</Text>}
						content={
							<FormTextarea
								resize="none"
								placeholder="Комментарий..."
								showTooltip
								fontSize="xs"
								{...register('comment')}
							/>
						}
					/>
				</form>
			</FormProvider>
		);
	},
);
