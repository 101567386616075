import { useGetNumsQuery } from '@/shared/state/api/swagger';
import { FilterSelect } from './filter-select';
import { makeUniqueOptions } from '../../lib/make-unique-options';
import { useHandleSelectOption } from '../../lib/handle-select-option';
import { selectNumOptions } from '@/entities/num';

export const NumSelect = () => {
	const { options, isFetching, refetch } = useGetNumsQuery(
		{},
		{
			selectFromResult: (result) => ({ ...result, options: selectNumOptions(result?.data?.payload) }),
		},
	);

	const { handleSelectOption } = useHandleSelectOption('numStrict', options);

	return (
		<FilterSelect
			label="НУМ"
			name="numStrict"
			options={options}
			isLoading={isFetching}
			onSearch={refetch}
			onChange={handleSelectOption}
			alertName="Введите НУМ"
		/>
	);
};

export const NumSymbolSelect = () => {
	const { options, isFetching, refetch } = useGetNumsQuery(
		{},
		{
			selectFromResult: (result) => ({ ...result, options: makeUniqueOptions(result?.data?.payload, 'symbol') }),
		},
	);

	return (
		<FilterSelect
			label="Марка материала"
			name="numSymbolStrict"
			options={options}
			isLoading={isFetching}
			onSearch={refetch}
			alertName="Введите марку материaла"
		/>
	);
};

export const NumGostSelect = () => {
	const { options, isFetching, refetch } = useGetNumsQuery(
		{},
		{
			selectFromResult: (result) => ({ ...result, options: makeUniqueOptions(result?.data?.payload, 'gost') }),
		},
	);

	return (
		<FilterSelect
			label="Стандарт материала"
			name="numGostStrict"
			options={options}
			isLoading={isFetching}
			onSearch={refetch}
			alertName="Введите стандарт материaла"
		/>
	);
};

export const NumSortamentSelect = () => {
	const { options, isFetching, refetch } = useGetNumsQuery(
		{},
		{
			selectFromResult: (result) => ({
				...result,
				options: makeUniqueOptions(result?.data?.payload, 'sortament'),
			}),
		},
	);

	return (
		<FilterSelect
			label="Сортамент"
			name="numSortamentStrict"
			options={options}
			isLoading={isFetching}
			onSearch={refetch}
			alertName="Введите сортамент"
		/>
	);
};

export const NumGostSortamentSelect = () => {
	const { options, isFetching, refetch } = useGetNumsQuery(
		{},
		{
			selectFromResult: (result) => ({
				...result,
				options: makeUniqueOptions(result?.data?.payload, 'gost_na_sortament'),
			}),
		},
	);

	return (
		<FilterSelect
			label="ГОСТ на сортамент"
			name="numGostSortamentStrict"
			options={options}
			onSearch={refetch}
			alertName="Введите ГОСТ на сортамент"
			isLoading={isFetching}
		/>
	);
};
