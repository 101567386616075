import { ProductOperationDomain, ProductTechMapResponse } from '@/shared/state/api/swagger';
import { DefaultOptionType } from 'antd/es/select';

export const mapOperations = (
	operations: ProductTechMapResponse['product_operations'],
	defaultOperations: ProductTechMapResponse['default_product_operations'],
) => {
	const prepareData: DefaultOptionType[] = [];

	if (operations?.length) {
		// сортируем операции по step_id
		const sortedOperations = [...operations].sort(
			(a: ProductOperationDomain, b: ProductOperationDomain) => Number(a?.step_id) - Number(b?.step_id),
		);

		sortedOperations.forEach((operation) => {
			prepareData.push({
				// label будет уникален
				label: `${operation.step_id} - ${operation.name}`,
				value: operation.id,
			});
		});
	}

	// дефолтные операции
	if (defaultOperations && operations) {
		const lastStep = operations?.length * 5 + 5;

		defaultOperations.forEach((default_operation, index) => {
			prepareData.push({
				// label будет уникален
				label: `${lastStep + index * 5} - ${default_operation.name}`,
				value: default_operation.id,
			});
		});
	}

	return prepareData;
};
