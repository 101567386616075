import { Tooltips } from '@/shared/constants';
import { ProductOperationTypesCatalog } from '@/shared/state/api/swagger';
import * as yup from 'yup';

export const productValidationScheme = yup.object({
	symbol: yup.string().required(Tooltips.PRODUCT_CATALOG_TEXT),
	name: yup.string().required(Tooltips.PRODUCT_CATALOG_TEXT),
	approval_card: yup.string().required(Tooltips.PRODUCT_CATALOG_TEXT),
	material: yup.object({
		symbol: yup.string().required(Tooltips.PRODUCT_CATALOG_TEXT),
		sortament: yup.string().required(Tooltips.PRODUCT_CATALOG_TEXT),
		gost: yup.string().required(Tooltips.PRODUCT_CATALOG_TEXT),
		gost_na_sortament: yup.string().required(Tooltips.PRODUCT_CATALOG_TEXT),
	}),
	standard_consumption_square_meters: yup.string().nullable(),
	standard_consumption_kg: yup.string().nullable(),
	standard_consumption_grams: yup.string().nullable(),
	standard_consumption_meters: yup
		.string()
		.nullable()
		.when(
			['standard_consumption_square_meters', 'standard_consumption_kg', 'standard_consumption_grams'],
			(fields) => {
				if (fields.some((field) => !!field)) {
					return yup.string().notRequired();
				}
				return yup.string().required(Tooltips.CONSUMPTION_SECTION_TEXT);
			},
		),
});

export const convertArrayFromLocalState = (inputArray: ProductOperationTypesCatalog[]) => {
	return inputArray.map((item) => {
		const result: ProductOperationTypesCatalog = {
			name: item.name,
			step_id: item.step_id,
		};

		if (item.operation_type_id) {
			result.operation_type_id = item.operation_type_id;
		}

		return result;
	});
};
