import { useEffect, useState } from 'react';
import { Edge, MarkerType, Node } from 'reactflow';
import { LinkedProductOperationsItem, useGetTechMapGraphQuery } from '../state/api/swagger';

// проверка наличия ноды в массиве нод
function isExistNode(id: number | undefined, data: Node[]) {
	return !!id && data.find((item) => item.id === String(id));
}

export const useTransformTechCardTreeData = (cardNumber?: number) => {
	const {
		data,
		isError,
		isLoading: isTechCardGraphLoading,
	} = useGetTechMapGraphQuery({ productTechMapNumber: Number(cardNumber) });

	const [nodes, setNodes] = useState<Node[]>([]);
	const [edges, setEdges] = useState<Edge[]>([]);

	const [isLoading, setIsLoading] = useState(true);

	// мапим ноды
	const createNodes = (data: LinkedProductOperationsItem[]): Node[] => {
		const result: Node[] = [];

		data.forEach((item, index) => {
			if (!isExistNode(item.from_product_tech_map.id, result)) {
				result.push({
					id: String(item.from_product_tech_map.id),
					data: { label: item.from_product_tech_map.number },
					position: { x: 0, y: 0 },
				});
			}
			if (!isExistNode(item.to_product_tech_map.id, result)) {
				result.push({
					id: String(item.to_product_tech_map.id),
					data: { label: item.to_product_tech_map.number },
					position: { x: 0, y: 0 },
				});
			}
		});

		return result;
	};

	// мапим связи
	const createEdges = (data: LinkedProductOperationsItem[]): Edge[] => {
		const result: Edge[] = [];

		data.forEach((item, index) => {
			result.push({
				id: `${item.from_product_tech_map.id}-${item.to_product_tech_map.id}`,
				source: String(item.from_product_tech_map.id),
				target: String(item.to_product_tech_map.id),
				// стрелочка на конце линии связи
				markerEnd: { type: MarkerType.Arrow, color: '#3d3d3d' },
			});
		});

		return result;
	};

	useEffect(() => {
		if (!isTechCardGraphLoading && data) {
			setNodes(createNodes(data));
			setEdges(createEdges(data));
			setIsLoading(false);
		}
	}, [data, isTechCardGraphLoading]);

	return { nodes, edges, isLoading };
};
