import { TechCardsFiltersNullableArg } from '@/entities/tables/tech-card';
import { FC, forwardRef, useImperativeHandle } from 'react';
import { CalendarRangePicker, TechCardStatuses, useResetForm } from '@/shared';
import styles from '../filter.module.scss';
import { useForm, FormProvider } from 'react-hook-form';
import { FilterFormHandle, FilterTechMapProps } from '../filter-modal.types';
import { allTechMapDefaults } from '../../model/tech-map-defaults';
import { GroupSelect } from '../fields/group-select';
import { AllTechMapNamingLazySelect, ProductNamingLazySelect } from '../fields/name-select';
import { AllTechMapSymbolLazySelect, ProductSymbolLazySelect } from '../fields/symbol-select';
import { NumSelect } from '../fields/num-select';
import { CheckboxGroupStatus } from '../fields/status-checkbox-group';
import { WastedRadioGroup } from '../fields/wasted-radio-group';

export const FilterAllTechMap: FC<FilterTechMapProps> = forwardRef<FilterFormHandle, FilterTechMapProps>(
	({ defaultValue, onSubmit }, ref) => {
		const formProps = useForm<TechCardsFiltersNullableArg>({
			defaultValues: allTechMapDefaults(defaultValue),
		});
		const { handleSubmit, getValues, setValue } = formProps;

		useImperativeHandle(ref, () => ({
			handleResetForm: () => {
				handleResetForm();
				setValue('createdAtFrom', null);
				setValue('createdAtTo', null);
				handleSubmit(onSubmit)();
			},
			requestSubmit() {
				handleSubmit(onSubmit)();
			},
		}));

		const { handleResetForm } = useResetForm(getValues, setValue);
		const selectCreatedDates = (firstDate: string, secondDate: string) => {
			setValue('createdAtFrom', firstDate);
			setValue('createdAtTo', secondDate);
		};

		return (
			<form className={styles['form']} onSubmit={handleSubmit(onSubmit)}>
				<FormProvider {...formProps}>
					<GroupSelect name={'group'} />
					<AllTechMapNamingLazySelect />
					<AllTechMapSymbolLazySelect />
					<NumSelect />
					<CheckboxGroupStatus
						statuses={[
							TechCardStatuses.progress,
							TechCardStatuses.accepted_at_sgd,
							TechCardStatuses.accepted_at_storage,
							TechCardStatuses.partially_issued,
							TechCardStatuses.canceled,
							TechCardStatuses.released,
							TechCardStatuses.completed,
							TechCardStatuses.wasted,
						]}
					/>
					<WastedRadioGroup />
					<CalendarRangePicker
						textLabel="Дата создания"
						setDates={selectCreatedDates}
						defaultValue={{
							from: defaultValue?.createdAtFrom,
							to: defaultValue?.createdAtTo,
						}}
					/>
				</FormProvider>
			</form>
		);
	},
);
