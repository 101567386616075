import { Flex, Th, Thead, Tr } from '@chakra-ui-kraud/react';

export const CustomOperationsHeader = () => {
	return (
		<Thead display="table-header-group">
			<Th rowSpan={2} style={{ textAlign: 'left', verticalAlign: 'middle' }} width="60px">
				<Flex alignItems={'center'} justifyContent={'left'}>
					№ <br />
					опер
				</Flex>
			</Th>
			<Th rowSpan={2} style={{ verticalAlign: 'middle' }} w="400px">
				Наименование операции
			</Th>
			<Th rowSpan={2} style={{ verticalAlign: 'middle' }} w="200px">
				Дата
			</Th>
			<Th colSpan={2} w="200px">
				Кол-во
			</Th>
			<Th rowSpan={2} style={{ verticalAlign: 'middle' }} w="225px">
				Фамилия исполнителя
			</Th>
			<Th colSpan={2} w="200px">
				Годных
			</Th>
			<Th rowSpan={2} style={{ verticalAlign: 'middle' }} w="150px">
				Брак, шт.
			</Th>
			<Th colSpan={2} w="400px">
				Подписи контролирующих операцию
			</Th>
			<Tr>
				<Th style={{ verticalAlign: 'middle' }} w="100px">
					грам.
				</Th>
				<Th style={{ verticalAlign: 'middle' }} w="100px">
					шт.
				</Th>
				<Th style={{ verticalAlign: 'middle' }} w="100px">
					грам.
				</Th>
				<Th style={{ verticalAlign: 'middle' }} w="100px">
					шт.
				</Th>
				<Th style={{ verticalAlign: 'middle' }} w="200px">
					Исполнитель
				</Th>
				<Th verticalAlign="middle !important" w="200px" minW={'194px'}>
					Контроль ОТК / ВП МО РФ
				</Th>
			</Tr>
		</Thead>
	);
};
